<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Services</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                        outlined
                        dense
                        hide-details
                        label="Search"
                        name="search"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        class="small-text-field"
                        background-color="white"
                        @keypress.enter="search = $event.target.value">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn
                        @click="newService"
                        class="green py-4"
                        dark
                        small
                        block
                >
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New Service</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <h4>Total Records: {{ services.pagination.totalRecords }}</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="9">
                <v-btn
                        color="red darken-4"
                        small
                        class="mr-1 white--text"
                        @click="deleteService"
                        :disabled="services.selected.length == 0">
                    Delete
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text"
                        @click="updateStatus(true)"
                        :disabled="services.selected.length == 0" >
                    Mark As Active
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="updateStatus(false)"
                        :disabled="services.selected.length == 0" >
                    Mark As InActive
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" align="end">
                <v-btn
                        color="primary"
                        small
                        class="white--text">
                    Export To Excel
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="ml-1 white--text">
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="services.selected"
                        :headers="services.headers"
                        :items="services.data"
                        item-key="CustomerID"
                        :loading="services.loading"
                        loading-text="Loading Services, please wait..."
                        :page.sync="services.pagination.page"
                        :items-per-page="services.pagination.rowsPerPage"
                        class="elevation-1"
                        disable-sort
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!services.loading">
                        <tr>
                            <td><v-icon>fa-filter</v-icon></td>
                            <td v-for="header in services.headers" :key="header.value">
                                <v-text-field
                                        v-model="services.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.IsActive="{ item }">
                        <v-icon>{{ item.IsActive ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                    </template>
                    <template v-slot:item.Actions="props">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="viewFile(false, props.item.ItemID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-search</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Files</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="editService(props.item.CustomerID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="sendCredentials(false, props.item.ItemID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>SMS Credentials</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="deleteSingle(props.item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-trash</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="services.pagination.page" :length="services.pagination.totalPages" total-visible="20"></v-pagination>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "Services",
        data(){
          return {
              services: {
                  headers: [
                      { text: 'S.No', value: 'SNo', applyFilter: false },
                      { text: 'Customer No', value: 'CustomerNo', applyFilter: true },
                      { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                      { text: 'Contact Person', value: 'ContactPerson', applyFilter: true },
                      { text: 'Email', value: 'Email', applyFilter: true },
                      { text: 'Mobile', value: 'Mobile', applyFilter: true },
                      { text: 'Active Files', value: 'ActiveFiles', applyFilter: false },
                      { text: 'Cleared Files', value: 'ClearedFiles', applyFilter: false },
                      { text: 'No Of FCL', value: 'TotalContainers', applyFilter: false },
                      { text: 'Last Clearing Date', value: 'LastClearingDate', applyFilter: true },
                      { text: 'Customer Since', value: 'CreateDate', applyFilter: true },
                      { text: 'Active', value: 'IsActive', applyFilter: false },
                      { text: 'Action', value: 'Actions', applyFilter: false },
                  ],
                  data: [],
                  filters: {},
                  selected: [],
                  pagination: {
                      page: 1,
                      rowsPerPage: 25,
                      totalPages: 1,
                      totalRecords: 0,
                  },
                  loading: true,
              },
          };
        },
        computed: {
            selectedServices(){
                let selected = [];
                this.services.selected.forEach(user => {
                    selected.push(user.CustomerID);
                });
                return selected;
            }
        },
        methods: {
            getData(){
                this.services.loading = true;

                const formData = {
                    CustomerID: 0,
                    PageNo: this.services.pagination.page,
                    NoOfRows: this.services.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.services.filters).length,
                    Filters: this.services.filters,
                };
                console.log(JSON.stringify(formData));

                axios.post('Customers/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.services.pagination.totalPages = data.Data.TotalPages;
                            this.services.pagination.totalRecords = data.Data.TotalRecords;
                            this.services.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }

                        this.services.loading = false;
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                        this.services.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.services.filters[field] = value;
                else
                    delete this.services.filters[field];

                this.getData();
            },
            newService(){
                this.$router.push({name: 'servicesAddEdit', params: {"action": "new"}});
            },
            editService(serviceID){
                this.$router.push({name: 'servicesAddEdit', params: {"action": "edit"}, query: {id: serviceID}});
            },
            updateStatus(status){
                this.$loadingDialog.show();

                const formData = {
                    UpdateCustomerIDs: this.selectedServices,
                    Status: status
                };

                axios.post('Services/UpdateStatus', formData)
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success) {
                            this.getData();
                            this.services.selected = [];
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                    })
                    .catch( error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'An unexpected error has occurred.'});
                    })
            },
            deleteSingle(service){
                this.services.selected.push(service);
                this.deleteService();
            },
            deleteService() {
                console.log('Delete Service');
                this.$confirmDialog.show({
                    title: 'Delete Service',
                    message: 'Are you sure you want to delete the selected service(s)? This will delete all associated files with the service and this action cannot be undone.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            ServiceIDs: this.selectedServices,
                        };

                        axios.post('Services/Delete', formData)
                            .then(response => {
                                const data = response.data;
                                let success = data.Status;

                                if(success) {
                                    this.getData();
                                    this.services.selected = [];
                                }

                                this.$loadingDialog.hide();
                                this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                            })
                            .catch( error => {
                                console.log(error);
                                this.$loadingDialog.hide();
                                this.$snackBar.show({color: 'error', message: 'An unexpected error has occurred.'});
                            })                    }
                });

            }
        },
        created() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>