<template>
    <v-navigation-drawer app v-model="drawStatus">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-title class="title">{{ user.Name }}</v-list-item-title>
                <v-list-item-subtitle>{{ user.UserRole }}</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item ripple @click="viewLink('clientHome')">
            <v-list-item-content>
                <v-list-item-title>My Shipments</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item ripple @click="viewLink('clientProfile')">
            <v-list-item-content>
                <v-list-item-title>My Profile</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item ripple @click="logout">
            <v-list-item-content>
                <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: "navDrawer",
        props: {
            drawer: {
                type: Boolean,
                default: false,
            },
        },
        computed: {
            drawStatus: {
                get(){
                    return this.drawer;
                },
                set(val){
                    this.$emit('drawerChange', val);
                }
            },
            user(){
                return this.$store.getters.user;
            },
            modules(){
                return this.$store.getters.modules;
            }
        },
        methods: {
            viewLink(link, params) {
                this.$router.push({name: link, params: params});
            },
            logout() {
                const vm = this;
                this.$store.dispatch('logout').then(function() {
                    vm.$router.push({name: 'login'});
                });
            },
        },
    }
</script>

<style scoped>

</style>