<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Dashboard</h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="6">
                <v-card color="blue" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Total Shipments</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.Total }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="indigo" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">New Shipments</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.New }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="purple" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">In-Progress Shipments</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.Active }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="orange" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Out Of Delivery</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.Clearing }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="green" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Delivered Shipments</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.Cleared }}</v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="6">
                <v-card color="blue darken-4" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Active Shipments</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.PendingApproval }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="indigo darken-4" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Assess. Under Review</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ fileStats.PendingBOE }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="purple darken-4" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Total Containers</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ containerStats.Total }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card :color="CIP_Color" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Containers In Progress</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ containerStats.InProgress }}</v-card-title>
                </v-card>
            </v-col>
            <v-col xs="6">
                <v-card color="green darken-4" dark>
                    <v-card-subtitle class="text-h6 px-5 py-3">Containers Cleared</v-card-subtitle>
                    <v-card-title class="px-5 pb-3 pa-0">{{ containerStats.Cleared }}</v-card-title>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" xs="12">
                <v-card>
                    <v-card-title>Clearing Process Status</v-card-title>
                    <v-card-text>
                        <pie-chart
                                ref="stageChart"
                                :chart-data="stageChart.chartData"
                                :options="stageChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6" xs="12">
                <v-card>
                    <v-card-title>Terminals Status</v-card-title>
                    <v-card-text>
                        <pie-chart
                                ref="terminalsChart"
                                :chart-data="terminalsChart.chartData"
                                :options="terminalsChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="8" xs="12">
                <v-card>
                    <v-card-title>Total Cleared Containers</v-card-title>
                    <v-card-subtitle>Year-over-Year Comparison</v-card-subtitle>
                    <v-card-text>
                        <bar-chart
                                ref="filesChart"
                                :chart-data="filesChart.chartData"
                                :options="filesChart.chartOptions">
                        </bar-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12">
                <v-card height="100%">
                    <v-card-title>Total Cleared Containers</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="key in Object.keys(clearedContainerTimeStats)" :key="key" dense>
                                <v-list-item-title>{{ key }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ clearedContainerTimeStats[key] }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12">
                <v-card>
                    <v-card-title>Total Created Files</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="key in Object.keys(fileTimeStats)" :key="key" dense>
                                <v-list-item-title>{{ key }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ fileTimeStats[key] }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12">
                <v-card>
                    <v-card-title>Total Cleared Files</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="key in Object.keys(clearedFileTimeStats)" :key="key" dense>
                                <v-list-item-title>{{ key }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ clearedFileTimeStats[key] }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col xs="12">
                <v-card height="100%">
                    <v-card-title>Balance Refunds</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="refund in refundStats" :key="refund.ShortName" dense>
                                <v-list-item-title>{{ refund.ShortName }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ refund.Balance }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                    <v-card-title>Active Deposits</v-card-title>
                    <v-card-subtitle>For Shipping Lines</v-card-subtitle>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="refund in refundsShippingStats" :key="refund.ShippingLine+refund.ShortName" dense>
                                <v-list-item-title>{{ refund.ShippingLine }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ refund.ShortName }} {{ refund.Balance }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="4" xs="12">
                <v-card>
                    <v-card-title>Shipping Lines</v-card-title>
                    <v-card-subtitle>vs Total Files</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="stageChart"
                                :chart-data="shippingChart.chartData"
                                :options="shippingChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="4" xs="12">
                <v-card>
                    <v-card-title>Origins</v-card-title>
                    <v-card-subtitle>vs Total Files</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="originChart"
                                :chart-data="originChart.chartData"
                                :options="originChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="4" xs="12">
                <v-card>
                    <v-card-title>Product Categories</v-card-title>
                    <v-card-subtitle>vs Total Files</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="productChart"
                                :chart-data="productChart.chartData"
                                :options="productChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" xs="12">
                <v-card height="100%">
                    <v-card-title>Top 10 Customers</v-card-title>
                    <v-card-text>
                        <v-list>
                            <v-list-item v-for="item in top5Customers" :key="item.CompanyName" dense>
                                <v-list-item-title>{{ item.CompanyName }}</v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ item.TotalContainers }}</b></v-chip></v-list-item-title>
                                <v-list-item-title align="end"><v-chip color="green darken-2" text-color="white"><b>{{ item.FCLTypes }}</b></v-chip></v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3" xs="12">
                <v-card>
                    <v-card-title>Risk Level</v-card-title>
                    <v-card-subtitle>vs Total Containers</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="stageChart"
                                :chart-data="riskChart.chartData"
                                :options="riskChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="3" xs="12">
                <v-card>
                    <v-card-title>FCL Types</v-card-title>
                    <v-card-subtitle>vs Total Containers</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="originChart"
                                :chart-data="fclTypesChart.chartData"
                                :options="fclTypesChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" xs="12">
                <v-card>
                    <v-card-title>Referred By</v-card-title>
                    <v-card-subtitle>vs Total Customers</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="stageChart"
                                :chart-data="referredByChart.chartData"
                                :options="referredByChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col md="6" xs="12">
                <v-card>
                    <v-card-title>Business Type</v-card-title>
                    <v-card-subtitle>vs Total Customers</v-card-subtitle>
                    <v-card-text>
                        <pie-chart
                                ref="stageChart"
                                :chart-data="businessTypeChart.chartData"
                                :options="businessTypeChart.chartOptions"></pie-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col md="6" xs="12">
                <v-card>
                    <v-card-title>Avg Clearing Days</v-card-title>
                    <v-card-text>
                        <bar-chart
                                ref="avgClearingChart"
                                :chart-data="avgClearingChart.chartData"
                                :options="avgClearingChart.chartOptions"></bar-chart>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    // import moment from 'moment';

    export default {
        title: "Dashboard",
        data() {
            return {
                statistics: {},
                fileStats: {},
                containerStats: {},
                stageChart: {},
                filesChart: {},
                fileTimeStats: {},
                clearedFileTimeStats: {},
                clearedContainerTimeStats: {},
                shippingChart: {},
                originChart: {},
                productChart: {},
                riskChart: {},
                fclTypesChart: {},
                top5Customers: {},
                refundStats: {},
                terminalsChart: {},
                referredByChart: {},
                businessTypeChart: {},
                refundsShippingStats: [],
                avgClearingChart: [],
                settings: {},
            };
        },
        computed: {
            CIP_Color(){
                if('InProgress' in this.containerStats && 'CIP_Red' in this.settings) {
                    if(this.containerStats.InProgress <= this.settings.CIP_Red)
                        return 'red';
                    else if(this.containerStats.InProgress <= this.settings.CIP_Yellow)
                        return 'yellow darken-2';
                    else if(this.containerStats.InProgress >= this.settings.CIP_Green)
                        return 'green darken-2';
                    else
                        return '';
                }
                else {
                    return '';
                }
            }
        },
        methods: {
            async getData() {
                const colorsList = ['#9C27B0', '#3F51B5', '#4CAF50', '#FFEB3B', '#F57C00', '#F44336', '#795548', '#607D8B', '#757575', '#FFEB3B', '#F57C00', '#F44336'];

                this.$loadingDialog.show();

                await axios.post('Dashboard/Statistics')
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.statistics = data.Data;
                            this.fileStats = this.statistics['Statistics 1'][0];
                            this.containerStats = this.statistics['Statistics 3'][0];
                            this.fileTimeStats = this.statistics['Statistics 4'][0];
                            this.clearedFileTimeStats = this.statistics['Statistics 5'][0];
                            this.clearedContainerTimeStats = this.statistics['Statistics 6'][0];
                            this.top5Customers = this.statistics['Statistics 14'];
                            this.refundStats = this.statistics['Statistics 15'];
                            this.refundsShippingStats = this.statistics['Statistics 19'];

                            /* GENERATING DATA FOR STAGES CHART */

                            let stageChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Stages',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 2'].forEach(element => {
                                stageChart.chartData.labels.push(element.Stage);
                                stageChart.chartData.datasets[0].data.push(element.TotalFiles);
                            });

                            this.stageChart = stageChart

                            /* GENERATING DATA FOR STAGES CHART */

                            /* GENERATING DATA FOR FILES CHART */

                            let filesChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: (new Date().getFullYear() - 1).toString(),
                                            backgroundColor: '#9E9E9E',
                                            data: [],
                                        },
                                        {
                                            label: new Date().getFullYear().toString(),
                                            backgroundColor: '#3F51B5',
                                            data: [],
                                        },
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 7'].forEach(element => {
                                filesChart.chartData.labels.push(element.MonthName);
                                filesChart.chartData.datasets[1].data.push(element.TotalContainers);
                            });

                            this.statistics['Statistics 8'].forEach(element => {
                                filesChart.chartData.datasets[0].data.push(element.TotalContainers);
                            });

                            this.filesChart = filesChart

                            /* GENERATING DATA FOR FILES CHART */

                            /* GENERATING DATA FOR SHIPPING CHART */

                            let shippingChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Shipping Lines',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 9'].forEach(element => {
                                shippingChart.chartData.labels.push(element.ShippingLine);
                                shippingChart.chartData.datasets[0].data.push(element.TotalFiles);
                            });

                            this.shippingChart = shippingChart

                            /* GENERATING DATA FOR SHIPPING CHART */

                            /* GENERATING DATA FOR ORIGIN CHART */

                            let originChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Origin',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 10'].forEach(element => {
                                originChart.chartData.labels.push(element.Origin);
                                originChart.chartData.datasets[0].data.push(element.TotalFiles);
                            });

                            this.originChart = originChart

                            /* GENERATING DATA FOR ORIGIN CHART */

                            /* GENERATING DATA FOR PRODUCT CHART */

                            let productChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Product Categories',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 11'].forEach(element => {
                                productChart.chartData.labels.push(element.ProductCategory);
                                productChart.chartData.datasets[0].data.push(element.TotalFiles);
                            });

                            this.productChart = productChart

                            /* GENERATING DATA FOR PRODUCT CHART */

                            /* GENERATING DATA FOR PRODUCT CHART */

                            let riskChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Risk Level (Containers)',
                                            backgroundColor: ['#F44336', '#FF9800', '#4CAF50'],
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 12'].forEach(element => {
                                riskChart.chartData.labels.push(element.RiskLevel);
                                riskChart.chartData.datasets[0].data.push(element.TotalContainers);
                            });

                            this.riskChart = riskChart

                            /* GENERATING DATA FOR PRODUCT CHART */

                            /* GENERATING DATA FOR FCL TYPES CHART */

                            let fclTypesChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'FCL Types',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 13'].forEach(element => {
                                fclTypesChart.chartData.labels.push(element.FCLType);
                                fclTypesChart.chartData.datasets[0].data.push(element.TotalContainers);
                            });

                            this.fclTypesChart = fclTypesChart

                            /* GENERATING DATA FOR FCL TYPES CHART */

                            /* GENERATING DATA FOR FCL TYPES CHART */
                            let terminalsChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Terminals',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 16'].forEach(element => {
                                terminalsChart.chartData.labels.push(element.Terminal);
                                terminalsChart.chartData.datasets[0].data.push(element.TotalFiles);
                            });

                            this.terminalsChart = terminalsChart
                            /* GENERATING DATA FOR FCL TYPES CHART */

                            /* GENERATING DATA FOR FCL TYPES CHART */
                            let referredByChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Referred By',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 17'].forEach(element => {
                                referredByChart.chartData.labels.push(element.ReferredBy);
                                referredByChart.chartData.datasets[0].data.push(element.TotalCustomers);
                            });

                            this.referredByChart = referredByChart
                            /* GENERATING DATA FOR FCL TYPES CHART */

                            /* GENERATING DATA FOR BUSINESS TYPES CHART */
                            let businessTypeChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Business Type',
                                            backgroundColor: colorsList,
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 18'].forEach(element => {
                                businessTypeChart.chartData.labels.push(element.BusinessType);
                                businessTypeChart.chartData.datasets[0].data.push(element.TotalCustomers);
                            });

                            this.businessTypeChart = businessTypeChart
                            /* GENERATING DATA FOR BUSINESS TYPES CHART */

                            /* GENERATING DATA FOR AVG CLEARING CHART */
                            let avgClearingChart = {
                                chartData: {
                                    labels: [],
                                    datasets: [
                                        {
                                            label: 'Avg Clearing Days',
                                            backgroundColor: '#3F51B5',
                                            data: [],
                                        }
                                    ],
                                },
                                chartOptions: {
                                    responsive: true,
                                    maintainAspectRatio: false
                                },
                            };

                            this.statistics['Statistics 20'].forEach(element => {
                                avgClearingChart.chartData.labels.push(element.MonthName);
                                avgClearingChart.chartData.datasets[0].data.push(element.AvgClearingDays);
                            });

                            this.avgClearingChart = avgClearingChart
                            /* GENERATING DATA FOR BUSINESS TYPES CHART */
                        }
                        else
                            this.$snackBar.error({message: 'An unexpected error has occurred'});

                        this.$loadingDialog.hide();
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });

                // this.$loadingDialog.hide();
            },
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
        },
        mounted() {
            this.$loadingDialog.show();
            this.getSettings();
            this.$loadingDialog.hide();

            this.getData();
        }
    }
</script>

<style scoped>

</style>