<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Users Settings</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                        v-model="users.search"
                        outlined
                        dense
                        hide-details
                        label="Search"
                        name="search"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        class="small-text-field"
                        background-color="white"
                        @keypress.enter="search = $event.target.value">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="1">
                <v-btn
                        @click="newUser"
                        class="green py-4"
                        dark
                        small
                        block
                >
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New User</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6" align="start">
                <v-btn
                        color="red darken-2"
                        small
                        class="mr-1 white--text"
                        @click="resetAccess"
                        :disabled="users.selected.length == 0" >
                    Reset Access
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text"
                        @click="updateStatus(true)"
                        :disabled="users.selected.length == 0">
                    Mark As Active
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="updateStatus(false)"
                        :disabled="users.selected.length == 0" >
                    Mark As Inactive
                </v-btn>
            </v-col>
            <v-col cols="12" md="6" align="end">
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text" >
                    Export To Excel
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text" >
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-data-table
                        v-model="users.selected"
                        :headers="users.headers"
                        :items="users.data"
                        :loading="users.loading"
                        :search="users.search"
                        item-key="UserID"
                        loading-text="Loading Users, please wait..."
                        class="elevation-1"
                        show-select
                        dense
                        disable-pagination
                        hide-default-footer>
                    <template v-slot:item.IsActive="{ item }">
                        <v-icon>{{ item.IsActive ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item dense @click="editUser(item)">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item dense @click="deleteUser(item.UserID)">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-trash</v-icon></v-list-item-icon>
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgUsers"
                title="User"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveUser">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="editingUser.Name"
                                label="Name"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="editingUser.Username"
                                label="Username"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="editingUser.Password"
                                label="Password"
                                type="password"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="editingUser.UserRoleID"
                                label="User Role"
                                :items="userRoles"
                                item-text="UserRole"
                                item-value="UserRoleID"
                                solo
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="editingUser.MobileNumber"
                                label="Mobile"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="editingUser.EmailAddress"
                                label="Email"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios'

    export default {
        title: "Users Settings",
        data(){
            return {
                users: {
                    headers: [
                        { text: 'User ID', value: 'UserID' },
                        { text: 'Username', value: 'Username' },
                        { text: 'Name', value: 'Name' },
                        { text: 'Email', value: 'EmailAddress' },
                        { text: 'Mobile No', value: 'MobileNumber' },
                        { text: 'Role', value: 'UserRole' },
                        { text: 'Active', value: 'IsActive' },
                        { text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    selected: [],
                    search: '',
                    loading: true,
                },
                userRoles: [],
                editingUser: {
                    UserID: 0,
                    UserRoleID: 0,
                    Username: '',
                    Password: '',
                    Name: '',
                    EmailAddress: '',
                    MobileNumber: '',
                }
            };
        },
        computed: {
            userID(){
                return this.$store.getters.userID;
            },
            selectedUsers(){
                let selected = [];
                this.users.selected.forEach(user => {
                   selected.push(user.UserID);
                });
                return selected;
            }
        },
        methods: {
            getData(){
                this.users.loading = true;
                axios.post('Users/GetData', {UserID: this.userID})
                .then(response => {
                    const data = response.data;

                    if(data.Status) {
                        this.users.data = data.Data;
                    }
                    else {
                        this.$snackBar.show({color: 'error', message: data.Message});
                    }

                    this.users.loading = false;
                })
                // eslint-disable-next-line no-unused-vars
                .catch(error => {
                    this.$snackBar.show({color: 'error', message: 'An error has occurred, unable to fetch data.'});
                    this.users.loading = false;
                })
            },
            getUserRoles(){
                this.users.loading = true;
                axios.post('Users/UsersRoles_GetData', {UserID: this.userID, Option: 2})
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.userRoles = data.Data;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }

                        this.users.loading = false;
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$snackBar.show({color: 'error', message: 'An error has occurred, unable to fetch user roles.'});
                        this.users.loading = false;
                    })
            },
            newUser(){
                this.editingUser = {
                    UserID: 0,
                    Username: '',
                    Password: '',
                    Name: '',
                    EmailAddress: '',
                    MobileNumber: '',
                    UserRoleID: 0,
                };

                this.$refs.dlgUsers.show();
            },
            editUser(user){
                this.editingUser = {
                    UserID: user.UserID,
                    Username: user.Username,
                    Password: 'Unchanged',
                    Name: user.Name,
                    EmailAddress: user.EmailAddress,
                    MobileNumber: user.MobileNumber,
                    UserRoleID: user.UserRoleID,
                };

                this.$refs.dlgUsers.show();
            },
            deleteUser(userID){
                let self = this;

                this.$confirmDialog.show({
                    title: 'Delete User',
                    message: `Are you sure you want to delete this user? Please confirm.`,
                    onConfirm: async () => {
                        axios.post('Users/Delete', {UserID: userID})
                            .then(response => {
                                const data = response.data;
                                let success = data.Status;

                                if(success)
                                    self.getData();

                                self.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                                self.$loadingDialog.hide();
                            })
                            // eslint-disable-next-line no-unused-vars
                            .catch(error => {
                                self.$loadingDialog.hide();
                                self.$snackBar.show({color: 'error', message: 'An error has occurred, unable to save user.'});
                            });
                    }
                });
            },
            saveUser(){
                this.$loadingDialog.show();

                const formData = {
                    UpdateUser: this.editingUser,
                };

                axios.post('Users/AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success)
                            this.getData();

                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'An error has occurred, unable to save user.'});
                    });
            },
            updateStatus(status){
                this.$loadingDialog.show();

                const formData = {
                    UpdateUserIDs: this.selectedUsers,
                    Status: status
                };

                axios.post('Users/UpdateStatus', formData)
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success)
                        {
                            this.getData();
                            this.users.selected = [];
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'An error has occurred, unable to update user status.'});
                    });
            },
            resetAccess(){
                this.$loadingDialog.show();

                const formData = {
                    UpdateUserIDs: this.selectedUsers
                };

                axios.post('Users/ResetAccess', formData)
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success)
                        {
                            this.getData();
                            this.users.selected = [];
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'An error has occurred, unable to reset user access.'});
                    });
            }
        },
        created() {
            this.getUserRoles();
            this.getData();
        }
    }
</script>

<style scoped>

</style>