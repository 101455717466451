<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Operation Settings</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Operations
                        <v-spacer></v-spacer>
                        <v-btn
                                class="white--text"
                                color="green"
                                small
                                @click="addStage">
                            <v-icon left>fa-plus-circle</v-icon>
                            Add Operation
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <v-list-item v-for="stage in operations" :key="stage.StageID"
                                         style="border-radius: 5px;"
                                         :class="selectedStage.StageID == stage.StageID ? 'grey lighten-2 elevation-1' : ''"
                                         @click="selectStage(stage)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ stage.Stage }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn small icon @click="editStage(stage)">
                                        <v-icon small>fa-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-btn small icon @click="deleteStage(stage)">
                                        <v-icon small>fa-trash</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Display Fields - {{ selectedStage.Stage }}
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-row dense>
                            <v-col cols="12" md="10">
                                <v-autocomplete
                                        label="Field"
                                        v-model="selectedField"
                                        :items="fileFields"
                                        item-text="FieldDisplayName"
                                        item-value="FieldName"
                                        class="small-text-field"
                                        autocomplete="disable"
                                        return-object
                                        hide-details
                                        dense
                                        solo>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn
                                        class="white--text py-4"
                                        color="green"
                                        small
                                        block
                                        :disabled="!this.selectedStage || !this.selectedField"
                                        @click="addDisplayField">
                                    <v-icon>fa-plus-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-list dense>
                            <draggable
                                    v-model="selectedStage.DisplayFields"
                                    @start="displayFieldDrag = true"
                                    @end="onDisplayDrag($event, selectedStage.DisplayFields)">
                                <v-list-item v-for="field in selectedStage.DisplayFields" :key="field.FieldName">
                                    <v-list-item-icon><v-icon small style="cursor: pointer">fa-bars</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ field.FieldDisplayName }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-btn small icon @click="deleteDisplayField(field)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item-icon>
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="white--text" @click="saveFields(true)">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Update Fields - {{ selectedStage.Stage }}
                        <v-spacer></v-spacer>
                        <v-btn small color="green" class="white--text" @click="addUpdateField"><v-icon left>fa-plus-circle</v-icon> Add</v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <draggable
                                    v-model="selectedStage.UpdateFields"
                                    @start="displayFieldDrag = true"
                                    @end="onDisplayDrag($event, selectedStage.UpdateFields)">
                                <v-list-item v-for="field in selectedStage.UpdateFields" :key="field.FieldName">
                                    <v-list-item-icon><v-icon small style="cursor: pointer">fa-bars</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ field.FieldDisplayName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ field.FieldType }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="!!field.Options">{{ field.Options }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-btn small icon @click="deleteUpdateField(field)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item-icon>
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="white--text" @click="saveFields(false)">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgOperations"
                title="Operation"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveStage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="stage.Stage"
                                label="Item Description"
                                solo
                                dense
                                autofocus
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgFields"
                title="Update Fields"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveUpdateField">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                label="Field Name"
                                v-model="updateField.FieldName"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                label="Field Type"
                                v-model="updateField.FieldType"
                                :items="['Switch', 'Text', 'Date', 'Dropdown', 'Document', 'SMS']"
                                hide-details
                                solo>
                        </v-select>
                    </v-col>
                    <v-col cols="12"
                           v-if="updateField.FieldType == 'Dropdown'">
                        <v-text-field
                                label="Options"
                                v-model="updateField.Options"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12"
                           v-if="updateField.FieldType == 'Document'">
                        <v-text-field
                                label="Document Name"
                                v-model="updateField.Options"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import draggable from 'vuedraggable';

    export default {
        title: "Operation Settings",
        components: {
            draggable,
        },
        data(){
            return {
                operations: [],
                selectedStage: {},
                fileFields: [],
                selectedField: {},
                displayFieldDrag: false,
                updateFieldDrag: false,
                updateField: {
                    FieldName: '',
                    FieldType: '',
                    Options: '',
                },
                stage: {
                    StageID: 0,
                    Stage: ''
                },
            };
        },
        computed: {
            displayMaxSeq() {
                return this.selectedStage.DisplayFields.length == 0 ? 0 : Math.max.apply(Math, this.selectedStage.DisplayFields.map(function(o){return o.Seq;}));
            },
            updateMaxSeq() {
                return this.selectedStage.UpdateFields.length == 0 ? 0 : Math.max.apply(Math, this.selectedStage.UpdateFields.map(function(o){return o.Seq;}));
            }
        },
        methods: {
            getData(){
                this.$loadingDialog.show();

                axios.post('Stages/GetData', {Option: 1})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.operations = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            getFileFields() {
                this.$loadingDialog.show();

                axios.post('Stages/GetFileFields', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.fileFields = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            addStage(){
              this.stage = {
                  StageID: 0,
                  Stage: '',
              };

              this.$refs.dlgOperations.show();
            },
            editStage(stage){
              this.stage = stage;
              this.$refs.dlgOperations.show();
            },
            deleteStage(stage){
                let vm = this;

                this.$confirmDialog.show({
                   title: 'Delete Stage',
                   message: 'Are you want to delete this stage? Please confirm.',
                   onConfirm(){
                       vm.$loadingDialog.show();

                       const formData = {
                           StageID: stage.StageID
                       };

                       axios.post('Stages/Delete', formData)
                           .then(response => {
                               const data = response.data;
                               const status = data.Status;

                               if(status)
                               {
                                   vm.selectedStage = {};
                                   vm.getData();
                               }

                               vm.$loadingDialog.hide();
                               vm.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                           })
                           .catch(error => {
                               console.log(error);
                               vm.$snackBar.error({message: 'An unexpected error has occurred'});
                               vm.$loadingDialog.hide();
                           });
                   }
                });
            },
            saveStage(){
                this.$loadingDialog.show();

                const formData = this.stage;

                axios.post('Stages/AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.selectedStage = {};
                            this.getData();
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            selectStage(stage) {
                this.selectedStage = stage;
            },
            onDisplayDrag(ev, fields) {
                const oldSeq = ev.oldIndex + 1;
                const newSeq = ev.newIndex + 1;

                if( oldSeq != newSeq )
                {
                    if( newSeq < oldSeq )
                    {
                        fields.forEach(element => {
                            if(element.Seq >= newSeq && element.Seq < oldSeq)
                                element.Seq += 1;
                            else if(element.Seq == oldSeq)
                                element.Seq = newSeq;
                        });
                    }
                    else
                    {
                        fields.forEach(element => {
                            if(element.Seq == oldSeq)
                                element.Seq = newSeq
                            else if(element.Seq > oldSeq && element.Seq <= newSeq)
                                element.Seq -= 1;
                        });
                    }
                }

                fields.forEach(element => {
                    console.log(element.FieldName, element.Seq);
                });
            },
            addDisplayField() {
                this.selectedStage.DisplayFields.push({
                    FieldName: this.selectedField.FieldName,
                    FieldDisplayName: this.selectedField.FieldDisplayName,
                    FieldType: '',
                    IsDisplay: true,
                    Seq: this.displayMaxSeq + 1,
                });

                this.selectedField = {};
            },
            deleteDisplayField(field){
                const ind = this.selectedStage.DisplayFields.indexOf(field);
                const seq = field.Seq;
                this.selectedStage.DisplayFields.splice(ind, 1);

                this.selectedStage.DisplayFields.forEach(element => {
                    if(element.Seq > seq )
                        element.Seq = element.Seq - 1;
                });

                console.log(this.selectedStage.DisplayFields);
            },
            addUpdateField(){
                this.updateField = {
                    FieldName: '',
                    FieldType: '',
                    Options: '',
                };

                this.$refs.dlgFields.show();
            },
            saveUpdateField() {
                this.selectedStage.UpdateFields.push({
                    FieldName: this.updateField.FieldName,
                    FieldDisplayName: this.updateField.FieldName,
                    FieldType: this.updateField.FieldType,
                    Options: this.updateField.Options,
                    IsDisplay: false,
                    Seq: this.updateMaxSeq + 1,
                });

                console.log(this.selectedStage.UpdateFields);
            },
            deleteUpdateField(field){
                const ind = this.selectedStage.UpdateFields.indexOf(field);
                const seq = field.Seq;
                this.selectedStage.UpdateFields.splice(ind, 1);

                this.selectedStage.UpdateFields.forEach(element => {
                    if(element.Seq > seq )
                        element.Seq = element.Seq - 1;

                    console.log(element.FieldName, element.Seq);
                });

            },
            saveFields(isDisplay) {
                this.$loadingDialog.show();

                const formData = {
                    StageID: this.selectedStage.StageID,
                    IsDisplay: isDisplay,
                    Fields: isDisplay ? this.selectedStage.DisplayFields : this.selectedStage.UpdateFields,
                };

                axios.post('Stages/AddEditFields', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            }
        },
        created(){
            this.getData();
            this.getFileFields();
        },
    }
</script>

<style scoped>

    .card-scroll {
        height: 65vh;
    }

</style>