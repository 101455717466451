import loadingDialog from "./loadingDialog.vue";

const LoadingDialog = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    // We must create new Eventbus
    // which is just another Vue instance that will be listening for and emitting events from our main instance
    // this EventBus will be available as Modal.EventBus
    this.EventBus = new Vue();

    // making our modal component global
    Vue.component('loading-dialog', loadingDialog);

    // exposing global $modal object with method show()
    // method show() takes object params as argument
    // inside this object we can have modal title, text, styles... and also our callback confirm function
    Vue.prototype.$loadingDialog = {
      show(params) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        LoadingDialog.EventBus.$emit('show', params)
      },
      hide(params) {
        // if we use this.$modal.show(params) inside our original Vue instance
        // we will emit 'show' event with parameters 'params'
        LoadingDialog.EventBus.$emit('hide', params)
      },
    }
  }
}

export default LoadingDialog
