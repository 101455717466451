<template>
    <v-container fluid>
        <v-row>
            <v-col md="2">
                <v-img max-height="100px" src="@/assets/logo.png" contain></v-img>
            </v-col>
            <v-col md="10" align="right">
                <v-img height="150px" :src="settings.ClientBanner01"></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="3" offset-md="1">
                <v-text-field
                        v-model="customer.CustomerNo"
                        label="Customer No"
                        class="small-text-field elevation-1"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="customer.CompanyName"
                        label="Company Name"
                        class="small-text-field mt-2 elevation-1"
                        background-color="white"
                        outlined
                        dense
                        autocomplete="disabled"
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="customer.CompanyRegistrationDate"
                        label="Company Registration Date"
                        class="small-text-field mt-2 elevation-1"
                        type="date"
                        background-color="white"
                        outlined
                        dense
                        autocomplete="disabled"
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="customer.TINNo"
                        label="TIN No"
                        class="small-text-field mt-2 elevation-1"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="customer.IDType"
                        label="ID Type"
                        class="small-text-field mt-2 elevation-1"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="customer.BusinessType"
                        label="Business Type"
                        class="small-text-field mt-2 elevation-1"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>
                        Contact Persons
                    </v-card-title>
                    <v-list v-if="customer.CustomersPersons.length > 0">
                        <v-list-item v-for="contact in customer.CustomersPersons" :key="contact.ContactPerson">
                            <v-list-item-content>
                                <v-list-item-title>{{ contact.ContactPerson }}</v-list-item-title>
                                <v-list-item-subtitle>Mobile: {{ contact.Mobile }}</v-list-item-subtitle>
                                <v-list-item-subtitle>WhatsApp: {{ contact.Whatsapp }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Phone: {{ contact.Phone }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Email: {{ contact.Email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Address: {{ contact.Address }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Contact Person<br/>click <b>"ADD CONTACT"</b> button above
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card>
                    <v-card-title>
                        Delivery Address
                    </v-card-title>
                    <v-list
                            dense
                            v-if="customer.CustomersLocations.length > 0" >
                        <v-list-item
                                v-for="location in customer.CustomersLocations"
                                :key="location.CustomerLocationID">
                            <v-list-item-content>
                                <v-list-item-title>{{ location.Address }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Delivery Address<br/>click <b>"ADD ADDRESS"</b> button above
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "My Profile",
        data(){
            return {
                settings: {},
                customer: {},
            };
        },
        methods: {
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            async getData(){
                await axios.post('ClientsPortal/Profile', {FileID: 0})
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.customer = data.Data;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });
            }
        },
        async created() {
            this.$loadingDialog.show();
            await this.getSettings();
            await this.getData();
            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>