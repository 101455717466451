<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Currency Settings</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>
                        Currencies
                        <v-spacer></v-spacer>
                        <v-btn
                                @click="newCurrency"
                                class="green py-4"
                                dark
                                small
                        >
                            <v-icon left>mdi-plus-circle</v-icon>
                            <span>New Currency</span>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <v-list-item v-for="currency in currencies" :key="currency.CurrencyID" dense>
                                <v-list-item-content>
                                    <v-list-item-title>{{ currency.ShortName }}</v-list-item-title>
                                    <v-list-item-subtitle>Name: <b>{{ currency.Currency }}</b> Symbol: <b>{{ currency.Symbol }}</b></v-list-item-subtitle>
                                    <v-list-item-subtitle v-if="currency.IsLocalCurrency || currency.IsReportingCurrency">
                                        <span :class="currency.IsLocalCurrency ? 'green--text text--darken-2' : 'blue--text text--darken-2'">{{ currency.IsLocalCurrency ? "Local Currency" : (currency.IsReportingCurrency ? "Reporting Currency" : "") }}</span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon><v-btn icon @click="editCurrency(currency)"><v-icon>fa-pencil</v-icon></v-btn></v-list-item-icon>
                                <v-list-item-icon><v-btn icon @click="deleteCurrency(currency.CurrencyID)"><v-icon>fa-trash</v-icon></v-btn></v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-card>
                    <v-card-title>
                        Exchange Rates
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-data-table
                                :headers="currencyExchangeRates.header"
                                :items="currencies"
                                class="elevation-1"
                                dense
                                disable-pagination
                                hide-default-footer>
                            <template v-slot:item.LocalExchangeRate="{item}">
                                <v-text-field
                                        v-model="item.LocalExchangeRate"
                                        hide-details
                                        dense
                                        solo
                                        class="small-text-field mt-2"
                                        @keydown.enter="updateRates(item)">
                                </v-text-field>
                            </template>
                            <template v-slot:item.ReportingExchangeRate="{item}">
                                <v-text-field
                                        v-model="item.ReportingExchangeRate"
                                        hide-details
                                        dense
                                        solo
                                        class="small-text-field mt-2"
                                        @keydown.enter="updateRates(item)" >
                                </v-text-field>
                            </template>
                            <template v-slot:item.Actions>
                                <v-btn
                                        text
                                        small>
                                    View History
                                </v-btn>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgCurrency"
                title="Currency"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveCurrency">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="currency.ShortName"
                                label="Currency"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="currency.Symbol"
                                label="Currency Symbol"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                                v-model="currency.Currency"
                                label="Currency Name"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-checkbox
                                v-model="currency.IsLocalCurrency"
                                label="Is Local Currency"
                                solo
                                dense
                                hide-details="auto"
                                @change="currency.IsReportingCurrency = false">
                        </v-checkbox>
                    </v-col>

                    <v-col cols="12" md="6">
                        <v-checkbox
                                v-model="currency.IsReportingCurrency"
                                label="Is Reporting Currency"
                                solo
                                dense
                                hide-details="auto"
                                @change="currency.IsLocalCurrency = false">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "Currency Settings",
        data(){
            return {
                currencies: [],
                currencyExchangeRates: {
                    header: [
                        { text: 'Currency', value: 'Currency' },
                        { text: 'Local Exch. Rate', value: 'LocalExchangeRate', width: '150px' },
                        { text: 'Reporting Exch. Rate', value: 'ReportingExchangeRate', width: '150px' },
                        { text: 'Updated On', value: 'UpdateDate' },
                        { text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    loading: false,
                },
                currency: {
                    CurrencyID: 0,
                    Currency: '',
                    ShortName: '',
                    Symbol: '',
                    IsLocalCurrency: false,
                    IsReportingCurrency: false,
                }
            };
        },
        methods: {
            async getData() {
                this.$loadingDialog.show();

                await axios.post('Currencies/GetData', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.currencies = data.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
            newCurrency(){
                this.currency = {
                    CurrencyID: 0,
                    Currency: '',
                    ShortName: '',
                    Symbol: '',
                    IsLocalCurrency: false,
                    IsReportingCurrency: false,
                };

                this.$refs.dlgCurrency.show();
            },
            editCurrency(currency){
                this.currency = currency;
                this.$refs.dlgCurrency.show();
            },
            async saveCurrency(){
                this.$loadingDialog.show();

                await axios.post('Currencies/AddEdit', {Currency: this.currency})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData()

                        this.$snackBar.show({color: status ? 'success' : 'error', message: 'An unexpected error has occurred'});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
            deleteCurrency(currencyID){
                this.$confirmDialog.show({
                    title: 'Delete Currency',
                    message: 'Are you sure you want to delete this currency? This action cannot be undone.',
                    async onConfirm(){
                        this.$loadingDialog.show();

                        await axios.post('Currencies/Delete', {CurrencyID: currencyID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData()

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error({message: 'An unexpected error has occurred'});
                            });

                        this.$loadingDialog.hide();
                    },
                });
            },
            async updateRates(currency){
                this.$loadingDialog.show();

                const formData = {
                    CurrencyID: currency.CurrencyID,
                    LocalExchangeRate: currency.LocalExchangeRate,
                    ReportingExchangeRate: currency.ReportingExchangeRate,
                };

                await axios.post('Currencies/UpdateRates', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData()

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
        },
        created(){
            this.getData();
        },
    }
</script>

<style scoped>

    .card-scroll {
        height: 70vh;
    }

</style>