<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">{{ isClearedFiles ? 'Cleared Files' : 'Out Of Delivery List' }}</h2>
            </v-col>
        </v-row>
<!--        <v-row dense>-->
<!--            <v-col cols="12" md="12">-->
<!--                <v-card>-->
<!--                    <v-card-text class="d-flex">-->
<!--                        <h4>Total Records: <span class="indigo&#45;&#45;text text&#45;&#45;darken-2">{{ files.totals.TotalRecords }}</span></h4>-->
<!--                        <h4 class="ml-2">Total Approved: <span class="indigo&#45;&#45;text text&#45;&#45;darken-2">{{ files.totals.Approved }}</span></h4>-->
<!--                        <h4 class="ml-2">Total Not-Approved: <span class="indigo&#45;&#45;text text&#45;&#45;darken-2">{{ files.totals.NotApproved }}</span></h4>-->
<!--                    </v-card-text>-->
<!--                </v-card>-->
<!--            </v-col>-->
<!--        </v-row>-->
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="files.selected"
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td><v-icon>fa-filter</v-icon></td>
                            <td v-for="header in files.headers" :key="header.value">
                                <v-text-field
                                        v-model="files.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.Stage="{item}">
                        <v-btn
                                color="primary"
                                class="white--text my-1"
                                small
                                block
                                @click="updateStage(item)">
                            {{ item.Stage }}
                        </v-btn>
                    </template>
                    <template v-slot:item.ViewFile="{item}">
                        <v-btn
                                color="primary"
                                class="white--text my-1"
                                icon
                                small
                                block
                                @click="viewFile(item.FileID)">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.FKStatusID="{ item }">
                        <v-btn
                                v-if="item.FKStatusID == 3"
                                color="orange"
                                class="white--text my-1"
                                small
                                block
                                @click="approveFile(item, 4)">
                            Clear Shipment
                        </v-btn>
                        <v-btn
                                color="orange"
                                class="white--text my-1"
                                small
                                block
                                @click="showWarehouse(item)"
                                v-if="item.FKStatusID != 4 && item.FKStageID != 90">
                            Transfer To Warehouse
                        </v-btn>
                    </template>
                    <template v-slot:item.Actions="{ item }">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="selectDocument(item, 'Waybill')" dense v-if="!isClearedFiles">
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-upload</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Upload Waybill</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="selectDocument(item, 'Document')" dense v-if="isClearedFiles">
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-upload</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Upload Documents</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="viewCustomer(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-user</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Customer</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="viewBookings(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-truck</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Bookings</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="viewDocuments(item.FileID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-file</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Documents</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="viewSMS(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Send SMS</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgWarehouse"
                title="Move To Warehouse"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="moveToWarehouse">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.FileNo"
                                label="File No"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.FKWarehouseID"
                                label="Warehouse"
                                :items="warehouses"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.Quantity"
                                label="Quantity"
                                type="number"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.Unit"
                                label="Unit"
                                :items="units"
                                item-text="TextLine"
                                item-value="TextLine"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.Value"
                                label="Total Value"
                                type="number"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.FKCurrencyID"
                                label="Currency"
                                :items="currencies"
                                item-text="ShortName"
                                item-value="CurrencyID"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgStage"
                title="Move File Stage"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="moveFile">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="selectedFile.FileNo"
                                label="File No"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="selectedFile.FKStageID"
                                label="Stage"
                                :items="stages"
                                item-text="Stage"
                                item-value="StageID"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocuments"
                title="All Documents"
                max-width="600"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-list dense>
                    <template  v-if="fileDocuments.length > 0">
                        <v-list-item v-for="doc in fileDocuments" :key="doc.DocumentID" link :href="doc.FilePath" target="_blank">
                            <v-list-item-content>
                                <v-list-item-title>{{ doc.DocumentTitle }}</v-list-item-title>
                                <v-list-item-title v-if="doc.DocumentNo.length > 0">Document # {{ doc.DocumentNo }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title>No Documents Are Available</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocument"
                :title="documentTitle"
                confirm-button-text="Save Document"
                cancel-button-text="Close"
                :on-confirm="saveWaybill">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentTitle"
                                label="Document Title"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                class="mt-2"
                                color="primary"
                                dark
                                block >
                            Upload {{ document.DocumentType }}
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadWaybill" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgLoading"
                title="Loading Truck"
                confirm-button-text=""
                cancel-button-text="Close"
                max-width="1300">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table
                                :headers="loading.header"
                                :items="loading.data"
                                class="elevation-1"
                                dense
                                disable-pagination
                                hide-default-footer>
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from "moment";

    export default {
        title: "Files",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S.No', value: 'SNo', applyFilter: false },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'BL Status', value: 'BLStatus', applyFilter: true },
                        { text: 'ETA Date', value: 'ETADate', applyFilter: true },
                        { text: 'Shipping Line', value: 'ShippingLine', applyFilter: true },
                        // { text: 'Vessel', value: 'VesselName', applyFilter: true },
                        { text: 'FCL', value: 'FCL', applyFilter: true },
                        { text: 'Ref No', value: 'Reference', applyFilter: true },
                        { text: 'Product Description', value: 'ProductDescription', applyFilter: true },
                        { text: 'Terminal', value: 'Terminal', applyFilter: true },
                        { text: 'Created By', value: 'CreatedBy', applyFilter: true },
                        { text: 'Created On', value: 'CreateDate', applyFilter: true },
                        { text: 'Stage', value: 'Stage', applyFilter: true },
                        { text: '', value: 'ViewFile', applyFilter: false },
                        { text: 'Actions', value: 'Actions', applyFilter: false },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: true,
                },
                stages: [],
                selectedFile: {},
                warehouses: [],
                units: [],
                currencies: [],
                warehouse: {
                    FileID: 0,
                    FileNo: '',
                    FKWarehouseID: 0,
                    FKCurrencyID: 0,
                    Quantity: 0,
                    Unit: '',
                    Value: 0,
                },
                fileDocuments: [],
                document: {
                    DocumentType: '',
                    DocumentID: 0,
                    FKDocumentTypeID: 2,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
                loading: {
                    header: [
                        {text: 'S.No', value: 'SNo'},
                        {text: 'BookingID', value: 'BookingID'},
                        {text: 'Loading Date', value: 'LoadingDate'},
                        {text: 'Booking Type', value: 'BookingType'},
                        {text: 'Driver', value: 'Driver'},
                        {text: 'Driver Phone', value: 'DriverPhone'},
                        {text: 'Booked Containers', value: 'NoOfContainers'},
                        {text: 'Loaded Containers', value: 'LoadedContainers', width: '150'},
                    ],
                    data: [],
                },
                message: {
                    FileID: 0,
                    Message: '',
                },
            };
        },
        computed: {
            isClearedFiles(){
                return this.$route.name == 'clearedFiles';
            },
            documentTitle(){
                return `${this.document.DocumentType}`;
            }
        },
        methods: {
            getData(){
                this.files.loading = true;

                const formData = {
                    FileID: 0,
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: false,
                    IsActive: false,
                    Filters: this.files.filters,
                };

                axios.post('Files/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            approveFile(file, fileStatus) {
                this.$confirmDialog.show({
                    title: fileStatus == 2 ? 'Approve Clearing' : 'Cancel Clearing',
                    message: `Are you sure you want to ${ fileStatus == 4 ? 'clear' : 'cancel clearing of' } the selected file? Please confirm.`,
                    onConfirm: async () => {
                        this.$loadingDialog.show();

                        const formData = {
                            FileID: file.FileID,
                            FKStatusID: fileStatus,
                        };

                        await axios.post('Files/ApproveClearing', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(() => {
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            });

                        this.$loadingDialog.hide();
                    }
                });
            },
            viewFile(fileID){
                const routeData = this.$router.resolve({name: 'filesAddEdit', params: {"action": "edit"}, query: {id: fileID}});
                window.open(routeData.href, '_blank');
            },
            updateStage(file) {
                if(file.FKStageID == 0) {
                    this.$snackBar.info({message: 'Cannot move a new file'});
                }
                else {
                    this.selectedFile = file;
                    this.$refs.dlgStage.show();
                }
            },
            moveFile(){
                this.$confirmDialog.show({
                    title: 'Move File',
                    message: 'Are you sure you want to move the selected file? Please confirm.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            FileID: this.selectedFile.FileID,
                            StageID: this.selectedFile.FKStageID,
                        };

                        axios.post('Files/MoveFile', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                this.$loadingDialog.hide();
                            })
                            .catch(() => {
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                                this.$loadingDialog.hide();
                            });
                    }
                });
            },
            showWarehouse(file) {
                this.warehouse = {
                    FileID: file.FileID,
                    FileNo: file.FileNo,
                    FKWarehouseID: 0,
                    Quantity: 0,
                    Unit: '',
                    Value: 0,
                };

                this.$refs.dlgWarehouse.show();
            },
            async moveToWarehouse(){
                this.$loadingDialog.show();

                const result = await axios.post('Files/MoveToWarehouse', this.warehouse)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});

                        return status;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        return false;
                    });

                if(result)
                    this.getData();

                this.$loadingDialog.hide();
            },
            async viewDocuments(fileID){
                this.$loadingDialog.show();

                this.fileDocuments = await axios.post('Files/GetAllDocuments', {FileID: fileID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        return status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$refs.dlgDocuments.show();
                this.$loadingDialog.hide();
            },
            selectDocument(file, document){
                this.document = {
                    DocumentType: document,
                    DocumentID: 0,
                    FKDocumentTypeID: 2,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                };

                this.selectedFile = file;

                this.$refs.dlgDocument.show();
            },
            uploadWaybill(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.document.error = "File size is exceeding the limit. Please select a smaller picture.";
                    return;
                }

                this.document.File = file;
                this.document.FilePath = URL.createObjectURL(file);
                this.document.name = file.name;
                this.document.size = size;
                this.document.error = "";
            },
            async saveWaybill() {
                this.$loadingDialog.show();

                let formData = new FormData();

                formData.append('FileID', this.selectedFile.FileID);
                formData.append('CustomerID', this.selectedFile.FKCustomerID);

                if(this.document.File != null)
                {
                    Object.keys(this.document).forEach(key => {
                        formData.append(`Documents[0].${key}`, this.document[key] == null ? '' : this.document[key]);
                    });
                }

                await axios.post('Files/UploadWaybill', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    }).catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

                this.$loadingDialog.hide();
            },
            viewCustomer(file)
            {
                const cust = this.$router.resolve({name: 'clientsAddEdit', params: {"action": "edit"}, query: {id: file.FKCustomerID}});
                window.open(cust.href, '_blank');
            },
            viewBookings(file)
            {
                this.loading.data = file.Bookings;
                this.loading.data.forEach(element => {
                    element.LoadingDate = moment(element.LoadingDate).format('D-MMM-yyyy hh:mm a');
                });
                this.$refs.dlgLoading.show();
            },
            viewSMS(file){
                this.message = {
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
        },
        async created() {
            this.files.filters['FKStatusID'] = this.isClearedFiles ? 4 : 3;

            if(!this.isClearedFiles)
                this.files.headers.splice(this.files.headers.length-1, 0, { text: this.isClearedFiles ? 'Status' : 'Approval', value: 'FKStatusID', applyFilter: false });
                // this.files.headers.push({ text: this.isClearedFiles ? 'Status' : 'Approval', value: 'FKStatusID', applyFilter: false });
            else
            {
                this.files.headers.splice(12, 0, { text: 'Cleared Date', value: 'ClearedDate', applyFilter: true });
                this.files.headers.splice(13, 0, { text: 'Clearing Days', value: 'ClearingDays', applyFilter: true });
                this.files.headers.splice(14, 0, { text: 'Cleared By', value: 'ClearedBy', applyFilter: true });
            }

            this.getData();

            this.$loadingDialog.show();

            await this.getStages()
                .then(response => {
                    this.stages = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(14, 0)
                .then(response => {
                    this.warehouses = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                })

            await this.getGeneral(15, 0)
                .then(response => {
                    this.units = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                })

            await this.getCurrencies()
                .then(response => {
                    this.currencies = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                });

            console.log(this.currencies);

            this.$loadingDialog.hide();
        },
    }
</script>

<style scoped>

</style>