<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">{{ stage.Stage }}</h2>
            </v-col>
            <v-spacer v-if="stageID == 2"></v-spacer>
            <v-col v-if="stageID == 2" cols="12" md="2" align="end">
                <v-menu
                        offset-y
                        transition="scale-transition"
                        :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="fromDate"
                                label="From Date"
                                class="small-text-field mb-2"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="fromDatePicker"
                            :min="this.toShortDateString(new Date())"
                            @change="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col v-if="stageID == 2" cols="12" md="2" align="end">
                <v-menu
                        offset-y
                        transition="scale-transition"
                        :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="toDate"
                                label="To Date"
                                class="small-text-field mb-2"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="toDatePicker"
                            @change="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" align="end">
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="exportToExcel">
                    Export To Excel
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="ml-1 white--text">
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="table.selected"
                        :headers="table.headers"
                        :items="table.data"
                        :loading="table.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="table.pagination.page"
                        :items-per-page="table.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr>
                            <td v-for="(header, i) in table.headers" :key="header.value">
                                <template v-if="i === 0">
                                    <v-icon>fa-filter</v-icon>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            v-model="table.filters[header.value]"
                                            class="small-text-field my-1"
                                            background-color="white"
                                            outlined
                                            dense
                                            hide-details
                                            autcomplete="disable"
                                            @change="customFilter($event, header.value)"
                                            v-if="header.applyFilter && !header.value.includes('Date')">
                                    </v-text-field>
                                    <v-menu
                                            offset-y
                                            transition="scale-transition"
                                            :close-on-content-click="true"
                                            v-if="header.applyFilter && header.value.includes('Date')">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="table.filters[header.value]"
                                                    class="small-text-field my-1"
                                                    background-color="white"
                                                    outlined
                                                    dense
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="table.filters[header.value]"
                                                @change="customFilter($event, header.value)">
                                            <v-spacer></v-spacer>
                                            <v-btn color="grey" class="white--text" small
                                                   @click="customFilter('', header.value)">Clear</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </td>
                        </tr>
                        <template v-if="table.data.length > 0">
                            <tr v-for="item in items" :key="item.FileID">
                                <td v-for="header in table.headers" :key="header.value" class="text-md-body-2 py-1">
                                    <template v-if="header.isDisplay">
                                        <template v-if="header.value === 'BLStatus'">
                                            <span :class="item.BLStatus === 'Copy' ? 'red--text' : 'green--text darken-4'"><b>{{ item.BLStatus }}</b></span>
                                        </template>
                                        <template v-else-if="header.value === 'VesselStatus'">
                                            <v-chip label :color="item.VesselStatus === 'ARRIVED' ? 'orange' : 'green'" text-color="white">{{ item.VesselStatus }}</v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'RiskLevel'">
                                            <v-chip label :color="item.RiskLevelColor.toLowerCase()" text-color="white">{{ item.RiskLevel.toUpperCase() }}</v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'Priority'">
                                            <v-chip
                                                    label
                                                    :color="item.Priority === 'Normal' ? 'green' : item.Priority === 'Medium' ? 'orange' : item.Priority === 'Urgent' ? 'purple' : 'red'"
                                                    text-color="white">
                                                {{ item.Priority.toUpperCase() }}
                                            </v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'Actions'">
                                            <v-menu left offset-x>
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            text
                                                            small>
                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                    </v-btn>
                                                </template>
                                                <v-list dense>
                                                    <v-list-item @click="viewClearing(item)" dense>
                                                        <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>Edit Estimated Clearing Date</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                        <template v-else>
                                            {{ item[header.value] }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="header.fieldType === 'Switch'">
                                            <v-tooltip top open-delay="1200">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div v-bind="attrs"
                                                         v-on="on"
                                                         style="width: min-content;"
                                                         @mouseenter="fieldHistory(item.FileID, header.value)">
                                                        <v-switch
                                                                v-model="item[header.value]"
                                                                hide-details
                                                                class="mt-1 mb-2"
                                                                :readonly="!rights.allowEdit"
                                                                @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                                        </v-switch>
                                                    </div>
                                                </template>
                                                <span>{{ tooltip.value }}</span>
                                            </v-tooltip>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Text'">
                                            <v-text-field
                                                    v-model="item[header.value]"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @keydown.enter="updateData(item.FileID, header.fieldType, header.seq, header.value, item[header.value])">
                                            </v-text-field>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Date'">
                                            <v-menu
                                                    offset-y
                                                    transition="scale-transition"
                                                    :close-on-content-click="true">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            :value="item[header.value].length > 0 ? toShortDateString(new Date(item[header.value])) : ''"
                                                            dense
                                                            solo
                                                            hide-details
                                                            class="mt-1 mb-2"
                                                            v-bind="attrs"
                                                            v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                        :value="item[header.value].length > 0 ? toShortDateString(new Date(item[header.value])) : ''"
                                                        :readonly="!rights.allowEdit"
                                                        @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                                </v-date-picker>
                                            </v-menu>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Document'">
                                            <v-btn v-if="header.options == 'MDA'"
                                                   color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="viewMDA(item)">
                                                {{ header.options }}
                                            </v-btn>
                                            <v-btn v-else
                                                   color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="viewDocument(item, header.options)">
                                                {{ header.options }}
                                            </v-btn>
                                        </template>
                                        <template v-else-if="header.fieldType === 'SMS'">
                                            <v-btn color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="viewSMS(item)">
                                                SMS
                                            </v-btn>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Dropdown'">
                                            <v-select
                                                    v-if="header.value === 'Terminal'"
                                                    v-model="item.FKTerminalID"
                                                    item-text="Terminal"
                                                    item-value="TerminalID"
                                                    :items="terminals"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                            </v-select>
                                            <v-select
                                                    v-else
                                                    v-model="item[header.value]"
                                                    :items="header.options.split(',')"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                            </v-select>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td :colspan="table.headers.length" class="grey--text text-center">No data available</td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="table.pagination.page" :length="table.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgDocument"
                title="Document"
                :confirm-button-text="stageID == 4 ? '' : 'Save Document'"
                cancel-button-text="Close"
                :on-confirm="saveDocument">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-btn
                                color="blue darken-1"
                                class="white--text mb-2"
                                :href="document.FilePath"
                                target="_blank"
                                link
                                block
                                v-if="document.FilePath.length > 0">
                            View {{ document.DocumentTitle }}
                        </v-btn>
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                color="primary"
                                dark
                                block >
                            Upload {{ document.DocumentTitle }}
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadDocument" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgMDA"
                title="MDA"
                confirm-button-text="Save"
                cancel-button-text="Close"
                :on-confirm="saveDocument">
            <template v-slot:content>
                <v-list dense>
                    <v-list-item dense v-for="document in selectedFile.Documents"
                                 :key="document.DocumentTitle"
                                 @click="viewDocument(selectedFile, document.DocumentTitle)">
                        <v-list-item-content>
                            <v-list-item-title :class="!!document.FilePath ? '' : 'red--text'">{{ document.DocumentTitle }}</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action><v-btn icon small><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                    </v-list-item>
                </v-list>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgEstimatedClearing"
                title="Estimated Clearing Date"
                confirm-button-text="Save"
                cancel-button-text="Close"
                max-width="500"
                :on-confirm="saveClearing">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <date-picker label="Estimated Clearing Date" v-model="clearing.EstimatedClearingDate" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        title: "Operations",
        data() {
            return {
                stage: {},
                table: {
                    headers: [
                        { text: 'S. No', value: 'SNo', applyFilter: false, isDisplay: true },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 30,
                    },
                    totals: {},
                    loading: false,
                },
                fromDatePicker: '',
                toDatePicker: '',
                selectedFile: {},
                document: {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
                tooltip: {
                    show: false,
                    value: "",
                },
                documents: [],
                terminals: [],
                message: {
                    FileID: 0,
                    Message: '',
                },
                clearing: {
                    FileID: 0,
                    EstimatedClearingDate: '',
                },
                rights: {
                    allowEdit: false,
                },
            };
        },
        computed: {
            stageID() {
                return this.$route.params.stage;
            },
            fromDate() {
                if(this.fromDatePicker)
                {
                    let f_date = this.fromDatePicker.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
            toDate() {
                if(this.toDatePicker)
                {
                    let f_date = this.toDatePicker.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
        },
        methods: {
            customFilter(value, field){
                if( value )
                    this.table.filters[field] = value;
                else
                    delete this.table.filters[field];

                this.getData();
            },
            async getFields(){
                this.$loadingDialog.show();

                await axios.post('Operations/GetFields', {StageID: this.stageID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status) {
                            this.stage = data.Data;

                            this.stage.DisplayFields.forEach(element => {
                                this.table.headers.push(
                                    {
                                        text: element.FieldDisplayName,
                                        value: element.FieldName,
                                        applyFilter: true,
                                        fieldType: element.FieldType,
                                        isDisplay: true,
                                        seq: element.Seq,
                                    }
                                );
                            });

                            this.table.headers.push(
                                {
                                    text: 'Duration',
                                    value: 'Duration',
                                    applyFilter: true,
                                    fieldType: '',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );

                            this.stage.UpdateFields.forEach(element => {
                                this.table.headers.push(
                                    {
                                        text: element.FieldDisplayName,
                                        value: element.FieldName,
                                        applyFilter: false,
                                        fieldType: element.FieldType,
                                        options: element.Options,
                                        isDisplay: false,
                                        seq: element.Seq,
                                        width: element.FieldType === 'Text' || element.FieldType === 'Dropdown' ? '175px' : '',
                                    }
                                );
                            });

                            this.table.headers.push(
                                {
                                    text: 'Actions',
                                    value: 'Actions',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });
                this.$loadingDialog.hide();
            },
            getData() {
                return new Promise((resolve, reject) => {
                    this.$loadingDialog.show();

                    const formData = {
                        PageNo: this.table.pagination.page,
                        NoOfRows: this.table.pagination.rowsPerPage,
                        HasFilter: !!Object.keys(this.table.filters).length,
                        StageID: this.stageID,
                        Filters: this.table.filters,
                        FromDate: this.fromDate,
                        ToDate: this.toDate,
                    };

                    axios.post('Operations/GetData', formData)
                        .then(response => {
                            const data = response.data;

                            if(data.Status) {
                                this.table.totals = data.Data.Totals;
                                this.table.pagination.totalPages = data.Data.TotalPages;
                                this.table.data = data.Data.Items;

                                resolve(data);
                            }
                            else {
                                this.$snackBar.error({message: data.Message});
                                reject(data);
                            }

                            this.$loadingDialog.hide();
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            this.$loadingDialog.hide();
                            reject(error);
                        });
                })
            },
            updateData(fileID, fieldType, seq, field, value) {
                this.$loadingDialog.show();

                let val = value;

                if(fieldType === 'Switch')
                    val = val == false ? '' : true;

                const formData = {
                    FileID: fileID,
                    StageID: this.stageID,
                    Field: field,
                    Value: val,
                    Seq: seq,
                };

                axios.post('Operations/Update', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status && field == 'Terminal')
                        {
                            this.$emit('onTerminalAssigned');
                        }

                        this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });
            },
            async fieldHistory(fileID, field) {
                this.tooltip = {
                    show: false,
                    value: "",
                };

                const formData = {
                    FileID: fileID,
                    StageID: this.stageID,
                    Field: field,
                };

                const updatedBy = await axios.post('Operations/GetFieldHistory', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            return data.Data;
                        else
                        {
                            this.$snackBar.error({message: data.Message});
                            return "";
                        }
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.tooltip = {
                    show: true,
                    value: updatedBy,
                }
            },
            viewMDA(file){
                this.selectedFile = file;
                this.$refs.dlgMDA.show();
            },
            viewDocument(file, doc){
                this.selectedFile = file;
                this.document = this.selectedFile.Documents.find(element => element.DocumentTitle == doc);

                if(this.document == undefined) {
                    this.selectedFile.Documents.push({
                        DocumentID: 0,
                        DocumentTitle: doc,
                        DocumentDate: '',
                        DocumentNo: '',
                        FilePath: "",
                        name: "",
                        size: "",
                        error: "",
                    });

                    this.document = this.selectedFile.Documents.find(element => element.DocumentTitle == doc);
                }

                this.$refs.dlgDocument.show();
            },
            uploadDocument(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.document.error = "File size is exceeding the limit. Please select a smaller picture.";
                    return;
                }

                this.document.File = file;
                this.document.FilePath = URL.createObjectURL(file);
                this.document.name = file.name;
                this.document.size = size;
                this.document.error = "";
            },
            saveDocument() {
                this.$loadingDialog.show();

                let formData = new FormData();

                formData.append('FileID', this.selectedFile.FileID);
                formData.append('CustomerID', this.selectedFile.FKCustomerID);

                Object.keys(this.document).forEach(key => {
                    formData.append(`Documents[0].${key}`, this.document[key] == null ? '' : this.document[key]);
                });

                // let ind = 0;
                // this.selectedFile.Documents.forEach(item => {
                //     if(item.File != null)
                //     {
                //         Object.keys(item).forEach(key => {
                //             formData.append(`Documents[${ind}].${key}`, item[key] == null ? '' : item[key]);
                //         });
                //
                //         ind++;
                //     }
                // });

                axios.post('Operations/UpdateDocuments', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    }).catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });
            },
            viewSMS(file){
                this.message = {
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
            viewClearing(file){
                this.clearing = {
                    FileID: file.FileID,
                    EstimatedClearingDate: file.EstimatedClearingDate,
                };

                this.$refs.dlgEstimatedClearing.show();
            },
            async saveClearing(){
                this.$loadingDialog.show();
                await this.SaveEstimatedClearing(this.clearing.FileID, this.clearing.EstimatedClearingDate);
                await this.getData();
                this.$loadingDialog.hide();
            },
            async exportToExcel(){
                const formData = {
                    PageNo: 1,
                    NoOfRows: 99999999,
                    HasFilter: !!Object.keys(this.table.filters).length,
                    StageID: this.stageID,
                    Filters: this.table.filters,
                    FromDate: this.fromDate,
                    ToDate: this.toDate,
                };

                await this.excelExport('Operations/ExportToExcel', formData, `Operations_${this.stage.Stage}.xlsx`);
            }
        },
        async created(){
            this.rights.allowEdit = this.verifyRights(this.$route.name, 'E', this.stageID);

            const endOfYearDate = new Date().getFullYear() + '-12-01';

            this.fromDatePicker = this.toShortDateString(new Date());
            this.toDatePicker = new moment(endOfYearDate).endOf('months').format('YYYY-MM-DD') //this.toShortDateString(new Date());

            await this.getFields();
            await this.getData();

            this.$loadingDialog.show();
            this.terminals = await this.getTerminals().then(response => {
                const status = response.Status;
                return status ? response.Data : [];
            });
            this.$loadingDialog.hide();

        }
    }
</script>

<style scoped>

</style>