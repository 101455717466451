<template>
  <router-view :key="$route.path"></router-view>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>

/*  .small-text-field {*/
/*    font-size: 11pt;*/
/*  }*/

  .v-data-table-header {
    background-color: #c3c3c3;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }

  .v-data-table-header th:first-of-type {
    border-top-left-radius: 4px;
  }

  .v-data-table-header th:last-of-type {
    border-top-right-radius: 4px;
  }

  .small-text-field .v-input__slot {
    min-height: 30px !important;
  }

  .small-text-field.v-text-field--outlined .v-label {
    font-size: 11pt;
    top: 5px !important;
  }

  .small-text-field.v-text-field--solo .v-label {
    font-size: 11pt;
    line-height: 18px;
  }

  .small-text-field .v-label--active {
    transform: translateY(-12px) scale(.75) !important;
  }

  .small-text-field .v-input__prepend-inner {
    margin-top: 4px !important;
  }

  .small-text-field .v-input__append-inner {
    margin-top: 4px !important;
  }

  .small-text-field .v-input__append-outer {
    margin-top: 4px !important;
  }

  .small-text-field .v-select__selections {
    padding: 0 !important;
  }

  .card-scroll-wrapper {
    overflow-y: scroll;
  }

  .card-scroll {
    height: 80vh;
  }

  .v-data-table {
    white-space: nowrap;
  }
</style>