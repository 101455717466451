<template>
    <v-container fluid>
        <v-row>
            <v-col md="2">
                <v-img max-height="100px" src="@/assets/logo.png" contain></v-img>
            </v-col>
            <v-col md="10" align="right" v-if="settings.ClientBanner01.length > 0">
                <v-img height="150px" :src="settings.ClientBanner01"></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h2>Current Stage: <v-chip label color="green" text-color="white"><b>{{ this.file.Stage }}</b></v-chip></h2>
            </v-col>
            <!--            <v-col cols="12">-->
            <!--                <v-data-table-->
            <!--                        :headers="fileActivity.headers"-->
            <!--                        :items="fileActivity.data"-->
            <!--                        item-key="FileID"-->
            <!--                        @click:row="onRowClick"-->
            <!--                        :loading="fileActivity.loading"-->
            <!--                        loading-text="Loading Files, please wait..."-->
            <!--                        class="elevation-1"-->
            <!--                        style="cursor: pointer"-->
            <!--                        disable-pagination-->
            <!--                        disable-sort-->
            <!--                        dense-->
            <!--                        hide-default-footer>-->
            <!--                    <template v-slot:item.FKStatusID="{ item }">-->
            <!--                        <v-chip color="primary" label class="my-1"><b>{{ item.FKStatusID == 4 ? 'Delivered' : item.FKStatusID == 1 ? 'New' : 'In-Process' }}</b></v-chip>-->
            <!--                    </template>-->
            <!--                </v-data-table>-->
            <!--            </v-col>-->
        </v-row>
        <v-row>
            <v-col md="4" cols="12">
                <v-text-field
                        v-model="file.BLNo"
                        label="Shipment No"
                        class="small-text-field elevation-1"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.BLStatus"
                        label="BL Status"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.ReceivingFileDate"
                        label="File Received Date"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.FileNo"
                        label="File No"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
            </v-col>
            <v-col md="4" cols="12">
                <v-text-field
                        v-model="file.FCL"
                        label="No Of Containers"
                        class="small-text-field elevation-1"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.ETADate"
                        label="ETA"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.VesselStatus"
                        label="Vessel Status"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.ProductDescription"
                        label="Description"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
            </v-col>
            <v-col md="4" cols="12">
                <v-text-field
                        v-model="file.ShippingLine"
                        label="Shipping Line"
                        class="small-text-field elevation-1"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.VesselName"
                        label="Vessel Name"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.Origin"
                        label="Origin"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.ClearingStatus"
                        label="Clearing Status"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
                <v-text-field
                        v-model="file.Weight"
                        label="Total Weight"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        readonly
                        hide-details="auto">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col v-if="!file.IsAcceptAssessment">
                <v-textarea
                        v-model="file.ClientComments"
                        label="Comments"
                        rows="3"
                        outlined
                        background-color="white"
                        dense
                        autocomplete="disable"
                        class="red--text"
                        readonly
                        hide-details="auto" />
            </v-col>
            <v-col cols="12" md="4" md-4 v-if="file.IsShowDocuments">
                <v-card>
                    <v-card-title>Documents</v-card-title>
                    <v-list dense>
                        <v-list-item v-for="document in file.Documents"
                                     :key="document.title"
                                     @click="openDocument(document)"
                                    :disabled="document.FilePath.length == 0">
                            <v-list-item-content>
                                <v-list-item-title :class="document.FilePath.length == 0 ? 'red--text' : ''">{{ document.DocumentTitle }}</v-list-item-title>
                                <v-list-item-subtitle>{{ document.DocumentNo }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "My Shipments",
        data(){
            return {
                settings: {},
                file: {},
                fileActivity: {
                    headers: [
                        {text: 'S No', value: 'SNo'},
                        {text: 'Date', value: 'ActionDate'},
                        {text: 'Activity', value: 'Action'},
                    ],
                    data: [],
                    loading: true,
                }
            };
        },
        computed: {
          fileID(){
              return this.$route.params.id;
          },
        },
        methods: {
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            async getData(){
                await axios.post('ClientsPortal/Shipments', {FileID: this.fileID})
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.file = data.Data.Files[0];
                            this.fileActivity.data = data.Data.Activity;
                            this.file.ClearingStatus = this.file.FKStatusID == 1 ? 'New' : this.file.FKStatusID == 4 ? 'Cleared' : 'In-Process';
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });

                this.fileActivity.loading = false;
            },
            openDocument(document){
                window.open(document.FilePath, '_blank');
            },
            onRowClick(row){
                console.log(row);
            }
        },
        async created() {
            this.$loadingDialog.show();
            await this.getSettings();
            await this.getData();

            console.log(this.fileID);

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>