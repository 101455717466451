import axios from 'axios'

const tokenKey = 'user-token'
const uidKey = 'user-guid'
const userKey = 'user'
const modulesKey = 'modules'
const rightsKey = 'userRights'

const state = {
    token: localStorage.getItem(tokenKey) || '',
    guid: localStorage.getItem(uidKey) || '',
    status: '',
    user: localStorage.getItem(userKey) || '',
    modules: localStorage.getItem(modulesKey) || '',
    error: '',
    rights: localStorage.getItem(rightsKey) || '',
}

const actions = {
    login({commit}, user) {
        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST');
            const qs = require('querystringify');

            axios.post('Token', qs.stringify(user))
                .then(async resp => {
                    const token = resp.data.access_token;
                    localStorage.setItem(tokenKey, token);
                    commit('AUTH_SUCCESS', token);

                    commit('USER_REQUEST');
                    await axios.post('Auth/GetRights')
                        .then(response => {
                            const rights = response.data.Data;
                            localStorage.setItem(rightsKey, JSON.stringify(rights));
                            commit('RIGHTS_SUCCESS', JSON.stringify(rights));
                        })
                        .catch(error => {
                            localStorage.removeItem(rightsKey);
                            reject(error);
                        });

                    await axios.post('Auth/GetUser')
                        .then(response => {
                            const user = response.data.Data;
                            const guid = user.MacAddress;
                            localStorage.setItem(uidKey, guid);
                            localStorage.setItem(userKey, JSON.stringify(user));
                            commit('USER_SUCCESS', JSON.stringify(user), guid);
                        })
                        .catch(error => {
                            commit('USER_ERROR', error);
                            localStorage.removeItem(uidKey);
                            reject(error);
                        });

                    await axios.post('Modules/GetModules')
                        .then(response => {
                            const modules = response.data.Data;

                            console.log(modules);

                            localStorage.setItem(modulesKey, JSON.stringify(modules));
                            commit('MODULE_SUCCESS', JSON.stringify(modules));
                        })
                        .catch(error => {
                            localStorage.removeItem(modulesKey);
                            reject(error);
                        });

                    resolve(true);
                })
                .catch(error => {
                    commit('AUTH_ERROR', error);
                    localStorage.removeItem(tokenKey);
                    reject(error);
                });
        });
    },
    logout({commit}) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            commit('AUTH_LOGOUT');
            localStorage.removeItem(tokenKey);
            localStorage.removeItem(userKey);
            localStorage.removeItem(rightsKey);
            resolve(true);
            reject(true);
        });
    }
}

const mutations = {
    AUTH_REQUEST(state) {
        state.status = 'auth loading';
    },
    AUTH_SUCCESS(state, token) {
        state.status = 'auth success';
        state.token = token;
    },
    AUTH_ERROR(state, error) {
        state.status = 'auth error';
        state.error = error.response;
    },
    USER_REQUEST(state){
        state.status = 'fetching user';
    },
    USER_SUCCESS(state, user, uid){
        state.status = 'user success';
        state.user = user;
        state.guid = uid;
    },
    RIGHTS_SUCCESS(state, rights){
        state.status = 'rights success';
        state.rights = rights;
    },
    MODULE_SUCCESS(state, modules){
        state.status = 'modules success';
        state.modules = modules;
    },
    USER_ERROR(state, error){
        state.status = 'user error';
        state.error = error.response;
    },
    AUTH_LOGOUT(state){
        state.token = '';
        state.status = 'logged out';
    }
}

const getters = {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    userID: state => state.user.UserID,
    user: state => state.user.startsWith('{') ? JSON.parse(state.user) : '',
    token: state => state.token,
    modules: state => state.modules.startsWith('[') ? JSON.parse(state.modules) : '',
    rights: state => state.rights.startsWith('[') ? JSON.parse(state.rights) : '',
}

export default  {
    state,
    getters,
    mutations,
    actions
}

