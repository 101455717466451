<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">{{ terminal.Terminal }}</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="table.selected"
                        :headers="table.headers"
                        :items="table.data"
                        :loading="table.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="table.pagination.page"
                        :items-per-page="table.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr>
                            <td v-for="(header, i) in table.headers" :key="header.value">
                                <template v-if="i === 0">
                                    <v-icon>fa-filter</v-icon>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            v-model="table.filters[header.value]"
                                            class="small-text-field my-1"
                                            background-color="white"
                                            outlined
                                            dense
                                            hide-details
                                            autcomplete="disable"
                                            @change="customFilter($event, header.value)"
                                            v-if="header.applyFilter && !header.value.includes('Date')">
                                    </v-text-field>
                                    <v-menu
                                            offset-y
                                            transition="scale-transition"
                                            :close-on-content-click="true"
                                            v-if="header.applyFilter && header.value.includes('Date')">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="table.filters[header.value]"
                                                    class="small-text-field my-1"
                                                    background-color="white"
                                                    outlined
                                                    dense
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="table.filters[header.value]"
                                                @change="customFilter($event, header.value)">
                                            <v-spacer></v-spacer>
                                            <v-btn color="grey" class="white--text" small
                                                   @click="customFilter('', header.value)">Clear</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </td>
                        </tr>
                        <template v-if="table.data.length > 0">
                            <tr v-for="item in items" :key="item.FileID">
                                <td v-for="header in table.headers" :key="header.value" class="text-md-body-2 py-1">
                                    <template v-if="header.isDisplay">
                                        <template v-if="header.value === 'VesselStatus'">
                                            <v-chip label :color="item.VesselStatus === 'ARRIVED' ? 'orange' : 'green'" text-color="white">{{ item.VesselStatus }}</v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'Priority'">
                                            <v-chip
                                                    label
                                                    :color="item.Priority === 'Normal' ? 'green' : item.Priority === 'Medium' ? 'orange' : item.Priority === 'Urgent' ? 'purple' : 'red'"
                                                    text-color="white">
                                                {{ item.Priority.toUpperCase() }}
                                            </v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'RiskLevel'">
                                            <v-chip label :color="item.RiskLevelColor.toLowerCase()" text-color="white">{{ item.RiskLevel.toUpperCase() }}</v-chip>
                                        </template>
                                        <template v-else-if="header.value === 'UnBookedContainers'">
                                            {{ item.TotalContainers - item.BookedContainers - item.LoadedContainers }}
                                        </template>
                                        <template v-else>
                                            {{ item[header.value] }}
                                        </template>
                                    </template>
                                    <template v-else>
                                        <template v-if="header.fieldType === 'Switch'">
                                            <v-switch
                                                    v-model="item[header.value]"
                                                    hide-details class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                            </v-switch>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Text'">
                                            <v-text-field
                                                    v-model="item[header.value]"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @keydown.enter="updateData(item.FileID, header.fieldType, header.seq, header.value, item[header.value])">
                                            </v-text-field>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Date'">
                                            <v-menu
                                                    offset-y
                                                    transition="scale-transition"
                                                    :close-on-content-click="true">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                            :value="item[header.value].length > 0 ? toShortDateString(new Date(item[header.value])) : ''"
                                                            dense
                                                            solo
                                                            hide-details
                                                            class="mt-1 mb-2"
                                                            v-bind="attrs"
                                                            v-on="on">
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                        :value="item[header.value].length > 0 ? toShortDateString(new Date(item[header.value])) : ''"
                                                        :readonly="!rights.allowEdit"
                                                        @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                                </v-date-picker>
                                            </v-menu>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Document'">
                                            <v-btn v-if="header.options == 'Booking'"
                                                   color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="booking(item)">
                                                {{ header.options }}
                                            </v-btn>
                                            <v-btn v-else-if="header.options == 'Loading'"
                                                   color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="viewBookings(item)">
                                                {{ header.options }}
                                            </v-btn>
                                            <v-btn v-else-if="header.options == 'SMS'"
                                                   color="primary"
                                                   small
                                                   class="py-4"
                                                   :disabled="!rights.allowEdit"
                                                   @click="viewSMS(item)">
                                                {{ header.options }}
                                            </v-btn>
                                        </template>
                                        <template v-else-if="header.fieldType === 'Dropdown'">
                                            <v-select
                                                    v-if="header.value === 'Terminal'"
                                                    v-model="item.FKTerminalID"
                                                    item-text="Terminal"
                                                    item-value="TerminalID"
                                                    :items="terminals"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                            </v-select>
                                            <v-select
                                                    v-else
                                                    v-model="item[header.value]"
                                                    :items="header.options.split(',')"
                                                    dense
                                                    solo
                                                    hide-details
                                                    class="mt-1 mb-2"
                                                    :readonly="!rights.allowEdit"
                                                    @change="updateData(item.FileID, header.fieldType, header.seq, header.value, $event)">
                                            </v-select>
                                        </template>
                                    </template>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td :colspan="table.headers.length" class="grey--text text-center">No data available</td>
                        </tr>
                        </tbody>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="table.pagination.page" :length="table.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgBooking"
                title="Booking Truck"
                confirm-button-text="Book"
                cancel-button-text="Cancel"
                max-width="900"
                :on-confirm="saveBooking">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="6" md="4">
                        <v-text-field
                                v-model="truckBooking.booking.BLNo"
                                label="BL No"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="6" md="4">
                        <v-text-field
                                v-model="truckBooking.booking.AvailableContainers"
                                label="Containers Available"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-divider class="my-2"></v-divider>
                <v-row dense>
                    <v-col cols="12" md="4">
                        <v-select
                                v-model="truckBooking.booking.BookingType"
                                label="Booking Type"
                                :items="['Partial', 'Full']"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details
                                @change="selectBookingType($event)">
                        </v-select>
                    </v-col>
                    <v-col md="8">
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                                offset-y
                                transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="truckBooking.booking.BookingDate"
                                        label="Booking Date"
                                        outlined
                                        dense
                                        background-color="white"
                                        class="elevation-1"
                                        readonly
                                        hide-details
                                        autocomplete="disable"
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="truckBooking.booking.BookingDate">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="truckBooking.booking.LoadingDate"
                                        label="Loading Date"
                                        outlined
                                        dense
                                        background-color="white"
                                        class="elevation-1"
                                        readonly
                                        hide-details
                                        autocomplete="disable"
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="truckBooking.booking.LoadingDate">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="4">
                        <v-menu
                                offset-y
                                transition="scale-transition">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="truckBooking.booking.LoadingTime"
                                        label="Loading Time"
                                        outlined
                                        dense
                                        background-color="white"
                                        class="elevation-1"
                                        readonly
                                        hide-details
                                        autocomplete="disable"
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-time-picker
                                    v-model="truckBooking.booking.LoadingTime"
                                    ampm-in-title
                                    format="ampm">
                            </v-time-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                                v-model="truckBooking.booking.FKTransporterID"
                                label="Transporter"
                                :items="truckBooking.transporters"
                                item-text="Transporter"
                                item-value="TransporterID"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details
                                @change="getDrivers($event)">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                                label="Driver"
                                :value="truckBooking.booking.FKDriverID"
                                :items="truckBooking.drivers"
                                item-text="Driver"
                                item-value="DriverID"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details
                                return-object
                                @change="getDriverDetails($event)">
                        </v-autocomplete>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.TruckLicense"
                                label="Truck License"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.DriverLicense"
                                label="Driver License"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.DriverPhone"
                                label="Driver Phone"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="truckBooking.booking.AxleCount"
                                label="Axle Type"
                                :items="truckBooking.axles"
                                item-text="TextLine"
                                item-value="TextLine"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.TareWeight"
                                label="Truck Weight"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.NoOfContainers"
                                label="No Of Containers"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="truckBooking.booking.Remarks"
                                label="Comments"
                                outlined
                                dense
                                background-color="white"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-autocomplete
                                v-model="truckBooking.booking.DeliveryLocation"
                                label="Delivery Location"
                                :items="truckBooking.customer.CustomersLocations"
                                item-text="Address"
                                item-value="Address"
                                outlined
                                dense
                                background-color="white"
                                class="elevation-1"
                                hide-details>
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgLoading"
                title="Loading Truck"
                confirm-button-text=""
                cancel-button-text="Close"
                max-width="1300">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table
                                :headers="loading.header"
                                :items="loading.data"
                                class="elevation-1"
                                dense
                                disable-pagination
                                hide-default-footer>
                            <template v-slot:item.LoadedContainers="{ item }">
                                <template v-if="item.IsLoaded">{{ item.LoadedContainers }}</template>
                                <v-text-field
                                        v-model="item.LoadedContainers"
                                        dense
                                        solo
                                        hide-details
                                        class="mt-1 mb-2"
                                        v-else>
                                </v-text-field>
                            </template>
                            <template v-slot:item.Load="{ item }">
                                <template v-if="item.IsLoaded">
                                    <b class="red--text">LOADED</b>
                                </template>
                                <v-btn
                                        color="primary"
                                        class="text--white"
                                        @click="saveLoading(item)"
                                        v-else-if="isLoadingEnabled(item)">
                                    Load
                                </v-btn>
                                <template v-else-if="isFutureBooking(item)">
                                    BOOKING DATE NOT ARRIVED
                                </template>
                                <template v-else>
                                    INVALID - RE BOOK
                                </template>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-autocomplete label="Message" v-model="message.Message" outlined dense background-color="white" :items="messages" item-text="TextLine" item-value="TextLine" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        title: "Terminal Operations",
        data() {
            return {
                terminal: {},
                table: {
                    headers: [
                        { text: 'S. No', value: 'SNo', applyFilter: false, isDisplay: true },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: false,
                },
                selectedFile: {},
                document: {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
                documents: [],
                terminals: [],
                truckBooking: {
                    fileID: 0,
                    booking: {
                        BLNo: '',
                        AvailableContainers: 0,
                        BookingID: 0,
                        FKFileID: 0,
                        BookingType: '',
                        BookingDate: '',
                        LoadingDate: '',
                        LoadingTime: '',
                        NoOfContainers: 0,
                        FKTransporterID: 0,
                        FKDriverID: 0,
                        DriverPhone: '',
                        DriverLicense: '',
                        TruckLicense: '',
                        AxleCount: '',
                        TareWeight: 0,
                        DeliveryLocation: '',
                        Remarks: '',
                    },
                    customer: {},
                    axles: [],
                    transporters: [],
                    drivers: [],
                },
                loading: {
                    header: [
                        {text: 'S.No', value: 'SNo'},
                        {text: 'BookingID', value: 'BookingID'},
                        {text: 'Loading Date', value: 'LoadingDate'},
                        {text: 'Booking Type', value: 'BookingType'},
                        {text: 'Driver', value: 'Driver'},
                        {text: 'Driver Phone', value: 'DriverPhone'},
                        {text: 'Booked Containers', value: 'NoOfContainers'},
                        {text: 'Loaded Containers', value: 'LoadedContainers', width: '150'},
                        {text: 'Load', value: 'Load'},
                    ],
                    data: [],
                },
                messages: [],
                message: {
                  FileID: 0,
                  Message: '',
                },
                rights: {
                    allowEdit: false,
                },
            };
        },
        computed: {
            terminalID() {
                return this.$route.params.id;
            }
        },
        methods: {
            customFilter(value, field){
                if( value )
                    this.table.filters[field] = value;
                else
                    delete this.table.filters[field];

                this.getData();
            },
            async getFields(){
                this.$loadingDialog.show();

                await axios.post('TerminalOperations/GetFields', {TerminalID: this.terminalID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status) {
                            this.terminal = data.Data;

                            this.terminal.DisplayFields.forEach(element => {
                                this.table.headers.push(
                                    {
                                        text: element.FieldDisplayName,
                                        value: element.FieldName,
                                        applyFilter: true,
                                        fieldType: element.FieldType,
                                        isDisplay: true,
                                        seq: element.Seq,
                                    }
                                );
                            });

                            this.table.headers.push(
                                {
                                    text: 'Duration',
                                    value: 'Duration',
                                    applyFilter: true,
                                    fieldType: '',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );

                            this.table.headers.push(
                                {
                                    text: 'Booked',
                                    value: 'BookedContainers',
                                    applyFilter: true,
                                    fieldType: '',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );

                            this.table.headers.push(
                                {
                                    text: 'Loaded',
                                    value: 'LoadedContainers',
                                    applyFilter: true,
                                    fieldType: '',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );

                            this.table.headers.push(
                                {
                                    text: 'UnBooked',
                                    value: 'UnBookedContainers',
                                    applyFilter: true,
                                    fieldType: '',
                                    isDisplay: true,
                                    seq: 0,
                                }
                            );

                            this.terminal.UpdateFields.forEach(element => {
                                this.table.headers.push(
                                    {
                                        text: element.FieldDisplayName,
                                        value: element.FieldName,
                                        applyFilter: false,
                                        fieldType: element.FieldType,
                                        options: element.Options,
                                        isDisplay: false,
                                        seq: element.Seq,
                                        width: element.FieldType === 'Text' || element.FieldType === 'Dropdown' ? '175px' : '',
                                    }
                                );
                            });
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            getData(){
                return new Promise((resolve, reject) => {
                    this.$loadingDialog.show();

                    const formData = {
                        PageNo: this.table.pagination.page,
                        NoOfRows: this.table.pagination.rowsPerPage,
                        HasFilter: !!Object.keys(this.table.filters).length,
                        TerminalID: this.terminalID,
                        Filters: this.table.filters,
                    };

                    axios.post('TerminalOperations/GetData', formData)
                        .then(response => {
                            const data = response.data;

                            if(data.Status) {
                                this.table.totals = data.Data.Totals;
                                this.table.pagination.totalPages = data.Data.TotalPages;
                                this.table.data = data.Data.Items;

                                resolve(data);
                            }
                            else {
                                this.$snackBar.error({message: data.Message});
                                reject(data);
                            }

                            this.$loadingDialog.hide();
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            this.$loadingDialog.hide();
                            reject(error);
                        });
                });
            },
            updateData(fileID, fieldType, seq, field, value) {
                this.$loadingDialog.show();

                let val = value;

                if(fieldType === 'Switch')
                    val = val == false ? '' : true;

                const formData = {
                    FileID: fileID,
                    TerminalID: this.terminalID,
                    Field: field,
                    Value: val,
                    Seq: seq,
                };

                axios.post('TerminalOperations/Update', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });
            },
            async getDrivers(transporterID){
                this.$loadingDialog.show();
                await this.getTransporterDrivers(transporterID)
                    .then(response => {
                        if(response.Status)
                            this.truckBooking.drivers = response.Data;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
                this.$loadingDialog.hide();
            },
            getDriverDetails(driver){
                this.truckBooking.booking.FKDriverID = driver.DriverID;
                this.truckBooking.booking.DriverPhone = driver.Mobile;
                this.truckBooking.booking.DriverLicense = driver.License;
            },
            async booking(file)
            {
                this.truckBooking.fileID = file.FileID;

                if(file.TotalContainers - file.BookedContainers - file.LoadedContainers > 0)
                {
                    this.$loadingDialog.show();

                    await this.getCustomer(file.FKCustomerID)
                        .then(response => {
                            const status = response.Status;

                            if(status)
                                this.truckBooking.customer = response.Data.Items[0];
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error({message: 'An unexpected error has occurred'});
                        });

                    this.$loadingDialog.hide();

                    this.truckBooking.booking = {
                        BLNo: file.BLNo,
                        AvailableContainers: file.TotalContainers - file.BookedContainers - file.LoadedContainers,
                        BookingID: 0,
                        FKFileID: 0,
                        BookingType: '',
                        BookingDate: this.toShortDateString(new Date()),
                        LoadingDate: '',
                        LoadingTime: '',
                        NoOfContainers: 0,
                        FKTransporterID: 0,
                        FKDriverID: 0,
                        DriverPhone: '',
                        DriverLicense: '',
                        TruckLicense: '',
                        AxleCount: '',
                        TareWeight: 0,
                        DeliveryLocation: '',
                        Remarks: '',
                    };

                    this.$refs.dlgBooking.show();
                }
                else {
                    this.$snackBar.error({message: 'All containers are booked or loaded already'});
                }

            },
            selectBookingType(type){
                if(type == 'Full')
                    this.truckBooking.booking.NoOfContainers = this.truckBooking.booking.AvailableContainers;
                else
                    this.truckBooking.booking.NoOfContainers = 1;
            },
            async saveBooking(){
                if(this.truckBooking.booking.FKDriverID == 0)
                {
                    this.$snackBar.error({message: 'Unable to create booking, driver is not selected'});
                    return;
                }

                this.$loadingDialog.show();

                this.truckBooking.booking.LoadingDate = this.truckBooking.booking.LoadingDate + ' ' + this.truckBooking.booking.LoadingTime;

                const formData = {
                    FileID: this.truckBooking.fileID,
                    Booking: this.truckBooking.booking
                };

                await axios.post('TerminalOperations/Booking', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            viewBookings(file)
            {
                this.loading.data = file.Bookings;
                this.loading.data.forEach(element => {
                    element.LoadingDate = moment(element.LoadingDate).format('D-MMM-yyyy hh:mm a');
                });
                this.$refs.dlgLoading.show();
            },
            isLoadingEnabled(booking){
                return !booking.IsLoaded && moment(booking.LoadingDate).format('D-MMM-yyyy') == moment().format('D-MMM-yyyy');
            },
            isFutureBooking(booking){
                return moment(booking.LoadingDate).isAfter(moment().format('D-MMM-yyyy'));
            },
            async saveLoading(booking) {
                this.$refs.dlgLoading.hide();
                this.$loadingDialog.show();

                const formData = {
                    BookingID: booking.BookingID,
                    LoadedContainers: booking.LoadedContainers,
                };

                await axios.post('TerminalOperations/LoadBooking', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            viewSMS(file){
                this.message = {
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            }
        },
        async created(){
            this.rights.allowEdit = this.verifyRights(this.$route.name, 'E', this.terminalID);

            await this.getFields();
            await this.getData();

            this.$loadingDialog.show();

            this.messages = await this.getGeneral(17, 0)
                .then(response => {
                    if(response.Status)
                        return response.Data;
                    else
                        return [];
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                    return [];
                });

            this.truckBooking.axles = await this.getGeneral(13, 0)
                .then(response => {
                    if(response.Status)
                        return response.Data;
                    else
                        return [];
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                    return [];
                });

            this.truckBooking.transporters = await this.getTransporters()
                .then(response => {
                    if(response.Status)
                        return response.Data;
                    else
                        return [];
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                    return [];
                });

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>