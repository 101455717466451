<template>
    <v-container fluid>
        <v-row dense>
            <v-col xs="12">
                <h2 class="heading">Refunds</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col xs="12" align="end">
                <v-btn
                        @click="newRefund"
                        class="green py-4"
                        dark
                        small >
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New Refund</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 class="mr-2">Total Refunds: <span class="indigo--text text--darken-2">{{ files.totals.Total }}</span></h4>
                        <h4 class="mr-2">Received Refunds: <span class="indigo--text text--darken-2">{{ files.totals.Received }}</span></h4>
                        <h4 class="mr-2">Pending Refunds: <span class="indigo--text text--darken-2">{{ files.totals.Balance }}</span></h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Refunds, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td v-for="(header, i) in files.headers" :key="header.value">
                                <template v-if="i == 0"><v-icon>fa-filter</v-icon></template>
                                <v-text-field
                                        v-model="files.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.ShippingLineInvoice="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.ShippingLineInvoice"
                                target="_blank"
                                :disabled="!item.ShippingLineInvoice">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.IsReceived="{item}">
                        <v-chip v-if="item.IsReceived" color="green" text-color="white" label small>Received</v-chip>
                        <v-btn v-else color="orange" class="white--text" small @click="receiveRefund(item.RefundID)" :disabled="!rights.allowReceive">Not Received</v-btn>
                    </template>
                    <template v-slot:item.IsSubmitted="{item}">
                        <v-chip v-if="item.IsSubmitted" color="green" text-color="white" label small>Submitted</v-chip>
                        <v-btn v-else color="orange" class="white--text" small @click="submitRefund(item.RefundID)" :disabled="!rights.allowSubmit">Not Submitted</v-btn>
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="editRefund(item)" dense v-if="rights.allowEdit">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="deleteRefund(item.RefundID)" dense v-if="rights.allowDelete">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-trash</v-icon></v-list-item-icon>
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgRefund"
                title="New Refund"
                max-width="650"
                :on-confirm="saveRefund">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="refund.FileNo"
                                label="File No"
                                outlined
                                background-color="white"
                                dense
                                @keydown.enter="getFileDetails"
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.CompanyName"
                                label="Company Name"
                                outlined
                                background-color="white"
                                dense
                                readonly
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="refund.BLNo"
                                label="BL No"
                                outlined
                                background-color="white"
                                dense
                                readonly
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.ShippingLine"
                                label="Shipping Line"
                                outlined
                                background-color="white"
                                dense
                                readonly
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.ContainerNo"
                                label="Container No"
                                outlined
                                background-color="white"
                                dense
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="refund.ProductDescription"
                                label="Product Description"
                                outlined
                                background-color="white"
                                dense
                                readonly
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="refund.Remarks"
                                label="Remarks"
                                outlined
                                background-color="white"
                                dense
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="refund.FKCurrencyID"
                                label="Currency"
                                :items="currencies"
                                item-text="ShortName"
                                item-value="CurrencyID"
                                outlined
                                background-color="white"
                                dense
                                hide-details>
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.Amount"
                                label="Refund Amount"
                                outlined
                                background-color="white"
                                dense
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-checkbox
                                v-model="refund.IsSubmitted"
                                label="Submitted"
                                outlined
                                class="mt-1"
                                hide-details>
                        </v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="refund.SubmissionDate"
                                        label="Submission Date"
                                        outlined
                                        background-color="white"
                                        dense
                                        hide-details
                                        readonly
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="refund.SubmissionDate">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.ReceivedAmount"
                                label="Received Amount"
                                outlined
                                background-color="white"
                                dense
                                hide-details>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="refund.BalanceAmount"
                                label="Balance"
                                outlined
                                background-color="white"
                                dense
                                readonly
                                hide-details>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from 'moment';

    export default {
        title: "Refunds",
        data() {
            return {
                files: {
                    headers: [
                        { text: 'S.No', value: 'SNo', applyFilter: false },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'Container No', value: 'ContainerNo', applyFilter: true },
                        { text: 'Shipping', value: 'ShippingLine', applyFilter: true },
                        { text: 'Currency', value: 'ShortName', applyFilter: true },
                        { text: 'Refund Amount', value: 'Amount', applyFilter: true },
                        { text: 'Shipping Line Invoice', value: 'ShippingLineInvoice', applyFilter: false },
                        { text: 'Submitted', value: 'IsSubmitted', applyFilter: true },
                        { text: 'Submission Date', value: 'SubmissionDate', applyFilter: true },
                        { text: 'Received', value: 'IsReceived', applyFilter: true },
                        { text: 'Received Date', value: 'ReceivedDate', applyFilter: true },
                        { text: 'Received Amount', value: 'ReceivedAmount', applyFilter: true },
                        { text: 'Balance', value: 'BalanceAmount', applyFilter: true },
                        { text: 'Action', value: 'Actions', applyFilter: false },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: true,
                },
                currencies: [],
                refund: {},
                rights: {
                    allowEdit: this.verifyRights(this.$route.name, 'E', 0),
                    allowDelete: this.verifyRights(this.$route.name, 'D', 0),
                    allowSubmit: this.verifyRights(this.$route.name, 'SM', 0),
                    allowReceive: this.verifyRights(this.$route.name, 'RE', 0),
                }
            };
        },
        methods: {
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            async getData(){
                this.files.data = [];
                this.files.loading = true;

                const formData = {
                    RefundID: 0,
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    Filters: this.files.filters,
                };

                axios.post('Refunds/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.files.loading = false;
            },
            newRefund(){
                this.refund = {
                    RefundID: 0,
                    FKFileID: 0,
                    FileNo: 0,
                    CompanyName: '',
                    BLNo: '',
                    ShippingLine: '',
                    ProductDescription: '',
                    ContainerNo: '',
                    FKCurrencyID: 0,
                    Amount: 0,
                    ReceivedAmount: 0,
                    BalanceAmount: 0,
                    IsSubmitted: false,
                    SubmissionDate: '',
                    Remarks: '',
                };

                this.$refs.dlgRefund.show();
            },
            editRefund(item){
                this.refund = item;

                this.refund.SubmissionDate = new moment(item.SubmissionDate).format('Y-M-D');

                this.$refs.dlgRefund.show();
            },
            async getFileDetails(){
                this.$loadingDialog.show();

                const filters = {
                    FileNo: this.refund.FileNo
                }

                const file = await this.getFile(0, filters)
                    .then(response => {
                        const data = response.Data;
                        const status = response.Status;

                        if(status)
                            return data.Items[0];
                        else
                        {
                            this.$snackBar.error({message: response.Message});
                            return [];
                        }
                })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.refund.FKFileID = file.FileID;
                this.refund.FileNo = file.FileNo;
                this.refund.BLNo = file.BLNo;
                this.refund.CompanyName = file.CompanyName;
                this.refund.ShippingLine = file.ShippingLine;
                this.refund.ProductDescription = file.ProductDescription;

                this.$loadingDialog.hide();
            },
            async saveRefund(){
                this.$loadingDialog.show();

                await axios.post('Refunds/AddEdit', this.refund)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            receiveRefund(refundID){
                this.$confirmDialog.show({
                    title: 'Receive Refund',
                    message: 'Are you sure you want to receive full amount? Please confirm.',
                    async onConfirm() {
                        this.$loadingDialog.show();

                        await axios.post('Refunds/Receive', {RefundID: refundID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            });

                        this.$loadingDialog.hide();
                    }
                })
            },
            deleteRefund(refundID){
                this.$confirmDialog.show({
                    title: 'Delete Refund',
                    message: 'Are you sure you want to delete this refund? This action cannot be undone.',
                    async onConfirm() {
                        this.$loadingDialog.show();

                        await axios.post('Refunds/Delete', {RefundID: refundID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            });

                        this.$loadingDialog.hide();
                    }
                })
            },
            async submitRefund(refundID){
                this.$loadingDialog.show();

                await axios.post('Refunds/Submit', {RefundID: refundID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
        },
        async created(){
            await this.getData();

            this.$loadingDialog.show();

            this.currencies = await this.getCurrencies()
                .then(response => {
                    return response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error as occurred'});
                });

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>