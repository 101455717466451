<template>
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth" >

      <v-card color="background">
        <v-card-title class="headline text--primary">{{ title }}</v-card-title>
        <v-card-text>
          <slot name="content"></slot>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="hide">{{ this.cancelButtonText }}</v-btn>
          <v-btn color="primary" @click="confirm" v-if="confirmButtonText.length > 0">{{ confirmButtonText }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>
    export default {
      name: "customDialog",
      props: {
        title: {
          default: 'Dialog'
        },
        confirmButtonText: {
          default: 'Save'
        },
        cancelButtonText: {
          default: 'Cancel'
        },
        persistent: {
          default: false
        },
        maxWidth: {
          default: 450
        },
        onConfirm: {}
      },
      data() {
        return {
          dialog: false,
        };
      },
      methods: {
        hide() {
          this.dialog = false;
        },
        confirm() {
          // we must check if this.onConfirm is function
          if(typeof this.onConfirm === 'function')
            this.onConfirm();

          this.hide();
        },
        show() {
          // making modal visible
          this.dialog = true;
        },
      },
    }
</script>

<style scoped>

</style>
