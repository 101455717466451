import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors'
import 'font-awesome/css/font-awesome.min.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.indigo.darken2,
                secondary: colors.indigo.darken4,
                accent: colors.green,
                background: colors.grey.lighten3,
            },
            dark: {
                primary: colors.indigo,
                secondary: colors.indigo.darken4,
                accent: colors.green,
                background: colors.grey.darken3,
            }
        }
    },
    icons: {
        iconfont: 'fa4',
    },
});
