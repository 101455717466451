<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">ETA Status</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="2" align="end">
                <v-menu
                        offset-y
                        transition="scale-transition"
                        :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="fromDate"
                                label="From Date"
                                class="small-text-field mb-2"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="files.fromDatePicker"
                            :min="this.toShortDateString(new Date())"
                            @change="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="12" md="2" align="end">
                <v-menu
                        offset-y
                        transition="scale-transition"
                        :close-on-content-click="true">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                                v-model="toDate"
                                label="To Date"
                                class="small-text-field mb-2"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details
                                v-bind="attrs"
                                v-on="on">
                        </v-text-field>
                    </template>
                    <v-date-picker
                            v-model="files.toDatePicker"
                            @change="getData">
                    </v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.totals)" :key="key" class="mr-2">
                            {{ key }}: <span class="indigo--text text--darken-2">{{ files.totals[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" align="end">
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text"
                        @click="exportToExcel">
                    Export To Excel
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text" >
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="files.selected"
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        :item-class="etaRowClass"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td v-for="(header, i) in files.headers" :key="header.value">
                                <template v-if="i == 0">
                                    <v-icon>fa-filter</v-icon>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            v-model="files.filters[header.value]"
                                            class="small-text-field my-1"
                                            background-color="white"
                                            outlined
                                            dense
                                            hide-details
                                            autcomplete="disable"
                                            @change="customFilter($event, header.value)"
                                            v-if="header.applyFilter && !header.value.includes('Date')">
                                    </v-text-field>
                                    <v-menu
                                            offset-y
                                            transition="scale-transition"
                                            :close-on-content-click="true"
                                            v-if="header.applyFilter && header.value.includes('Date')">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="files.filters[header.value]"
                                                    class="small-text-field my-1"
                                                    background-color="white"
                                                    outlined
                                                    dense
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="files.filters[header.value]"
                                                @change="customFilter($event, header.value)">
                                            <v-spacer></v-spacer>
                                            <v-btn color="grey" class="white--text" small
                                                   @click="customFilter('', header.value)">Clear</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.ShippingLine="{item}">
<!--                        <v-btn text block :to="item.ShippingLineWebsite" target="_blank">{{ item.ShippingLine }}</v-btn>-->
                        <a :href="item.ShippingLineWebsite" target="_blank">{{ item.ShippingLine }}</a>
                    </template>
                    <template v-slot:item.DaysAfterArrival="{ item }">
                        <span v-if="item.DaysAfterArrival < 0">{{ item.DaysAfterArrival * -1 }} Day(s)</span>
                        <span v-else>{{ item.DaysAfterArrival }} Day(s) After Arrival</span>
                    </template>
                    <template v-slot:item.VesselStatus="{ item }">
                        <v-chip label :color="item.VesselStatus == 'ARRIVED' ? 'orange' : 'green'" text-color="white" class="my-1">{{ item.VesselStatus }}</v-chip>
                    </template>
                    <template v-slot:item.IsETAApproved="{ item }">
                        <v-chip label color="green" text-color="white" v-if="item.IsETAApproved">Approved</v-chip>
                        <v-btn color="orange" class="white--text my-1" small v-else-if="rights.allowApprove" @click="approveETA(item)">Waiting Approval</v-btn>
                        <v-chip label color="orange" text-color="white" v-else>Pending</v-chip>
                    </template>
                    <template v-slot:item.Actions="props">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="viewHistory(props.item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-search</v-icon></v-list-item-icon>
                                    <v-list-item-title>View History</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="editETA(props.item)" dense v-if="rights.allowEdit">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="viewFreeDays(props.item)" dense v-if="rights.allowFreeDays">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit Free Days</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgETA"
                title="ETA"
                max-width="325"
                :on-confirm="saveETA">
            <template v-slot:content>
                <v-row dense>
                    <v-col>

                    <v-text-field
                            :value="selectedFile.BLNo"
                            label="BL No"
                            class="elevation-1"
                            background-color="white"
                            outlined
                            dense
                            readonly
                            hide-details />
                    <v-text-field
                            :value="selectedFile.FileNo"
                            label="File No"
                            class="elevation-1 mt-2"
                            background-color="white"
                            outlined
                            dense
                            readonly
                            hide-details />
                    <v-text-field
                            :value="selectedFile.FCL"
                            label="FCL"
                            class="elevation-1 mt-2"
                            background-color="white"
                            outlined
                            dense
                            readonly
                            hide-details />
                    <v-text-field
                            :value="selectedFile.CompanyName"
                            label="Company"
                            class="elevation-1 mt-2"
                            background-color="white"
                            outlined
                            dense
                            readonly
                            hide-details />
                    <v-text-field
                            :value="(selectedFile.DaysAfterArrival < 0 ? (selectedFile.DaysAfterArrival * -1) : selectedFile.DaysAfterArrival * -1) + ' Day(s)'"
                            label="Days To Arrive"
                            class="elevation-1 mt-2"
                            background-color="white"
                            outlined
                            dense
                            readonly
                            hide-details />
                    </v-col>
                </v-row>
                <v-row dense class="mt-2">
                    <v-col cols="12">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        :value="formatDate(eta.ETADate)"
                                        label="ETA Date"
                                        class="elevation-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="eta.ETADate">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
<!--                    <v-col cols="12">-->
<!--                        <v-menu-->
<!--                                offset-y-->
<!--                                transition="scale-transition"-->
<!--                                :close-on-content-click="true">-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-text-field-->
<!--                                        :value="formatDate(eta.ETBDate)"-->
<!--                                        label="ETB Date"-->
<!--                                        class="elevation-1"-->
<!--                                        background-color="white"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        readonly-->
<!--                                        hide-details-->
<!--                                        v-bind="attrs"-->
<!--                                        v-on="on">-->
<!--                                </v-text-field>-->
<!--                            </template>-->
<!--                            <v-date-picker-->
<!--                                    v-model="eta.ETBDate">-->
<!--                            </v-date-picker>-->
<!--                        </v-menu>-->
<!--                    </v-col>-->
                    <v-col cols="12">
                        <v-text-field
                                v-model="eta.Comments"
                                label="Comments"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col>
                        <v-btn color="primary" @click="viewSMS" class="py-4" small>SMS</v-btn>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgETAHistory"
                title="ETA History"
                confirm-button-text=""
                cancel-button-text="Close"
                :max-width="900">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.FileNo"
                                label="File No"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.BLNo"
                                label="BL No"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.BLStatus"
                                label="BL Status"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table
                                v-model="etaHistory.selected"
                                :headers="etaHistory.header"
                                :items="etaHistory.data"
                                :loading="etaHistory.loading"
                                loading-text="Loading History, please wait..."
                                class="elevation-1"
                                style="min-height: 60vh;"
                                disable-pagination
                                dense
                                hide-default-footer>
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgFreeDays"
                title="Free Days"
                confirm-button-text="Save"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="updateFreeDays">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message"
                                      v-model="freeDays.FreeDays"
                                      outlined
                                      dense
                                      type="number"
                                      background-color="white"
                                      hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from 'moment';

    export default {
        title: "ETA Status",
        data(){
            return {
                files: {
                    fromDatePicker: null,
                    toDatePicker: null,
                    headers: [
                        { text: 'S. No', value: 'SNo', applyFilter: false, divider: true, },
                        { text: 'File No', value: 'FileNo', applyFilter: true, divider: true, },
                        { text: 'BL No', value: 'BLNo', applyFilter: true, divider: true, },
                        { text: 'BL Status', value: 'BLStatus', applyFilter: true, divider: true, },
                        { text: 'Company', value: 'CompanyName', applyFilter: true, divider: true, },
                        // { text: 'Shipping Line', value: 'ShippingLine', applyFilter: true, divider: true, },
                        { text: 'Shipping Line', value: 'ShippingLine', applyFilter: true },
                        { text: 'FCL', value: 'FCL', applyFilter: true, divider: true, },
                        { text: 'Comments', value: 'Comments', applyFilter: true, divider: true, },
                        { text: 'ETA Date', value: 'ETADate', applyFilter: true, width: '150px', divider: true, },
                        { text: 'Days To Arrive', value: 'DaysAfterArrival', applyFilter: true, divider: true, },
                        { text: 'Free Days', value: 'FreeDays', applyFilter: true, divider: true, },
                        { text: 'Product Description', value: 'ProductDescription', applyFilter: true, divider: true, },
                        // { text: 'ETB Date', value: 'ETBDate', applyFilter: true, divider: true, },
                        { text: 'Last Updated', value: 'LastETAUpdateDate', applyFilter: true, divider: true, },
                        { text: 'Vessel Status', value: 'VesselStatus', applyFilter: true, divider: true, },
                        { text: 'Approval', value: 'IsETAApproved', applyFilter: false, divider: true, },
                        { text: 'Action', value: 'Actions', applyFilter: false, divider: true, },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: false,
                },
                eta: {
                    FileID: 0,
                    ETADate: '',
                    ETBDate: '',
                    Comments: '',
                },
                selectedFile: {},
                etaHistory: {
                    header: [
                        { text: 'S.No', value: 'SNo' },
                        { text: 'ETA Date', value: 'ETADate' },
                        { text: 'ETB Date', value: 'ETBDate' },
                        { text: 'Comments', value: 'Comments' },
                        { text: 'Update Date', value: 'UpdateDate' },
                        { text: 'Updated By', value: 'UpdatedBy' },
                    ],
                    data: [],
                    loading: false,
                    selected: [],
                },
                message: {
                    FileID: 0,
                    Message: '',
                },
                freeDays: {
                    FileID: 0,
                    FreeDays: 0,
                },
                rights: {
                    allowEdit: false,
                    allowApprove: false,
                    allowFreeDays: false,
                }
            };
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            fromDate() {
                if(this.files.fromDatePicker)
                {
                    let f_date = this.files.fromDatePicker.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
            toDate() {
                if(this.files.toDatePicker)
                {
                    let f_date = this.files.toDatePicker.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
        },
        methods: {
            getData(){
                this.files.loading = true;

                const formData = {
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    FromDate: this.fromDate,
                    ToDate: this.toDate,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    Filters: this.files.filters,
                };
                // console.log(JSON.stringify(formData));

                axios.post('ETA/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            etaRowClass(file){
                return file.LastETAUpdateDays > file.ETAUpdateDays ? 'red white--text' : '';
            },
            viewHistory(file) {
                this.$loadingDialog.show();

                const formData = {
                    FileID: file.FileID,
                };

                axios.post('ETA/History', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.selectedFile = file;
                            this.etaHistory.data = data.Data;
                            this.$refs.dlgETAHistory.show();
                        }

                        this.$loadingDialog.hide();
                    })
                    .catch(()=>{
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.$loadingDialog.hide();
                    });
            },
            editETA(file) {
                this.selectedFile = file;

                this.eta = {
                    FileID: file.FileID,
                    ETADate: this.formatString(file.ETADate),
                    ETBDate: '01-01-1970', //this.formatString(file.ETBDate),
                    Comments: '',
                };

                this.$refs.dlgETA.show();
            },
            saveETA() {
                this.$loadingDialog.show();

                const formData = this.eta;

                axios.post('ETA/Update', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(()=>{
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.$loadingDialog.hide();
                    });
            },
            approveETA(file) {
                this.$confirmDialog.show({
                    title: 'Approve ETA',
                    message: `Do you want to approve the following:<br/>New ETA Date: <b>${file.NewETADate}</b><br/>New ETB Date: <b>${file.NewETBDate}</b>`,
                    html: true,
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        axios.post('ETA/Approve', {FileID: file.FileID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                this.$loadingDialog.hide();
                            })
                            .catch(()=>{
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                                this.$loadingDialog.hide();
                            });
                    }
                });
            },
            viewSMS(){
                this.message = {
                    FileID: this.eta.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
            viewFreeDays(file){
                this.freeDays = {
                    FileID: file.FileID,
                    FreeDays: file.FreeDays,
                };

                this.$refs.dlgFreeDays.show();
            },
            async updateFreeDays(){
                this.$loadingDialog.show();

                await axios.post('ETA/UpdateFreeDays', this.freeDays)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(()=>{
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            formatDate(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
            formatString(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    const mon = this.months.indexOf(f_date[1]);
                    let f_date_string = new Date(f_date[2], mon, f_date[0]);

                    return this.toShortDateString(f_date_string);
                }
                else
                    return '';
            },
            async exportToExcel(){
                const formData = {
                    PageNo: 1,
                    NoOfRows: 99999999,
                    FromDate: this.fromDate,
                    ToDate: this.toDate,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    Filters: this.files.filters,
                    Fields: [
                        'FileNo',
                        'BLNo',
                        'BLStatus',
                        'CompanyName',
                        'ShippingLine',
                        'FCL',
                        'ProductDescription',
                        'Comments',
                        'ETADate',
                        'DaysAfterArrival',
                        'FreeDays',
                        'ETBDate',
                        'LastETAUpdateDate',
                        'VesselStatus',
                    ],
                };

                await this.excelExport('ETA/ExportToExcel', formData, `ETA.xlsx`);
            }
        },
        created() {
            this.rights.allowEdit = this.verifyRights(this.$route.name, 'E', 0);
            this.rights.allowApprove = this.verifyRights(this.$route.name, 'C', 0);
            this.rights.allowFreeDays = this.verifyRights(this.$route.name, 'F', 0);

            const endOfYearDate = new Date().getFullYear() + '-12-01';
            this.files.fromDatePicker = new moment().format('YYYY-MM-DD');
            this.files.toDatePicker = new moment(endOfYearDate).endOf('months').format('YYYY-MM-DD');

            // this.files.fromDatePicker = this.toShortDateString(new Date());
            // this.files.toDatePicker = this.toShortDateString(new Date());
            this.getData();
        }
    }
</script>

<style scoped>

</style>