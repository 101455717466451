var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({background: _vm.$vuetify.theme.themes.light.background}),attrs:{"light":""}},[_c('v-app-bar',{attrs:{"app":"","dark":"","dense":"","color":"primary","clipped-left":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-toolbar-title',{staticClass:"headline"},[_vm._v("ShipOn Logistics")]),(_vm.$vuetify.breakpoint.mdAndUp)?[_vm._l((_vm.modules),function(mod,i){return [(mod.Links.length == 0)?_c('v-btn',{key:mod.ModuleID,class:i == 0 ? 'ml-5' : '',attrs:{"text":"","small":"","to":{ name: mod.LinkName }}},[_vm._v(" "+_vm._s(mod.Title)+" ")]):_c('v-menu',{key:mod.ModuleID,attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:i == 0 ? 'ml-5' : '',attrs:{"small":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(mod.Title)+" ")])]}}],null,true)},[_c('v-list',_vm._l((mod.Links),function(link){return _c('v-list-item',{key:link.LinkID,attrs:{"ripple":"","link":"","to":link.LinkName.includes('AddEdit') ?
                                        {name: link.LinkName, params: {action: 'new'}} :
                                        link.LinkName.includes('ops') ? {name: link.LinkName, params: {stage: link.LinkID}} :
                                        link.LinkName.includes('terminal') ? {name: link.LinkName, params: {id: link.LinkID}} :
                                        {name: link.LinkName}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(link.Title)+" "),(mod.Title == 'Terminals')?[_vm._v(" ("+_vm._s(_vm.getTerminalCount(link.LinkID))+") ")]:_vm._e()],2)],1)],1)}),1)],1)]}),(_vm.user.UserRoleID == 1)?_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text--white",attrs:{"text":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Administration ")])]}}],null,false,817776921)},[_c('v-list',[_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('users')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('usersRoles')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Users Roles")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('currency')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Currency")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('transporters')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Transporters")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('operationSettings')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Operations")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('terminalSettings')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Terminals")])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.viewLink('settings')}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Settings")])],1)],1)],1)],1):_vm._e(),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","text":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.user.Name)+" ")])]}}],null,false,1538882555)},[_c('v-list',[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.Username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.UserRole))])],1)],1),_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.logout}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1)],1)],1)]:_vm._e()],2),(_vm.$vuetify.breakpoint.smAndDown)?_c('nav-drawer',{attrs:{"drawer":_vm.drawer},on:{"drawerChange":function($event){_vm.drawer = $event}}}):_vm._e(),_c('v-main',[_c('router-view',{on:{"onTerminalAssigned":_vm.onTerminalAssigned}})],1),_c('confirm-dialog'),_c('document-dialog'),_c('snack-bar'),_c('loading-dialog')],1)}
var staticRenderFns = []

export { render, staticRenderFns }