<template>
    <div>
        <v-menu min-width="auto" offset-y v-bind="$attrs">
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        ref="txt"
                        :label="label"
                        :value="formattedDate"
                        outlined
                        class="small-text-field"
                        background-color="white"
                        dense
                        readonly
                        hide-details="auto"
                        autocomplete="off">
                </v-text-field>
            </template>
            <v-date-picker v-bind="$attrs" v-on="$listeners" />
        </v-menu>
    </div>
</template>

<script>
    import moment from 'moment';

    export default {
        name: "datePicker",
        props: ['label', 'dateFormat', 'value'],
        computed: {
          formattedDate(){
              return this.value.length == 0 ? "" : new moment(this.value).format(this.dateFormat ?? 'DD-MMM-YYYY');
          }
        },
        methods: {
            focus(){
                this.$refs.txt.focus();
            }
        }
    }
</script>

<style scoped>

</style>