<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">
                    Files
                </h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col>
                <v-card :color="CIP_Color">
                    <v-card-text class="d-flex">
                        <h4 class="white--text">Containers In-Progress: {{ files.totals.CIP_InProgress }}</h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="2">
                <v-btn
                        @click="newFile"
                        class="green py-4"
                        dark
                        small
                        block
                        v-if="rights.allowAdd">
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New File</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4>Total Records: <span class="indigo--text text--darken-2">{{ files.totals.TotalRecords }}</span></h4>
                        <h4 class="ml-2">Total Approved: <span class="indigo--text text--darken-2">{{ files.totals.Approved }}</span></h4>
                        <h4 class="ml-2">Total Not-Approved: <span class="indigo--text text--darken-2">{{ files.totals.NotApproved }}</span></h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.boeModeTotal)" :key="key" class="mr-2">
                            BOE {{ key }}: <span class="indigo--text text--darken-2">{{ files.boeModeTotal[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.boeStatusTotal)" :key="key" class="mr-2">
                            BOE {{ key }}: <span class="indigo--text text--darken-2">{{ files.boeStatusTotal[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="9">
                <v-btn
                        color="red darken-4"
                        small
                        class="mr-1 white--text"
                        v-if="rights.allowDelete"
                        @click="deleteFiles">
                    Delete
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" align="end">
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="exportToExcel">
                    Export To Excel
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="ml-1 white--text">
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="files.selected"
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        :item-class="etaRowClass"
                        item-key="FileID"
                        class="elevation-1"
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td><v-icon>fa-filter</v-icon></td>
                            <td v-for="header in files.headers" :key="header.value">
                                <v-text-field
                                        v-model="files.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.DaysAfterArrival="props">
                        <td v-if="props.item.DaysAfterArrival < 0"></td>
                        <td v-else>{{ props.item.DaysAfterArrival }}</td>
                    </template>
                    <template v-slot:item.VesselStatus="props">
                        <v-chip label :color="props.item.VesselStatus == 'ARRIVED' ? 'orange' : 'green'" text-color="white" class="my-1">{{ props.item.VesselStatus }}</v-chip>
                    </template>
                    <template v-slot:item.Status="props">
                        <v-chip label :color="props.item.FKStatusID == 1 ? 'red' : 'green'" text-color="white" class="my-1">{{ props.item.FKStatusID == 1 ? 'PENDING' : 'APPROVED' }}</v-chip>
                    </template>
                    <template v-slot:item.SMS="{item}">
                        <v-btn small color="primary" @click="viewSMS(item, false)" v-if="rights.allowSMS">SMS</v-btn>
                        <v-btn small color="primary" @click="viewSMS(item, true)" class="ml-2" v-if="rights.allowSMS">Predefined SMS</v-btn>
                    </template>
                    <template v-slot:item.Actions="props">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="viewSMSHistory(props.item.FileID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-title>SMS History</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="viewHistory(props.item.FileID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-search</v-icon></v-list-item-icon>
                                    <v-list-item-title>Shipment History</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="viewFreeDays(props.item)">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit Free Days</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="editFile(props.item.FileID)" dense
                                             v-if="rights.allowEdit">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="deleteSingle(props.item)" dense
                                             v-if="rights.allowDelete">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-trash</v-icon></v-list-item-icon>
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgHistory"
                title="File History"
                :persistent="false"
                confirm-button-text=""
                cancel-button-text="Close"
                max-width="1000">
            <template v-slot:content>
                <v-list dense>
                    <template v-if="fileHistory.data.length > 0">
                        <v-list-item v-for="item in fileHistory.data" :key="item.ActionDate">
                            <v-list-item-content>
                                <v-list-item-title>{{ item.Action }}</v-list-item-title>
                                <v-list-item-subtitle>{{ item.ActionDate }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Stage: {{ item.Stage }} | User: {{ item.Username }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title>No History Available</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
<!--                <div style="overflow-x: auto;">-->
<!--                    <v-data-table-->
<!--                            :headers="fileHistory.header"-->
<!--                            :items="fileHistory.data"-->
<!--                            dense-->
<!--                            disable-pagination-->
<!--                            class="elevation-1"-->
<!--                            hide-default-footer>-->
<!--                    </v-data-table>-->
<!--                </div>-->
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details v-if="!message.IsPredefined" />
                        <v-autocomplete label="Message" v-model="message.Message" :items="predefinedMessages" item-text="TextLine" item-value="TextLine" outlined dense background-color="white" hide-details v-else />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMSHistory"
                title="SMS History"
                confirm-button-text=""
                cancel-button-text="Close"
                max-width="700">
            <template v-slot:content>
                <v-row dense>
                    <v-col>
                        <v-list>
                            <template v-if="smsHistory.length > 0">
                                <v-list-item v-for="sms in smsHistory" :key="sms.SystemSmsID">
                                    <v-list-item-content>
                                        <v-list-item-title>{{ sms.Message }}</v-list-item-title>
                                        <v-list-item-subtitle>Sent: <v-icon small>{{ sms.IsSent ? 'fa-check' : 'fa-times' }}</v-icon></v-list-item-subtitle>
                                        <v-list-item-subtitle>Sent Date: {{ sms.SentDate }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>API Response: {{ sms.Response }}</v-list-item-subtitle>
                                        <v-divider class="mt-2"></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                            <template v-else>
                                <v-list-item>
                                    <v-list-item-title>No SMS History Found</v-list-item-title>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

        <custom-dialog
                ref="dlgViewDocuments"
                title="Documents"
                :persistent="false"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

        <custom-dialog
                ref="dlgDocuments"
                title="Upload Document"
                :persistent="false"
                confirm-button-text="Add"
                cancel-button-text="Cancel"
                :on-confirm="saveDocument">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentTitle"
                                label="Document Title"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                class="primary"
                                dark
                                block >
                            Upload Document
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadDocument" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgFreeDays"
                title="Free Days"
                confirm-button-text="Save"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="updateFreeDays">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message"
                                      v-model="freeDays.FreeDays"
                                      outlined
                                      dense
                                      type="number"
                                      background-color="white"
                                      hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Files",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S.No', value: 'SNo', applyFilter: false },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'BL Status', value: 'BLStatus', applyFilter: true },
                        { text: 'Shipment Type', value: 'ShipmentType', applyFilter: true },
                        { text: 'Shipping Line', value: 'ShippingLine', applyFilter: true },
                        { text: 'FCL', value: 'FCL', applyFilter: true },
                        { text: 'Ref No', value: 'Reference', applyFilter: true },
                        { text: 'Product Description', value: 'ProductDescription', applyFilter: true },
                        { text: 'ETA', value: 'ETADate', applyFilter: true },
                        { text: 'Vessel Status', value: 'VesselStatus', applyFilter: true },
                        { text: 'Days After Arrival', value: 'DaysAfterArrival', applyFilter: false },
                        { text: 'Free Days', value: 'FreeDays', applyFilter: false },
                        { text: 'BOE Mode', value: 'BOEMode', applyFilter: true },
                        { text: 'BOE Status', value: 'BOEStatus', applyFilter: true },
                        { text: 'Approved', value: 'Status', applyFilter: true },
                        { text: 'Send SMS', value: 'SMS', applyFilter: true },
                        { text: 'Action', value: 'Actions', applyFilter: false },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    boeStatusTotal: {},
                    boeModeTotal: {},
                    loading: true,
                },
                fileHistory: {
                    header: [
                        { text: 'Action Date', value: 'ActionDate', width: '20%' },
                        { text: 'Action', value: 'Action', width: '200' },
                        { text: 'Stage', value: 'Stage', width: '20%' },
                        { text: 'User', value: 'Username', width: '15%' },
                    ],
                    data: [],
                },
                smsHistory: [],
                rights: {
                    allowAdd: this.verifyRights(this.$route.name, 'A', 0),
                    allowEdit: this.verifyRights(this.$route.name, 'E', 0),
                    allowDelete: this.verifyRights(this.$route.name, 'D', 0),
                    allowSMS: this.verifyRights(this.$route.name, 'S', 0),
                },
                message: {
                    IsPredefined: false,
                    FileID: 0,
                    Message: '',
                },
                freeDays: {
                    FileID: 0,
                    FreeDays: 0,
                },
                predefinedMessages: [],
                document: {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
                documents: [],
                settings: {},
            };
        },
        computed: {
            selectedFiles(){
                let selected = [];
                this.files.selected.forEach(user => {
                    selected.push(user.FileID);
                });
                return selected;
            },
            CIP_Color(){
                if('CIP_InProgress' in this.files.totals && 'CIP_Red' in this.settings) {
                    if(this.files.totals.CIP_InProgress <= this.settings.CIP_Red)
                        return 'red';
                    else if(this.files.totals.CIP_InProgress <= this.settings.CIP_Yellow)
                        return 'yellow darken-2';
                    else if(this.files.totals.CIP_InProgress >= this.settings.CIP_Green)
                        return 'green darken-2';
                    else
                        return '';
                }
                else {
                    return '';
                }
            },
        },
        methods: {
            getData(){
                this.files.data = [];
                this.files.loading = true;

                const formData = {
                    FileID: 0,
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: false,
                    IsActive: true,
                    Filters: this.files.filters,
                };

                axios.post('Files/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.boeStatusTotal = data.Data.BOEStatusTotal;
                            this.files.boeModeTotal = data.Data.BOEModeTotal;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            etaRowClass(file){
                return file.DaysAfterArrival > file.ETAClearingDays ? 'red lighten-1 white--text' : '';
            },
            async viewHistory(fileID){
                this.$loadingDialog.show();

                await axios.post('Files/GetHistory', {FileID: fileID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.fileHistory.data = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
                this.$refs.dlgHistory.show();
            },
            newFile(){
                this.$router.push({name: 'filesAddEdit', params: {"action": "new"}});
            },
            editFile(fileID){
                this.$router.push({name: 'filesAddEdit', params: {"action": "edit"}, query: {id: fileID}});
            },
            deleteSingle(file){
                this.files.selected.push(file);
                this.deleteFiles();
            },
            deleteFiles() {
                this.$confirmDialog.show({
                    title: 'Delete File(s)',
                    message: 'Are you sure you want to delete the selected file(s)? This action cannot be undone.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            FileIDs: this.selectedFiles,
                        };

                        axios.post('Files/Delete', formData)
                            .then(response => {
                                const data = response.data;
                                let success = data.Status;

                                if(success) {
                                    this.getData();
                                    this.files.selected = [];
                                }

                                this.$loadingDialog.hide();
                                this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                            })
                            .catch( error => {
                                console.log(error);
                                this.$loadingDialog.hide();
                                this.$snackBar.error({message: 'An unexpected error has occurred.'});
                            })
                    }
                });
            },
            viewSMS(file, isPredefined){
                this.message = {
                    IsPredefined: isPredefined,
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                if(this.message.Message.length == 0)
                {
                    this.$snackBar.error("Message is blank");
                    return;
                }

                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
            async viewSMSHistory(fileID) {
                this.$loadingDialog.show();

                await axios.post('Utilities/GetSMSHistory', {FileID: fileID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.smsHistory = data.Data;
                            this.$refs.dlgSMSHistory.show();
                        }
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            viewFreeDays(file){
                this.freeDays = {
                    FileID: file.FileID,
                    FreeDays: file.FreeDays,
                };

                this.$refs.dlgFreeDays.show();
            },
            async updateFreeDays(){
                this.$loadingDialog.show();

                await axios.post('ETA/UpdateFreeDays', this.freeDays)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(()=>{
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            async exportToExcel(){
                const formData = {
                    FileID: 0,
                    PageNo: 1,
                    NoOfRows: 99999999,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: false,
                    IsActive: true,
                    Filters: this.files.filters,
                    Fields: [
                        'FileNo',
                        'CompanyName',
                        'BLNo',
                        'BLStatus',
                        'ShippingLine',
                        'FCL',
                        'Reference',
                        'ProductCategory',
                        'ProductDescription',
                        'ETADate',
                        'VesselStatus',
                        'DaysAfterArrival',
                        'FreeDays',
                        'BOEMode',
                        'BOEStatus',
                        'Status'
                    ]
                };

                await this.excelExport('Files/ExportToExcel', formData, 'Files.xlsx');
            },
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
        },
        async created() {
            this.$loadingDialog.show();

            this.getSettings();

            await this.getGeneral(18, 0)
                .then(response => {
                    this.predefinedMessages = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.$loadingDialog.hide();
            this.getData();
        }
    }
</script>

<style scoped>
</style>