<template>
    <v-dialog
      v-model="dialog"
      :persistent="persistent"
      :max-width="maxWidth" >

      <v-card color="background">
        <v-card-title class="headline text--primary">{{ title }}</v-card-title>
        <v-card-text v-if="html" v-html="message"></v-card-text>
        <v-card-text v-else>{{ message }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="hide">{{ this.cancelButtonText }}</v-btn>
          <v-btn color="primary" @click="confirm" v-if="confirmButtonText.length > 0">{{ confirmButtonText }}</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>

<script>
    import documentUploadDialog from "./documentUploadDialog.js";

    export default {
      name: "confirmDialog",
      data() {
        return {
          dialog: false,
          persistent: true,
          maxWidth: 450,
          title: '',
          message: '',
          confirmButtonText: 'Yes',
          cancelButtonText: 'No',
          html: false,
          onConfirm: {},
        };
      },
      methods: {
        hide() {
          this.dialog = false;
        },
        confirm() {
          // we must check if this.onConfirm is function
          if(typeof this.onConfirm === 'function') {
            // run passed function and then close the modal
            this.onConfirm();
            this.hide();
          } else {
            // we only close the modal
            this.hide();
          }
        },
        show(params) {
          // making modal visible
          this.dialog = true;
          // setting title and text
          this.title = params.title;
          this.message = params.message;

          if( 'confirmButton' in params )
            this.confirmButtonText = params.confirmButton;

          if('cancelButton' in params)
            this.cancelButtonText = params.cancelButton;

          if('html' in params)
            this.html = params.html;

          // setting callback function
          this.onConfirm = params.onConfirm;
        },
      },
      beforeMount() {
        // here we need to listen for emited events
        // we declared those events inside our plugin
        documentUploadDialog.EventBus.$on('show', (params) => {
          this.show(params)
        })
      }
    }
</script>

<style scoped>

</style>
