<template>
    <v-app light :style="{background: $vuetify.theme.themes.light.background}">
        <v-main>
            <v-container class="fill-height" fluid>
                <v-row dense justify="center">
                    <v-col cols="12">
                        <v-img src="../assets/logo.png" max-height="100px" contain />
                    </v-col>
                </v-row>
                <v-row justify="center" align="center">
                    <v-col cols="12" md="6" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-img :src="this.settings.LoginBanner" />
                    </v-col>
                    <v-col cols="12" md="4">
<!--                        <h1 class="text-center">ShipOn Logistics<br/>Logistics Management System</h1>-->

                        <v-card flat class="mt-5">
                            <v-card-title>Login</v-card-title>
                            <v-form @submit.prevent="login">
                                <v-card-text>
                                    <v-alert v-if="alert.show" :type="alert.type">{{ alert.message }}</v-alert>
                                    <v-text-field
                                            v-model="formData.username"
                                            filled
                                            dense
                                            ref="txtUsername"
                                            label="Username"
                                            name="username"
                                            type="text"
                                            prepend-inner-icon="mdi-account"
                                            hide-details="auto"
                                            :error-messages="errors.userError"
                                            :disabled="isLoading">
                                    </v-text-field>

                                    <v-text-field
                                            v-model="formData.password"
                                            filled
                                            dense
                                            ref="txtPassword"
                                            label="Password"
                                            name="password"
                                            type="password"
                                            prepend-inner-icon="mdi-lock"
                                            class="mt-2"
                                            hide-details="auto"
                                            :error-messages="errors.passwordError"
                                            :disabled="isLoading">
                                    </v-text-field>
                                </v-card-text>
                                <v-card-actions class="mx-2">
                                    <v-btn
                                            type="submit"
                                            color="primary"
                                            class="mb-2"
                                            block
                                            large
                                            :loading="isLoading"
                                            :disabled="isLoading">
                                        Login
                                    </v-btn>
                                </v-card-actions>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
    import Bowser from 'bowser';
    import axios from 'axios';

    export default {
        title: "Login | ShipOn Logistics",
        data(){
            return {
                formData: {
                    grant_type: 'password',
                    username: '',
                    password: '',
                    MacAddress: '',
                    IPAddress: '',
                    Location: '',
                    Browser: '',
                },
                alert: {
                    type: 'success',
                    message: '',
                    show: false,
                },
                isLoading: false,
                errors: {
                    userError: '',
                    passwordError: '',
                },
                settings: {},
            };
        },
        methods: {
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            login() {
                this.alert.show = false;
                this.errors.userError = "";
                this.errors.passwordError = "";

                if( !this.formData.username )
                {
                    this.errors.userError = "Please enter your username";
                    this.$refs.txtUsername.focus();
                    return;
                }
                if( !this.formData.password )
                {
                    this.errors.passwordError = "Please enter your password";
                    this.$refs.txtPassword.focus();
                    return;
                }

                this.formData.MacAddress = localStorage.getItem('user-guid') ?? '';

                this.isLoading = true;
                this.$store.dispatch('login', this.formData)
                    .then(() => {
                        this.alert.message = 'User logged in successfully';
                        this.alert.type = 'success';
                        this.alert.show = true;
                        this.isLoading = false;

                        const vm = this;
                        setTimeout(function(){
                            const module = vm.$store.getters.modules[0];

                            if(module.Links.length == 0)
                                vm.$router.push({name: module.LinkName});
                            else
                            {
                                const link = module.Links[0];
                                vm.$router.push({name: link.LinkName, params: {stage: link.LinkID, id: link.LinkID, action: 'new'}});
                            }
                        }, 1000);
                    })
                    .catch(error => {
                        console.log(error.response);
                        if(error.response == undefined) {
                            this.alert.message = 'Unable to login at this moment, please try again later.';
                            this.alert.type = 'error';
                            this.alert.show = true;
                        }
                        else {
                            this.alert.message = error.response.data.error_description;
                            this.alert.type = 'error';
                            this.alert.show = true;

                        }
                        this.isLoading = false;
                        this.$refs.txtPassword.focus();
                    });
            }
        },
        created() {
            const bowser = Bowser.getParser(window.navigator.userAgent);
            let os = bowser.getOSName();
            let browser = bowser.getBrowserName() + ' ' + bowser.getBrowserVersion();
            this.formData.Browser = `${browser} on ${os}`;

            axios.get('https://ipinfo.io/json', {}).then(response => {
                let resp = response.data;
                let location = `${resp.city}, ${resp.region}, ${resp.country} (${resp.loc})`;
                let ipAddress = resp.ip;

                this.formData.Location = location;
                this.formData.IPAddress = ipAddress;
            });

            this.getSettings();
        }
    }
</script>

<style scoped>

</style>