<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h2 class="heading">{{ action }} Client</h2>
            </v-col>
        </v-row>
        <v-row dense class="fill-height">
            <v-col cols="12" md="5">
                <v-row dense>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="customer.CustomerNo"
                                label="Customer No"
                                class="small-text-field elevation-1"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.CompanyName"
                                label="Company Name"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                autocomplete="disabled"
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.CompanyRegistrationDate"
                                label="Company Registration Date"
                                class="small-text-field mt-2 elevation-1"
                                type="date"
                                background-color="white"
                                outlined
                                dense
                                autocomplete="disabled"
                                hide-details="auto">
                        </v-text-field>

<!--                        <v-menu-->
<!--                                transition="scale-transition"-->
<!--                                offset-y-->
<!--                                min-width="290px"-->
<!--                                :close-on-content-click="false">-->
<!--                            <template v-slot:activator="{ on, attrs }">-->
<!--                                <v-text-field-->
<!--                                        v-model="customer.CompanyRegistrationDate"-->
<!--                                        label="Company Registration Date"-->
<!--                                        class="small-text-field mt-2 elevation-1"-->
<!--                                        background-color="white"-->
<!--                                        outlined-->
<!--                                        dense-->
<!--                                        readonly-->
<!--                                        hide-details="auto"-->
<!--                                        v-bind="attrs"-->
<!--                                        v-on="on">-->
<!--                                </v-text-field>-->
<!--                            </template>-->
<!--                            <v-date-picker-->
<!--                                    v-model="customer.CompanyRegistrationDate">-->
<!--                            </v-date-picker>-->
<!--                        </v-menu>-->

                        <v-text-field
                                v-model="customer.TINNo"
                                label="TIN No"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>

                        <v-select
                                v-model="customer.FKIDTypeID"
                                label="ID Type"
                                :items="idTypes"
                                item-text="TextLine"
                                item-value="Value01"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>

                        <v-select
                                v-model="customer.FKBusinessTypeID"
                                label="Business Type"
                                :items="businessTypes"
                                item-text="TextLine"
                                item-value="Value01"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>

                        <v-select
                                v-model="customer.FKReferredByID"
                                label="Referred By"
                                :items="customerReferenceTypes"
                                item-text="TextLine"
                                item-value="Value01"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>

                        <v-text-field
                                v-model="customer.ReferenceNo"
                                label="Reference"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.Notes"
                                label="Notes"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.CreditLimit"
                                label="Credit Limit"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.CreditDays"
                                label="Credit Days"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-card>
                            <v-card-text>
                                <v-checkbox
                                        v-model="customer.SendDailySummary"
                                        class="mt-0"
                                        label="Send Daily Summary"
                                        hide-details="auto">
                                </v-checkbox>

                                <v-checkbox
                                        v-model="customer.SendEmailUpdates"
                                        label="Send Updates By Email"
                                        hide-details="auto">
                                </v-checkbox>

                                <v-checkbox
                                        v-model="customer.SendSMSUpdates"
                                        label="Send Updates By SMS"
                                        hide-details="auto">
                                </v-checkbox>

                                <v-checkbox
                                        v-model="customer.IsClientLoginEnabled"
                                        label="Client Login Enabled"
                                        hide-details="auto">
                                </v-checkbox>

                            </v-card-text>
                        </v-card>

                        <v-text-field
                                v-model="customer.Username"
                                label="Username"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                :disabled="!customer.IsClientLoginEnabled"
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="customer.Password"
                                label="Password"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                :disabled="!customer.IsClientLoginEnabled"
                                hide-details="auto">
                        </v-text-field>

                        <v-textarea
                                v-model="customer.Remarks"
                                label="Remarks"
                                class="small-text-field mt-2 elevation-1"
                                background-color="white"
                                outlined
                                dense
                                rows="2"
                                hide-details="auto">
                        </v-textarea>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>
                        Contact Persons
                        <v-spacer></v-spacer>
                        <v-btn
                                color="green"
                                class="white--text"
                                small
                                @click="addContact">
                            <v-icon left>fa-plus-circle</v-icon> Add Contact
                        </v-btn>
                    </v-card-title>
                    <v-list v-if="customer.CustomersPersons.length > 0">
                        <v-list-item v-for="contact in customer.CustomersPersons" :key="contact.ContactPerson">
                            <v-list-item-content>
                                <v-list-item-title>{{ contact.ContactPerson }}</v-list-item-title>
                                <v-list-item-subtitle>Mobile: {{ contact.Mobile }}</v-list-item-subtitle>
                                <v-list-item-subtitle>WhatsApp: {{ contact.Whatsapp }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Phone: {{ contact.Phone }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Email: {{ contact.Email }}</v-list-item-subtitle>
                                <v-list-item-subtitle>Address: {{ contact.Address }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-btn icon @click="editContact(contact)" small><v-icon color="grey" small>fa-pencil</v-icon></v-btn>
                            </v-list-item-action>
                            <v-list-item-action>
                                <v-btn icon small @click="deleteContact(contact)"><v-icon color="grey" small>fa-trash</v-icon></v-btn>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Contact Person<br/>click <b>"ADD CONTACT"</b> button above
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card>
                    <v-card-title>
                        Delivery Address
                        <v-spacer></v-spacer>
                        <v-btn
                                color="green"
                                class="white--text"
                                small
                                @click="addAddress">
                            <v-icon left>fa-plus-circle</v-icon> Add Address
                        </v-btn>
                    </v-card-title>
                    <v-list
                            dense
                            v-if="customer.CustomersLocations.length > 0" >
                        <v-list-item
                                v-for="location in customer.CustomersLocations"
                                :key="location.CustomerLocationID">
                            <v-list-item-content>
                                <v-list-item-title>{{ location.Address }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-btn icon small><v-icon small color="grey">fa-trash</v-icon></v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Delivery Address<br/>click <b>"ADD ADDRESS"</b> button above
                    </v-card-text>
                </v-card>
                <v-card class="mt-2">
                    <v-card-title>
                        Documents
                        <v-spacer></v-spacer>
                        <v-btn
                                color="green"
                                class="white--text"
                                small
                                @click="addDocument">
                            <v-icon left>fa-plus-circle</v-icon> Add Document
                        </v-btn>
                    </v-card-title>
                    <v-list
                            dense
                            v-if="customer.Documents.length > 0">
                        <v-list-item
                                v-for="document in customer.Documents"
                                :key="document.DocumentTitle"
                                :href="document.FilePath"
                                target="_blank"
                                link >
                            <v-list-item-content>
                                <v-list-item-title>{{ document.DocumentTitle }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-btn icon small><v-icon small color="grey">fa-trash</v-icon></v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Document<br/>click <b>"ADD DOCUMENT"</b> button above
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider class="my-3"></v-divider>
        <v-row dense>
            <v-col cols="12" align="end">
                <v-btn color="green" class="white--text" @click="saveCustomer">
                    Save
                </v-btn>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgContactDetails"
                title="Contact Details"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveContact">
            <template v-slot:content>
                <v-form @submit="saveContact">
                    <v-row dense>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="contactPerson.ContactPerson"
                                    label="Contact Person"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="contactPerson.Mobile"
                                    label="Mobile"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="contactPerson.Whatsapp"
                                    label="Whatsapp No"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field
                                    v-model="contactPerson.Phone"
                                    label="Phone"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                    v-model="contactPerson.Email"
                                    label="Email"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                    v-model="contactPerson.Address"
                                    label="Address"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                        <v-col cols="12" md="12">
                            <v-text-field
                                    v-model="contactPerson.Notes"
                                    label="Notes"
                                    solo
                                    dense
                                    hide-details="auto">
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgAddress"
                title="Delivery Address"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveAddress">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="location.Address"
                                label="Address"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocuments"
                title="Documents"
                :persistent="false"
                confirm-button-text="Add"
                cancel-button-text="Cancel"
                :on-confirm="saveDocument">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentTitle"
                                label="Document Title"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                class="primary"
                                dark
                                block >
                            Upload Document
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadDocument" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: `Clients`,
        data(){
            return {
                customer: {
                    CustomerID: 0,
                    CustomerNo: '',
                    CompanyName: '',
                    CompanyRegistrationDate: '',
                    TINNo: '',
                    FKIDTypeID: 0,
                    FKBusinessTypeID: 0,
                    FKReferredByID: 0,
                    ReferenceNo: '',
                    Notes: '',
                    CreditLimit: 0,
                    CreditDays: 0,
                    SendDailySummary: false,
                    SendEmailUpdates: false,
                    SendSMSUpdates: false,
                    IsClientLoginEnabled: false,
                    Username: "",
                    Password: "",
                    Remarks: '',
                    CustomersPersons: [],
                    CustomersLocations: [],
                    Documents: [],
                },
                contactPerson: {
                    ContactPerson: '',
                    Mobile: '',
                    Whatsapp: '',
                    Phone: '',
                    Email: '',
                    Address: '',
                    Notes: '',
                },
                location: {
                    CustomerLocationID: 0,
                    Address: '',
                },
                document: {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
                idTypes: [],
                businessTypes: [],
                customerReferenceTypes: [],
            };
        },
        computed: {
            action() {
                return this.$route.params.action == 'new' ? 'New' : 'Edit';
            }
        },
        methods: {
            getData(customerID){
                this.$loadingDialog.show();

                const formData = {
                    CustomerID: customerID,
                    PageNo: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: [],
                };
                console.log(JSON.stringify(formData));

                axios.post('Customers/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status)
                            this.customer = data.Data.Items[0];
                        else
                            this.$snackBar.show({color: 'error', message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });
            },
            addContact(){
                this.contactPerson = {
                    PersonID: 0,
                    ContactPerson: '',
                    Mobile: '',
                    Whatsapp: '',
                    Phone: '',
                    Email: '',
                    Address: '',
                };

                this.$refs.dlgContactDetails.show();
            },
            editContact(contactPerson){
                this.contactPerson = contactPerson;
                this.$refs.dlgContactDetails.show();
            },
            saveContact() {
                if( this.contactPerson.ContactPerson.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter contact person name.'});
                    return;
                }

                if(this.contactPerson.PersonID == 0)
                    this.customer.CustomersPersons.push(this.contactPerson);
            },
            deleteContact(person) {
                const ind = this.customer.CustomersPersons.indexOf(person);
                this.customer.CustomersPersons.splice(ind, 1);
            },
            addAddress(){
                this.location = {
                    CustomerLocationID: 0,
                    Address: '',
                };

                this.$refs.dlgAddress.show();
            },
            saveAddress() {
                this.customer.CustomersLocations.push(this.location);
            },
            addDocument(){
                this.document = {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: this.toShortDateString(new Date()),
                    DocumentNo: '',
                    FilePath: "",
                    File: null,
                    name: "",
                    size: "",
                    error: "",
                };

                this.$refs.dlgDocuments.show();
            },
            uploadDocument(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.document.error = "File size is exceeding the limit. Please select a smaller picture.";
                    return;
                }

                this.document.File = file;
                this.document.FilePath = URL.createObjectURL(file);
                this.document.name = file.name;
                this.document.size = size;
                this.document.error = "";
            },
            saveDocument() {
                if(this.document.File == null)
                {
                    this.$snackBar.error({message: 'Please select a file to upload'});
                    return;
                }
                if(!this.document.DocumentTitle)
                {
                    this.$snackBar.error({message: 'Please enter a document title'});
                    return;
                }

                this.customer.Documents.push(this.document);
            },
            saveCustomer(){
                if( this.customer.CompanyName.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter company name.'});
                    return;
                }

                if( this.customer.TINNo.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter TIN No.'});
                    return;
                }

                if( this.customer.CustomersPersons.length == 0 )
                {
                    this.$snackBar.error({message: 'Please add at least 1 contact person.'});
                    return;
                }

                if( this.customer.CustomersLocations.length == 0 )
                {
                    this.$snackBar.error({message: 'Please add at least 1 delivery location.'});
                    return;
                }

                this.$loadingDialog.show();

                let formData = new FormData();
                Object.keys(this.customer).forEach(key => {
                    if(key != 'CustomersPersons' && key != 'CustomersLocations' && key != 'Documents')
                        formData.append(`Customer.${key}`, this.customer[key]);
                });

                this.customer.CustomersPersons.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Customer.CustomersPersons[${ind}].${key}`, item[key]);
                    });
                });

                this.customer.CustomersLocations.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Customer.CustomersLocations[${ind}].${key}`, item[key]);
                    });
                });

                this.customer.Documents.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Documents[${ind}].${key}`, item[key]);
                    });
                });

                axios.post('Customers/AddEdit', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            if(this.action == 'Edit')
                                this.getData(this.customer.CustomerID);
                            else
                                this.$router.push({name: 'clients'});
                        }

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    }).catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });
            }
        },
        created() {
            this.getGeneral(1, 0)
                .then(response => {
                    this.idTypes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            this.getGeneral(2, 0)
                .then(response => {
                    this.businessTypes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            this.getGeneral(3, 0)
                .then(response => {
                    this.customerReferenceTypes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            if(this.action == 'Edit')
            {
                const customerID = this.$route.query.id;
                this.getData(customerID);
            }
        }
    }
</script>

<style scoped>

</style>