<template>
  <v-overlay :value="isLoading" style="z-index: 20001">
    <v-card color="primary">
      <v-card-text>
        <v-progress-circular color="white" indeterminate size="53"></v-progress-circular>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>

<script>
    import loadingDialog from "./loadingDialog.js";

    export default {
      name: "loadingDialog",
      data() {
        return {
          isLoading: false,
        };
      },
      methods: {
        hide() {
          this.isLoading = false;
        },
        show() {
          // making modal visible
          this.isLoading = true;
        },
      },
      beforeMount() {
        // here we need to listen for emited events
        // we declared those events inside our plugin
        loadingDialog.EventBus.$on('show', params => {
          this.show(params);
        });

        loadingDialog.EventBus.$on('hide', params => {
          this.hide(params);
        })
      }

    }
</script>

<style scoped>

</style>
