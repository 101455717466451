<template>
    <v-app light :style="{background: $vuetify.theme.themes.light.background}">
        <v-app-bar app dark dense color="primary" clipped-left>
            <v-app-bar-nav-icon @click="drawer = !drawer" v-if="$vuetify.breakpoint.smAndDown"></v-app-bar-nav-icon>
            <v-toolbar-title class="headline">ShipOn Logistics</v-toolbar-title>

            <template v-if="$vuetify.breakpoint.mdAndUp">
                <template v-for="(mod, i) in modules" >
                    <v-btn
                            text
                            small
                            :class="i == 0 ? 'ml-5' : ''"
                            v-if="mod.Links.length == 0"
                            :key="mod.ModuleID"
                            :to="{ name: mod.LinkName }">
                        {{ mod.Title }}
                    </v-btn>

                    <v-menu offset-y v-else :key="mod.ModuleID">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    small
                                    text
                                    :class="i == 0 ? 'ml-5' : ''">
                                {{ mod.Title }}
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item
                                    v-for="link in mod.Links"
                                    ripple
                                    link
                                    :key="link.LinkID"
                                    :to="link.LinkName.includes('AddEdit') ?
                                            {name: link.LinkName, params: {action: 'new'}} :
                                            link.LinkName.includes('ops') ? {name: link.LinkName, params: {stage: link.LinkID}} :
                                            link.LinkName.includes('terminal') ? {name: link.LinkName, params: {id: link.LinkID}} :
                                            {name: link.LinkName}" >
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ link.Title }}
                                        <template v-if="mod.Title == 'Terminals'">
                                            ({{ getTerminalCount(link.LinkID) }})
                                        </template>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </template>

                <v-menu offset-y v-if="user.UserRoleID == 1">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="text--white"
                            text
                            small >
                            Administration
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item ripple @click="viewLink('users')">
                            <v-list-item-content>
                                <v-list-item-title>Users</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('usersRoles')">
                            <v-list-item-content>
                                <v-list-item-title>Users Roles</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('currency')">
                            <v-list-item-content>
                                <v-list-item-title>Currency</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('transporters')">
                            <v-list-item-content>
                                <v-list-item-title>Transporters</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('operationSettings')">
                            <v-list-item-content>
                                <v-list-item-title>Operations</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('terminalSettings')">
                            <v-list-item-content>
                                <v-list-item-title>Terminals</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="viewLink('settings')">
                            <v-list-item-content>
                                <v-list-item-title>Settings</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
                <v-spacer></v-spacer>
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            dark
                            v-bind="attrs"
                            v-on="on"
                            text>
                            {{ user.Name }}
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{ user.Username }}</v-list-item-title>
                                <v-list-item-subtitle>{{ user.UserRole }}</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item ripple @click="logout">
                            <v-list-item-content>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>
        </v-app-bar>
        <nav-drawer :drawer="drawer" @drawerChange="drawer = $event" v-if="$vuetify.breakpoint.smAndDown"/>

        <v-main>
            <router-view @onTerminalAssigned="onTerminalAssigned"></router-view>
        </v-main>

        <confirm-dialog />
        <document-dialog />
        <snack-bar />
        <loading-dialog />
    </v-app>
</template>

<script>
    import navDrawer from "../components/navDrawer";
    import axios from 'axios';

    export default {
        title: "Home",
        components: {
            navDrawer,
        },
        computed: {
            user(){
                return this.$store.getters.user;
            },
            modules(){
                return this.$store.getters.modules;
            }
        },
        data(){
            return {
                drawer: false,
                terminalFilesCount: [],
            };
        },
        methods: {
            viewLink(link, params) {
                this.$router.push({name: link, params: params});
            },
            logout() {
                const vm = this;
                this.$store.dispatch('logout').then(function() {
                    vm.$router.push({name: 'login'});
                });
            },
            getTerminalCount(terminalID){
                let count = 0;
                this.terminalFilesCount.forEach(element => {
                    if(element.TerminalID == terminalID)
                    {
                        count = element.TotalFiles;
                        return;
                    }
                });
                return count;
            },
            async onTerminalAssigned(){
                this.$loadingDialog.show();

                await axios.post('Utilities/GetTerminalFilesCount', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Data;

                        if(status)
                            this.terminalFilesCount = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
        },
        created() {
            this.onTerminalAssigned();
        }
    }
</script>

<style scoped>

</style>