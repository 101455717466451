<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Clients</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="2">
                <v-btn
                        @click="newClient"
                        class="green py-4"
                        dark
                        small
                        block
                        v-if="rights.allowAdd">
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New Client</span>
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-text>
                        <h4>Total Records: {{ clients.pagination.totalRecords }}</h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="9">
                <v-btn
                        color="red darken-4"
                        small
                        class="mr-1 white--text"
                        @click="deleteClient"
                        v-if="rights.allowDelete"
                        :disabled="clients.selected.length == 0">
                    Delete
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text"
                        @click="updateStatus(true)"
                        v-if="rights.allowEdit"
                        :disabled="clients.selected.length == 0" >
                    Mark As Active
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="updateStatus(false)"
                        v-if="rights.allowEdit"
                        :disabled="clients.selected.length == 0" >
                    Mark As InActive
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" align="end">
                <v-btn
                        color="primary"
                        small
                        class="white--text"
                        @click="exportToExcel">
                    Export To Excel
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="ml-1 white--text">
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="clients.selected"
                        :headers="clients.headers"
                        :items="clients.data"
                        item-key="CustomerID"
                        :loading="clients.loading"
                        loading-text="Loading Clients, please wait..."
                        :page.sync="clients.pagination.page"
                        :items-per-page="clients.pagination.rowsPerPage"
                        class="elevation-1"
                        disable-sort
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!clients.loading">
                        <tr>
                            <td><v-icon>fa-filter</v-icon></td>
                            <td v-for="header in clients.headers" :key="header.value">
                                <v-text-field
                                        v-model="clients.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.IsActive="{ item }">
                        <v-icon>{{ item.IsActive ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                    </template>
                    <template v-slot:item.Actions="{ item }">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="viewFile(false, item.ItemID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-search</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Files</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="editClient(item.CustomerID)" dense
                                             v-if="rights.allowEdit">
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="sendCredentials(item.CustomerID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>SMS Credentials</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="deleteSingle(item)" dense
                                             v-if="rights.allowDelete">
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-trash</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Delete</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>

                                <v-list-item @click="viewSMS(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Send SMS</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="clients.pagination.page" :length="clients.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "Clients",
        data(){
          return {
              clients: {
                  headers: [
                      { text: 'S.No', value: 'SNo', applyFilter: false },
                      { text: 'Customer No', value: 'CustomerNo', applyFilter: true },
                      { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                      { text: 'Contact Person', value: 'ContactPerson', applyFilter: true },
                      { text: 'Email', value: 'Email', applyFilter: true },
                      { text: 'Mobile', value: 'Mobile', applyFilter: true },
                      { text: 'Active Files', value: 'ActiveFiles', applyFilter: false },
                      { text: 'Cleared Files', value: 'ClearedFiles', applyFilter: false },
                      { text: 'No Of FCL', value: 'TotalContainers', applyFilter: false },
                      { text: 'Last Clearing Date', value: 'LastClearingDate', applyFilter: true },
                      { text: 'Customer Since', value: 'CreateDate', applyFilter: true },
                      { text: 'Active', value: 'IsActive', applyFilter: false },
                      { text: 'Action', value: 'Actions', applyFilter: false },
                  ],
                  data: [],
                  filters: {},
                  selected: [],
                  pagination: {
                      page: 1,
                      rowsPerPage: 25,
                      totalPages: 1,
                      totalRecords: 0,
                  },
                  loading: true,
              },
              message: {
                  CustomerID: 0,
                  Message: '',
              },
              rights: {
                  allowAdd: this.verifyRights(this.$route.name, 'A', 0),
                  allowEdit: this.verifyRights(this.$route.name, 'E', 0),
                  allowDelete: this.verifyRights(this.$route.name, 'D', 0),
              }
          };
        },
        computed: {
            selectedClients(){
                let selected = [];
                this.clients.selected.forEach(user => {
                    selected.push(user.CustomerID);
                });
                return selected;
            }
        },
        methods: {
            getData(){
                this.clients.loading = true;

                const formData = {
                    CustomerID: 0,
                    PageNo: this.clients.pagination.page,
                    NoOfRows: this.clients.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.clients.filters).length,
                    Filters: this.clients.filters,
                };

                axios.post('Customers/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.clients.pagination.totalPages = data.Data.TotalPages;
                            this.clients.pagination.totalRecords = data.Data.TotalRecords;
                            this.clients.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }

                        this.clients.loading = false;
                    })
                    // eslint-disable-next-line no-unused-vars
                    .catch(error => {
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                        this.clients.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.clients.filters[field] = value;
                else
                    delete this.clients.filters[field];

                this.getData();
            },
            newClient(){
                this.$router.push({name: 'clientsAddEdit', params: {"action": "new"}});
            },
            editClient(customerID){
                this.$router.push({name: 'clientsAddEdit', params: {"action": "edit"}, query: {id: customerID}});
            },
            updateStatus(status){
                this.$loadingDialog.show();

                const formData = {
                    UpdateCustomerIDs: this.selectedClients,
                    Status: status
                };

                axios.post('Customers/UpdateStatus', formData)
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success) {
                            this.getData();
                            this.clients.selected = [];
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                    })
                    .catch( error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'An unexpected error has occurred.'});
                    })
            },
            deleteSingle(client){
                this.clients.selected.push(client);
                this.deleteClient();
            },
            deleteClient() {
                console.log('Delete Client');
                this.$confirmDialog.show({
                    title: 'Delete Client',
                    message: 'Are you sure you want to delete the selected client(s)? This will delete all associated files with the client and this action cannot be undone.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            CustomerIDs: this.selectedClients,
                        };

                        axios.post('Customers/Delete', formData)
                            .then(response => {
                                const data = response.data;
                                let success = data.Status;

                                if(success) {
                                    this.getData();
                                    this.clients.selected = [];
                                }

                                this.$loadingDialog.hide();
                                this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                            })
                            .catch( error => {
                                console.log(error);
                                this.$loadingDialog.hide();
                                this.$snackBar.show({color: 'error', message: 'An unexpected error has occurred.'});
                            })                    }
                });
            },
            async sendCredentials(customerID) {
                this.$loadingDialog.show();

                await axios.post('Customers/SendCredentialsSMS', {CustomerIDs: [customerID]})
                    .then(response => {
                        const data = response.data;
                        let success = data.Status;

                        if(success)
                            this.clients.selected = [];

                        this.$snackBar.show({color: success ? 'success' : 'error', message: data.Message});
                    })
                    .catch( error => {
                        console.log(error);
                        this.$snackBar.show({color: 'error', message: 'An unexpected error has occurred.'});
                    })

                this.$loadingDialog.hide();
            },
            viewSMS(item){
                this.message = {
                    CustomerID: item.CustomerID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(0, this.message.CustomerID, this.message.Message);
                this.$loadingDialog.hide();
            },
            async exportToExcel(){
                const formData = {
                    CustomerID: 0,
                    PageNo: 1,
                    NoOfRows: 99999999,
                    HasFilter: !!Object.keys(this.clients.filters).length,
                    Filters: this.clients.filters,
                    Fields: [
                      'CustomerNo',
                      'CompanyName',
                      'ContactPerson',
                      'Email',
                      'Mobile',
                      'ActiveFiles',
                      'ClearedFiles',
                      'TotalContainers',
                      'LastClearingDate',
                      'CreateDate',
                      'IsActive',
                    ],
                };

                await this.excelExport('Customers/ExportToExcel', formData, 'Customers.xlsx');

            }
        },
        created() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>