<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Bulk SMS</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-title>New Message</v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col md="4" offset-md="2">
                                <v-textarea
                                        v-model="message.Message"
                                        label="Message"
                                        outlined
                                        class="elevation-1"
                                        background-color="white"
                                        dense
                                        hide-details>
                                </v-textarea>
                                <span class="red--text mt-1"><b>{{ totalCharacters }}</b> Characters</span>
                            </v-col>
                            <v-col md="4">
                                <v-select
                                        v-model="message.FileStatusID"
                                        label="File Status"
                                        outlined
                                        :items="[{Text: 'All', Value: 0}, {Text: 'Active', Value: 1}, {Text: 'Cleared', Value: 2}]"
                                        item-text="Text"
                                        item-value="Value"
                                        class="elevation-1"
                                        background-color="white"
                                        dense
                                        hide-details>
                                </v-select>
                                <v-select
                                        v-model="message.FileStageID"
                                        label="Stage"
                                        outlined
                                        :items="stages"
                                        item-text="Stage"
                                        item-value="StageID"
                                        class="elevation-1 mt-2"
                                        background-color="white"
                                        dense
                                        hide-details>
                                </v-select>
                                <v-checkbox v-model="message.HasClearingDateFilter" label="Filter Clearing Date" hide-details dense class="my-2">
                                </v-checkbox>
                                <v-row dense>
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="message.FromClearingDate"
                                                label="From Clearing Date"
                                                outlined
                                                class="elevation-1 mt-2"
                                                background-color="white"
                                                type="date"
                                                dense
                                                :disabled="!message.HasClearingDateFilter"
                                                hide-details>
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field
                                                v-model="message.ToClearingDate"
                                                label="To Clearing Date"
                                                outlined
                                                class="elevation-1 mt-2"
                                                background-color="white"
                                                type="date"
                                                dense
                                                :disabled="!message.HasClearingDateFilter"
                                                hide-details>
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="white--text" @click="sendMessage">
                            Send Message
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12">
                <v-card>
                    <v-card-title>All Messages</v-card-title>
                    <v-card-text>
                        <v-list dense>
                            <v-list-item dense v-for="message in messages" :key="message.SmsID">
                                <v-list-item-content>
                                    <v-list-item-title>{{ message.Message }}</v-list-item-title>
                                    <v-list-item-subtitle>Message Date: {{ message.CreateDate }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>File Status: {{ message.FileStatus }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>File Stage: {{ message.FileStage }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>From Clearing Date: {{ message.HasClearingDateFilter ? 'All' : message.FromClearingDate }} To Clearing Date: {{ message.HasClearingDateFilter ? 'All' : message.ToClearingDate }}</v-list-item-subtitle>
                                    <v-list-item-title>Total Recipients: {{ message.NoOfRecipients }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider dark></v-divider>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import moment from 'moment';

    export default {
        name: "bulkSms",
        data(){
            return {
                stages: [],
                message: {
                    Message: '',
                    FileStatusID: 0,
                    FileStageID: 0,
                    HasClearingDateFilter: false,
                    FromClearingDate: new moment(new Date()).format('YYYY-MM-DD'),
                    ToClearingDate: new moment(new Date()).format('YYYY-MM-DD'),
                },
                messages: [],
            };
        },
        computed: {
            totalCharacters(){
                return this.message.Message.length;
            }
        },
        methods: {
            async utilities(option) {
                await axios.post('SMS/Utilities', {Option: option})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        console.log(data);

                        this.stages = status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            async getMessages() {
                await axios.post('SMS/GetMessages')
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.messages = status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            async sendMessage() {
                if(this.message.Message.length == 0)
                {
                    this.$snackBar.error({message: 'Cannot send a blank message'});
                    return;
                }

                let vm = this;

                this.$confirmDialog.show({
                   title: 'Send Message',
                   message: 'Are you sure you want to send this message to the selected clients? Please confirm.',
                   async onConfirm(){
                       this.$loadingDialog.show();

                       console.log(vm.message);

                       await axios.post('SMS/SendMessages', vm.message)
                           .then(response => {
                               const data = response.data;
                               const status = data.Status;

                               if(status)
                                   vm.getMessages();

                               this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                           .catch(error => {
                               console.log(error);
                               this.$snackBar.error({message: 'An unexpected error has occurred'});
                           });

                       this.$loadingDialog.hide();
                   },
                });
            },
        },
        async created() {
            this.$loadingDialog.show();
            await this.utilities(1);
            await this.getMessages();
            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>