<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Warehouse</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4>Total Records: {{ files.totals.TotalRecords }}</h4>
                    </v-card-text>
                </v-card>
            </v-col>
<!--            <v-col cols="12" md="6">-->
<!--                <v-card>-->
<!--                    <v-card-text class="d-flex">-->
<!--                        <h4>Total Containers: 4</h4>-->
<!--                        <h4 class="ml-2">Total Value: 100,000</h4>-->
<!--                        <h4 class="ml-2">Total Balance Stock: 20,000</h4>-->
<!--                    </v-card-text>-->
<!--                </v-card>-->
<!--            </v-col>-->
        </v-row>
        <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" align="end">
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text" >
                    Export To Excel
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text" >
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td v-for="(header, i) in files.headers" :key="header.value">
                                <template v-if="i == 0">
                                    <v-icon>fa-filter</v-icon>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            v-model="files.filters[header.value]"
                                            class="small-text-field my-1"
                                            background-color="white"
                                            outlined
                                            dense
                                            hide-details
                                            autcomplete="disable"
                                            @change="customFilter($event, header.value)"
                                            v-if="header.applyFilter && !header.value.includes('Date')">
                                    </v-text-field>
                                    <v-menu
                                            offset-y
                                            transition="scale-transition"
                                            :close-on-content-click="true"
                                            v-if="header.applyFilter && header.value.includes('Date')">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="files.filters[header.value]"
                                                    class="small-text-field my-1"
                                                    background-color="white"
                                                    outlined
                                                    dense
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="files.filters[header.value]"
                                                @change="customFilter($event, header.value)">
                                            <v-spacer></v-spacer>
                                            <v-btn color="grey" class="white--text" small
                                                   @click="customFilter('', header.value)">Clear</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.Value="{item}">
                        {{ item.ShortName + ' ' + item.Value }}
                    </template>
                    <template v-slot:item.Actions="{item}">
                        <v-btn
                                small
                                color="primary"
                                class="white--text my-1"
                                @click="showDispatch(item)">
                            Dispatch
                        </v-btn>
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small
                                        v-if="rights.allowEdit">
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item
                                        @click="editWarehouse(item)"
                                        dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                            </v-list>
                            <v-list dense>
                                <v-list-item
                                        @click="viewClearing(item)"
                                        dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit Estimated Clearing Date</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalItems" total-visible="20"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgWarehouse"
                title="Edit Warehouse Transaction"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveWarehouse">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.FileNo"
                                label="File No"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.FKWarehouseID"
                                label="Warehouse"
                                :items="warehouses"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.Quantity"
                                label="Quantity"
                                type="number"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.Unit"
                                label="Unit"
                                :items="units"
                                item-text="TextLine"
                                item-value="TextLine"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="warehouse.Value"
                                label="Total Value"
                                type="number"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="warehouse.FKCurrencyID"
                                label="Currency"
                                :items="currencies"
                                item-text="ShortName"
                                item-value="CurrencyID"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDispatch"
                title="Dispatch"
                confirm-button-text=""
                cancel-button-text="Close"
                :max-width="900">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="2">
                        <v-text-field
                                v-model="selectedWarehouse.CreateDate"
                                label="Entry Date"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                                v-model="selectedWarehouse.BalanceQuantity"
                                label="Total Qty"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                                v-model="selectedWarehouse.Unit"
                                label="Unit"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                                v-model="selectedWarehouse.Value"
                                label="Total Value"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                        <v-text-field
                                v-model="selectedWarehouse.ShortName"
                                label="Currency"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <hr class="my-2"/>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        v-model="dispatch.DispatchDate"
                                        label="Dispatch Date"
                                        class="small-text-field"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    v-model="dispatch.DispatchDate">
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="dispatch.Quantity"
                                label="Quantity"
                                outlined
                                dense
                                class="small-text-field"
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                        <v-btn small block color="green" class="white--text py-4" @click="saveDispatch">
                            <v-icon>fa-plus-circle</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-data-table
                                :headers="history.header"
                                :items="history.data"
                                :loading="history.loading"
                                loading-text="Loading History, please wait..."
                                class="elevation-1"
                                disable-pagination
                                dense
                                hide-default-footer>
                        </v-data-table>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgEstimatedClearing"
                title="Estimated Clearing Date"
                confirm-button-text="Save"
                cancel-button-text="Close"
                max-width="500"
                :on-confirm="saveClearing">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <date-picker label="Estimated Clearing Date" v-model="clearing.EstimatedClearingDate" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import moment from 'moment';

    export default {
        title: "Warehouses",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S. No', value: 'SNo' },
                        { text: 'TR ID', value: 'TRID', applyFilter: true },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'Product Description', value: 'ProductDescription', applyFilter: true },
                        { text: 'Warehouse', value: 'Warehouse', applyFilter: true },
                        { text: 'Containers', value: 'FCL', applyFilter: true },
                        { text: 'Entry Date', value: 'CreateDate', applyFilter: true },
                        { text: 'Balance Qty', value: 'BalanceQuantity' },
                        { text: 'Unit', value: 'Unit' },
                        { text: 'Value', value: 'Value' },
                        { text: 'Actions', value: 'Actions' },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: false,
                },
                selectedWarehouse: {
                    entryDate: '03-Nov-2020',
                    totalQty: 0,
                    unit: 'Cnts',
                    totalValue: 0,
                    currency: 'USD',
                },
                warehouse: {
                    FileID: 0,
                    FileNo: '',
                    FKWarehouseID: 0,
                    FKCurrencyID: 0,
                    Quantity: 0,
                    Unit: '',
                    Value: 0,
                },
                history: {
                    header: [
                        { text: 'Date', value: 'DispatchDate' },
                        { text: 'Quantity', value: 'Quantity' },
                    ],
                    data: [],
                },
                dispatch: {
                    TRID: 0,
                    DispatchDate: '',
                    Quantity: 0,
                },
                warehouses: [],
                units: [],
                currencies: [],
                clearing: {
                    FileID: 0,
                    EstimatedClearingDate: '',
                },
                rights: {
                    allowEdit: this.verifyRights(this.$route.name, 'E', 0),
                }
            };
        },
        methods: {
            customFilter(value, field){
                if( value )
                    this.table.filters[field] = value;
                else
                    delete this.table.filters[field];

                this.getData();
            },
            async getData(){
                this.$loadingDialog.show();

                const formData = {
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    Filters: this.files.filters,
                };

                await axios.post('Warehouses/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            editWarehouse(warehouse){
                this.warehouse= {
                    TRID: warehouse.TRID,
                    FileID: warehouse.FKFileID,
                    FileNo: warehouse.FileNo,
                    FKWarehouseID: warehouse.FKWarehouseID,
                    FKCurrencyID: warehouse.FKCurrencyID,
                    Quantity: warehouse.Quantity,
                    Unit: warehouse.Unit,
                    Value: warehouse.Value,
                };

                this.$refs.dlgWarehouse.show();
            },
            saveWarehouse() {
                this.$loadingDialog.show();

                axios.post('Warehouses/Update', this.warehouse)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.getData();
                        }
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            getDispatch(TRID) {
                return new Promise((resolve, reject) => {
                    axios.post('Warehouses/GetDispatch', {TRID: TRID})
                        .then(response => {
                            const data = response.data;

                            if(data.Status) {
                                this.history.data = data.Data;
                            }
                            else
                                this.$snackBar.error({message: data.Message});

                            resolve(response.data);
                        })
                        .catch(error => {
                            console.log(error);
                            this.$snackBar.error({message: 'Unexpected error has occurred.'});
                            reject(error);
                        });
                });
            },
            showDispatch(warehouse){
                this.selectedWarehouse = warehouse;
                this.dispatch = {
                    TRID: this.selectedWarehouse.TRID,
                    DispatchDate: moment(new Date()).format('Y-M-D'),
                    Quantity: this.selectedWarehouse.BalanceQuantity,
                }

                this.getDispatch(this.selectedWarehouse.TRID).then(() => {
                    this.$refs.dlgDispatch.show();
                });
            },
            async saveDispatch(){
                this.$loadingDialog.show();

                await axios.post('Warehouses/Dispatch', this.dispatch)
                    .then(async response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            await this.getDispatch(this.selectedWarehouse.TRID);
                            await this.getData();
                        }

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
            viewClearing(file){
                this.clearing = {
                    FileID: file.FKFileID,
                    EstimatedClearingDate: file.EstimatedClearingDate,
                };

                this.$refs.dlgEstimatedClearing.show();
            },
            async saveClearing(){
                this.$loadingDialog.show();
                await this.SaveEstimatedClearing(this.clearing.FileID, this.clearing.EstimatedClearingDate);
                await this.getData();
                this.$loadingDialog.hide();
            },
        },
        async created() {
            await this.getData();

            this.$loadingDialog.show();

            await this.getGeneral(14, 0)
                .then(response => {
                    this.warehouses = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                })

            await this.getGeneral(15, 0)
                .then(response => {
                    this.units = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                })

            await this.getCurrencies()
                .then(response => {
                    this.currencies = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                })

            console.log(this.rights);

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>