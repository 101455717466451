<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Transporters Settings</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="5">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Companies
                        <v-spacer></v-spacer>
                        <v-btn
                                @click="newCompany"
                                class="green py-4"
                                dark
                                small>
                            <v-icon left>mdi-plus-circle</v-icon>
                            <span>New Company</span>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <v-list-item
                                    v-for="transport in transporters"
                                    :key="transport.TransporterID"
                                    dense
                                    :class="transport.TransporterID == (transporter == null ? 0 : transporter.TransporterID) ? 'grey lighten-3 elevation-1' : ''"
                                    style="border-radius: 4px;"
                                    @click="transporter = transport">
                                <v-list-item-content>
                                    <v-list-item-title>{{ transport.Transporter }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ transport.ContactPerson }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Email: {{ transport.Email }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>Phone: <b>{{ transport.Phone }}</b> Address: <b>{{ transport.Address }}</b></v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-icon><v-btn icon @click="editCompany(transport)"><v-icon>fa-pencil</v-icon></v-btn></v-list-item-icon>
                                <v-list-item-icon><v-btn icon><v-icon>fa-trash</v-icon></v-btn></v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col cols="12" md="7">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Drivers
                        <v-spacer></v-spacer>
                        <v-btn
                                @click="newDriver"
                                class="green py-4"
                                dark
                                small
                                :disabled="transporter == null">
                            <v-icon left>mdi-plus-circle</v-icon>
                            <span>Add Driver</span>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <template v-if="transporter != null">
                                <v-list-item v-for="driver in transporter.Drivers" :key="driver.DriverID" dense>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ driver.Driver }}</v-list-item-title>
                                        <v-list-item-subtitle>Mobile: {{ driver.Mobile }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>License: {{ driver.License }}</v-list-item-subtitle>
                                        <v-list-item-subtitle>Vehicle No: <b>{{ driver.VehicleNo }}</b> Vehicle Type: <b>{{ driver.VehicleType }}</b></v-list-item-subtitle>
                                        <v-list-item-subtitle>Capacity: {{ driver.Capacity }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon><v-btn icon @click="editDriver(driver)"><v-icon>fa-pencil</v-icon></v-btn></v-list-item-icon>
                                    <v-list-item-icon><v-btn icon><v-icon>fa-trash</v-icon></v-btn></v-list-item-icon>
                                </v-list-item>
                            </template>
                            <v-list-item v-else>
                                <v-list-item-title>No Transporter Selected</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgCompany"
                title="Transport Company"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveTransporter">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="transporter.Transporter"
                                label="Company Name"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="transporter.ContactPerson"
                                label="Contact Person"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="transporter.Email"
                                label="Email"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="transporter.Phone"
                                label="Phone"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="transporter.Address"
                                label="Address"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDriver"
                title="Driver"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveDriver">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="driver.Driver"
                                label="Driver Name"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="driver.Mobile"
                                label="Mobile"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="driver.License"
                                label="Driver License"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="driver.VehicleNo"
                                label="Vehicle Registration No"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="driver.VehicleType"
                                :items="['Truck']"
                                label="Vehicle Type"
                                solo
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                v-model="driver.Capacity"
                                label="Vehicle Capacity"
                                :items="fclTypes"
                                item-text="TextLine"
                                item-value="TextLine"
                                solo
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                                v-model="driver.IsActive"
                                label="Active"
                                solo
                                dense
                                hide-details="auto">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Transporters Settings",
        data(){
            return {
                transporters: [],
                transporter: null,
                driver: {
                    DriverID: 0,
                    Driver: '',
                    Mobile: '',
                    License: '',
                    VehicleNo: '',
                    VehicleType: '',
                    Capacity: '',
                    IsActive: true,
                },
                fclTypes: [],
            };
        },
        methods: {
            getData(){
                this.$loadingDialog.show();

                axios.post('Transporters/GetData', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.transporters = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            newCompany(){
                this.transporter = {
                    TransporterID: 0,
                    Transporter: '',
                    ContactPerson: '',
                    Phone: '',
                    Email: '',
                    Address: '',
                    Drivers: [],
                };

                this.$refs.dlgCompany.show();
            },
            editCompany(transport){
                this.transporter = transport;
                this.$refs.dlgCompany.show();
            },
            newDriver(){
                this.driver = {
                    DriverID: 0,
                    Driver: '',
                    Mobile: '',
                    License: '',
                    VehicleNo: '',
                    VehicleType: '',
                    Capacity: '',
                    IsActive: true,
                }
                this.$refs.dlgDriver.show();
            },
            editDriver(driver){
                this.driver = driver;
                this.$refs.dlgDriver.show();
            },
            async saveTransporter() {
                this.$loadingDialog.show();

                const formData = {
                    Transporter: this.transporter
                };

                await axios.post('Transporters/AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error',
                            message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
            async saveDriver() {
                this.$loadingDialog.show();

                const formData = {
                    TransporterID: this.transporter.TransporterID,
                    Driver: this.driver,
                };

                await axios.post('Transporters/AddEditDriver', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.transporter.Drivers.push(this.driver);

                        this.$snackBar.show({color: status ? 'success' : 'error',
                            message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            },
        },
        async created() {
            this.$loadingDialog.show();

            await this.getGeneral(6, 0)
                .then(response => {
                    this.fclTypes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.getData();
        }
    }
</script>

<style scoped>

    .card-scroll {
        height: 70vh;
    }

</style>