<template>
    <v-container fluid>
        <v-row dense>
            <v-col>
                <h2 class="heading">General Settings</h2>
            </v-col>
            <v-col align="end">
                <v-btn color="red darken-2" class="white--text" @click="resetData">Reset Data</v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="6">
                <v-card>
                    <v-card-title>
                        Dynamic Data
                        <v-spacer></v-spacer>
                        <v-btn
                                color="green"
                                class="white--text"
                                small
                                :disabled="selected == 0"
                                @click="addGeneral">
                            <v-icon left>fa-plus-circle</v-icon> Add Item
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row dense>
                            <v-col cols="12" md="6">
                                <v-list dense>
                                    <v-list-item
                                            v-for="item in generalParents"
                                            :key="item.Value01"
                                            @click="getGeneralItems(item.Value01, item.TextLine)"
                                            style="border-radius: 4px;"
                                            :class="selected == item.Value01 ? 'grey lighten-2 elevation-1' : ''">
                                        <v-list-item-title>{{ item.TextLine }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-list dense>
                                    <v-list-item
                                            v-for="item in generalItems"
                                            :key="item.Value01">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.TextLine }}</v-list-item-title>
                                            <v-list-item-subtitle v-if="selected == 4">Free Days: {{ item.Value02 }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="selected == 4">Website: <a :href="item.Text01" target="_blank">{{ item.Text01 }}</a></v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="selected == 11">Req: {{ item.Text01 }}</v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="selected == 16">Color: {{ item.Text01 }}</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-icon>
                                            <v-btn small icon @click="editGeneral(item)"><v-icon small>fa-pencil</v-icon></v-btn>
                                        </v-list-item-icon>
                                        <v-list-item-icon>
                                            <v-btn small icon @click="deleteGeneral(item)"><v-icon small>fa-trash</v-icon></v-btn>
                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card>
                    <v-card-title>ID Settings</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="settings.FILE_NO"
                                label="Initial File No"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.CUSTOMER_NO"
                                label="Initial Customer No"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="updateSettings(['FILE_NO', 'CUSTOMER_NO'])">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mt-2">
                    <v-card-title>ETA Settings</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="settings.ETA_UPDATE_DAYS"
                                label="Max ETA Update Days"
                                class="small-text-field"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.ETA_CLEARING_DAYS"
                                label="Max ETA Clearing Days"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                hide-details>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="updateSettings(['ETA_UPDATE_DAYS', 'ETA_CLEARING_DAYS'])">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>                <v-card class="mt-2">
                    <v-card-title>Containers-In-Progress</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="settings.CIP_Red"
                                prefix="Red <= "
                                class="small-text-field"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.CIP_Yellow"
                                prefix="Yellow <= "
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.CIP_Green"
                                prefix="Green >= "
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                hide-details>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="updateSettings(['CIP_Green', 'CIP_Yellow', 'CIP_Red'])">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="3">
                <v-card>
                    <v-card-title>SMS Settings</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="settings.SMS_APIURL"
                                label="API Url"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.SMS_APIUSER"
                                label="API Username"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.SMS_APIPASS"
                                label="API Password"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="updateSettings(['SMS_APIURL', 'SMS_APIUSER', 'SMS_APIPASS'])">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mt-2">
                    <v-card-title>Email Settings</v-card-title>
                    <v-card-text>
                        <v-text-field
                                v-model="settings.EMAIL_SERVERURL"
                                label="Mail Server"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.EMAIL_SENDERNAME"
                                label="Sender Name"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.EMAIL_SENDERADDRESS"
                                label="Email Address"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                        <v-text-field
                                v-model="settings.EMAIL_SENDERPASSWORD"
                                label="Password"
                                class="small-text-field mt-2"
                                background-color="grey lighten-4"
                                outlined
                                dense
                                hide-details>
                        </v-text-field>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="4">
                <v-card>
                    <v-card-title>Banner Settings</v-card-title>
                    <v-card-text>
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                color="primary"
                                class="white--text">
                            Upload Login Banner
                        </v-btn>
                        <input type="file" accept="image/jpeg" ref="imgUpload" style="display: none;" @change="previewImage($event, 'loginBanner')" />
                        <v-img :src="banners.loginBanner.url" class="mt-2"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="uploadBanner('loginBanner')">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card>
                    <v-card-title>Banner Settings</v-card-title>
                    <v-card-text>
                        <v-btn
                                @click="$refs.imgUpload02.click()"
                                color="primary"
                                class="white--text">
                            Upload Home Banner
                        </v-btn>
                        <input type="file" accept="image/jpeg" ref="imgUpload02" style="display: none;" @change="previewImage($event, 'clientBanner01')" />
                        <v-img :src="banners.clientBanner01.url" class="mt-2"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="uploadBanner('clientBanner01')">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="4">
                <v-card>
                    <v-card-title>Banner Settings</v-card-title>
                    <v-card-text>
                        <v-btn
                                @click="$refs.imgUpload03.click()"
                                color="primary"
                                class="white--text">
                            Upload File Banner
                        </v-btn>
                        <input type="file" accept="image/jpeg" ref="imgUpload03" style="display: none;" @change="previewImage($event, 'clientBanner02')" />
                        <v-img :src="banners.clientBanner02.url" class="mt-2"></v-img>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                small
                                color="green"
                                class="white--text"
                                @click="uploadBanner('clientBanner02')">
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgGeneral"
                :title="selectedName"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveGeneral">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="general.TextLine"
                                label="Item Description"
                                solo
                                dense
                                autofocus
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="selected == 4">
                        <v-text-field
                                v-model="general.Value02"
                                :label="selected == 4 ? 'Free Days': ''"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="selected == 16 || selected == 4">
                        <v-text-field
                                v-model="general.Text01"
                                :label="selected == 4 ? 'Website': 'Color'"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="selected == 11">
                        <v-select
                                v-model="selectedRequirements"
                                label="Requirements"
                                :items="productRequirements"
                                item-text="TextLine"
                                item-value="TextLine"
                                solo
                                dense
                                chips
                                multiple
                                @change="updateRequirements($event)"
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "General Settings",
        data() {
            return {
                settings: {},
                generalParents: [],
                generalItems: [],
                selected: 0,
                selectedName: '',
                general: {
                    TypeID: 0,
                    ParentID: 0,
                    TextLine: '',
                    Value01: 0,
                    Value02: 0,
                    Text01: '',
                    Text02: '',
                    Numeric01: 0,
                    Numeric02: 0,
                },
                selectedRequirements: [],
                productRequirements: [],
                banners: {
                    loginBanner: {
                        field: "LoginBanner",
                        picture: null,
                        url: "",
                        name: "",
                        size: "",
                        error: "",
                    },
                    clientBanner01: {
                        field: "ClientBanner01",
                        picture: null,
                        url: "",
                        name: "",
                        size: "",
                        error: "",
                    },
                    clientBanner02: {
                        field: "ClientBanner02",
                        picture: null,
                        url: "",
                        name: "",
                        size: "",
                        error: "",
                    },
                },
            };
        },
        methods: {
            async getSettings(){
                this.$loadingDialog.show();

                await axios.post('General/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.settings = data.Data;
                            this.banners.loginBanner.url = this.settings.LoginBanner;
                            this.banners.clientBanner01.url = this.settings.ClientBanner01;
                            this.banners.clientBanner02.url = this.settings.ClientBanner02;
                        }
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            updateSettings(fields){
                this.$loadingDialog.show();

                let formData = {
                    Fields: {},
                };

                fields.forEach(element => {
                    formData.Fields[element] = this.settings[element]
                });

                axios.post('General/UpdateSettings', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getSettings();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            getGeneralItems(typeID, name){
                this.$loadingDialog.show();
                this.selected = typeID;
                this.selectedName = name;

                if(typeID == 11)
                {
                    this.$loadingDialog.show();
                    this.getGeneral(10, 0)
                        .then(response => {
                            const status = response.Status;

                            if(status)
                                this.productRequirements = response.Data;
                            else
                                this.$snackBar.error({message: response.Message});

                            this.$loadingDialog.hide();
                        })
                        .catch(() => {
                            this.$snackBar.error({message: 'An unexpected error has occurred'});
                            this.$loadingDialog.hide();
                        });
                }

                this.getGeneral(typeID, 0)
                    .then(response => {
                        const status = response.Status;

                        if(status)
                            this.generalItems = response.Data;
                        else
                            this.$snackBar.error({message: response.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            updateRequirements(reqs){
                this.general.Text01 = reqs.filter(Boolean).join();
            },
            addGeneral() {
                this.general = {
                    TypeID: this.selected,
                    ParentID: 0,
                    TextLine: '',
                    Value01: 0,
                    Value02: 0,
                    Text01: '',
                    Text02: '',
                    Numeric01: 0,
                    Numeric02: 0,
                }

                if(this.selected == 11)
                {
                    this.selectedRequirements = [];
                }

                this.$refs.dlgGeneral.show();
            },
            editGeneral(general) {
                this.general = {
                    TypeID: this.selected,
                    ParentID: general.ParentID,
                    TextLine: general.TextLine,
                    Value01: general.Value01,
                    Value02: general.Value02,
                    Text01: general.Text01,
                    Text02: general.Text02,
                    Numeric01: general.Numeric01,
                    Numeric02: general.Numeric02,
                };

                if(this.selected == 11)
                {
                    this.selectedRequirements = general.Text01.split(',');
                }

                this.$refs.dlgGeneral.show();
            },
            saveGeneral() {
                this.$loadingDialog.show();

                const formData = {
                    General: this.general,
                };

                console.log(formData);

                axios.post('General/AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.$snackBar.success({message: data.Message});
                            this.getGeneralItems(this.selected);
                        }
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            async deleteGeneral(item){
                const vm = this;

                this.$confirmDialog.show({
                    title: 'Delete Item',
                    message: 'Are you sure you want to delete this item? Please confirm.',
                    async onConfirm(){
                        vm.$loadingDialog.show();

                        const formData = {
                            TypeID: item.TypeID,
                            Value01: item.Value01,
                        };

                        await axios.post('General/Delete', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    vm.getGeneralItems(vm.selected);

                                vm.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                vm.$snackBar.error({message: 'An unexpected error has occurred'});
                            });

                        this.$loadingDialog.hide();
                    }
                })
            },
            async resetData(){
                this.$confirmDialog.show({
                    title: 'RESET DATA',
                    message: 'Are you sure you want to delete all Customers and Files from the data?\nPlease make a backup before performing this action. This action cannot be undone!',
                    async onConfirm(){
                        this.$loadingDialog.show();

                        await axios.post('Utilities/ResetData', {})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error({message: 'An unexpected error has occurred'});
                            });

                        this.$loadingDialog.hide();
                    }
                });
            },
            previewImage(e, field){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.loginBanner.error = "Picture size is exceeding the limit. Please select a smaller sized picture.";
                    return;
                }

                this.banners[field].picture = file;

                this.banners[field].url = URL.createObjectURL(file);
                this.banners[field].name = file.name;
                this.banners[field].size = size;
                this.banners[field].error = "";
            },
            async uploadBanner(field){
                this.$loadingDialog.show();

                let formData = new FormData();
                formData.append('BannerField', this.banners[field].field);
                formData.append('File', this.banners[field].picture);

                await axios.post('General/UpdateLoginBanner', formData,
                    { headers: {'Content-Type': 'multipart/form-data'} })
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            }
        },
        created() {
            this.getSettings();

            this.$loadingDialog.show();
            this.getGeneral(0, 0)
                .then(response => {
                    const status = response.Status;

                    if(status)
                        this.generalParents = response.Data;
                    else
                        this.$snackBar.error({message: response.Message});

                    this.$loadingDialog.hide();
                })
                .catch(() => {
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                    this.$loadingDialog.hide();
                });

        }
    }
</script>

<style scoped>

</style>