import axios from 'axios';

String.prototype.contains = function(stringToSearch){
    const myString = this.valueOf();
    const pattern = `\\b${stringToSearch}\\b`;
    const result = new RegExp(pattern).test(myString);
    return result;
};

export default {
    data() {
        return {
            months: [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec',
            ],
        };
    },
    methods: {
        toShortDateString(date) {
            let mm = (date.getMonth() + 1).toString();
            let dd = date.getDate().toString();

            return [date.getFullYear(), mm.padStart(2, "0"), dd.padStart(2, "0"), ].join('-');
        },
        toDateString(date) {

            let mm = this.months[date.getMonth()];
            let dd = date.getDate().toString();

            return [dd.padStart(2, "0"), mm, date.getFullYear()].join('-');
        },
        getGeneral(typeID, parentID) {
            return new Promise((resolve, reject) => {
                axios.post('General/GetData', {TypeID: typeID, ParentID: parentID})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCustomer(customerID) {
            return new Promise((resolve, reject) => {
                const formData = {
                    CustomerID: customerID,
                    PageNo: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    Filters: [],
                };

                axios.post('Customers/GetData', formData)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCustomers() {
            return new Promise((resolve, reject) => {
                axios.post('Utilities/GetCustomers', {CustomerID: 0, FileID: 0})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getStages() {
            return new Promise((resolve, reject) => {
                axios.post('Stages/GetData', {Option: 2})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getTerminals() {
            return new Promise((resolve, reject) => {
                axios.post('Terminals/GetData', {})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getTransporters() {
            return new Promise((resolve, reject) => {
                axios.post('Transporters/GetData', {})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getTransporterDrivers(transporterID) {
            return new Promise((resolve, reject) => {
                axios.post('Transporters/GetDrivers', {TransporterID: transporterID})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getCurrencies() {
            return new Promise((resolve, reject) => {
                axios.post('Currencies/GetData', {})
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getFile(fileID, filter) {
            return new Promise((resolve, reject) => {
                const formData = {
                    FileID: fileID,
                    PageNo: 1,
                    NoOfRows: 1,
                    HasFilter: !!Object.keys(filter).length,
                    IsApproval: false,
                    IsActive: false,
                    Filters: filter,
                };

                axios.post('Files/GetData', formData)
                    .then(response => {
                        resolve(response.data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        verifyRights(route, right, linkID){
            const rights = this.$store.getters.rights;
            let hasRights = false;

            rights.forEach(element => {
                if(element.LinkName == route && (linkID == 0 || element.FKLinkID == linkID))
                {
                    hasRights = element.Rights.indexOf(right) >= 0;
                    return;
                }
            });

            return hasRights;
        },
        async excelExport(url, formData, fileName) {
            this.$loadingDialog.show();
            await axios.post(url, formData, { responseType: 'blob' })
                .then(response => {
                    const file = new Blob([response.data], {type: response.headers['content-type']});
                    const fileUrl = URL.createObjectURL(file);
                    let link = document.createElement('a');
                    link.download = fileName;
                    link.href = fileUrl;
                    link.click()
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.error({message: 'An unexpected error has occurred'});
                });

            this.$loadingDialog.hide();
        },
        async SendSMS(fileID, customerID, message){
            return new Promise((resolve, reject) => {
                this.$loadingDialog.show();
                axios.post('Utilities/SendSMS', {FileID: fileID, CustomerID: customerID, Message: message})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        reject(error);
                    });
                this.$loadingDialog.hide();
            });
        },
        async SaveEstimatedClearing(fileID, estimatedClearingDate){
            return new Promise((resolve, reject) => {
                this.$loadingDialog.show();
                axios.post('Files/UpdateEstimatedClearing', {FileID: fileID, EstimatedClearingDate: estimatedClearingDate})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        reject(error);
                    });
                this.$loadingDialog.hide();
            });
        },
        async SaveAssessStatus(fileID, isAccept){
            return new Promise((resolve, reject) => {
                this.$loadingDialog.show();
                axios.post('Files/UpdateAssessStatus', {FileID: fileID, IsAcceptAssessment: isAccept})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        resolve(data);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        reject(error);
                    });
                this.$loadingDialog.hide();
            });
        },
    }
}
