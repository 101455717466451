<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Chart Of Accounts</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                        outlined
                        dense
                        label="Search"
                        name="search"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        class="small-text-field"
                        background-color="white"
                        @keypress.enter="search = $event.target.value">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2">
                <v-btn
                        @click="newAccount"
                        class="green py-4"
                        dark
                        small
                        block
                >
                    <v-icon left>mdi-plus-circle</v-icon>
                    <span>New Account</span>
                </v-btn>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12" md="9">
                <v-btn
                        color="red darken-4"
                        small
                        class="mr-1 white--text">
                    Delete
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text" >
                    Mark As Active
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="white--text" >
                    Mark As InActive
                </v-btn>
            </v-col>

            <v-col cols="12" md="3" align="end">
                <v-btn
                        color="primary"
                        small
                        class="white--text">
                    Export To Excel
                </v-btn>

                <v-btn
                        color="primary"
                        small
                        class="ml-1 white--text">
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>

        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="accounts.selected"
                        :headers="accounts.header"
                        :items="accounts.data"
                        :loading="accounts.loading"
                        loading-text="Loading Accounts, please wait..."
                        class="elevation-1"
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:item.IsActive="{ item }">
                        <v-icon>{{ item.IsActive ? 'fa-check-circle' : 'fa-times-circle' }}</v-icon>
                    </template>
                    <template v-slot:item.Actions="props">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="deleteItem(false, props.item.ItemID)" dense>
                                    <v-list-icon class="mr-2"><v-icon>fa-search</v-icon></v-list-icon>
                                    <v-list-item-title>View Statement</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="editItem(props.item)" dense>
                                    <v-list-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="deleteItem(false, props.item.ItemID)" dense>
                                    <v-list-icon class="mr-2"><v-icon>fa-trash</v-icon></v-list-icon>
                                    <v-list-item-title>Delete</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgAccount"
                title="Account"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                label="Account Title"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                label="Control Account"
                                solo
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-select
                                label="Account Type"
                                solo
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                label="Opening Balance"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    export default {
        title: "Chart Of Accounts",
        data(){
            return {
                accounts: {
                    header: [
                        { text: 'Account Code', value: 'AccountCode', sortable: false },
                        { text: 'Account Title', value: 'Description', sortable: false },
                        { text: 'Account Type', value: 'AccountType', sortable: false },
                        { text: 'Closing Balance', value: 'ClosingBalance', sortable: false },
                        { text: 'Active', value: 'IsActive', sortable: false },
                        { text: 'Actions', value: 'Actions', sortable: false },
                    ],
                    data: [],
                    selected: [],
                    loading: false,
                },
            };
        },
        methods: {
            newAccount(){
                this.$refs.dlgAccount.show();
            }
        },
    }
</script>

<style scoped>

</style>