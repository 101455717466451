<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Users Roles Settings</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                        outlined
                        dense
                        hide-details
                        label="Search"
                        name="search"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        class="small-text-field"
                        background-color="white"
                        @keypress.enter="search = $event.target.value">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        User Roles
                        <v-spacer></v-spacer>
                        <v-btn
                                @click="newUserRole"
                                class="green py-4"
                                dark
                                small
                        >
                            <v-icon left>mdi-plus-circle</v-icon>
                            <span>New User Role</span>
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <v-list-item
                                    ripple
                                    link
                                    dense
                                    @click="selectRole(role)"
                                    v-for="role in usersRoles"
                                    :key="role.UserRoleID"
                                    style="border-radius: 4px"
                                    :class="selectedRole.UserRoleID == role.UserRoleID ? 'grey lighten-2 elevation-1' : ''">
                                <v-list-item-content>
                                    <v-list-item-title>{{ role.UserRole }} ({{ role.TotalUsers }} Users)</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-action><v-btn icon small @click.stop="updateRole(role)"><v-icon small>fa-pencil</v-icon></v-btn></v-list-item-action>
                                <v-list-item-action><v-btn icon small @click.stop="deleteRole(role)"><v-icon small>fa-trash</v-icon></v-btn></v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="8">
                <v-data-table
                        :headers="rights.headers"
                        :items="rights.data"
                        :loading="rights.loading"
                        loading-text="Loading Rights, please wait..."
                        class="elevation-1"
                        disable-pagination
                        dense
                        hide-default-footer>
                    <template v-slot:item.Rights="props">
                        <v-row align="center">
                            <v-checkbox
                                    v-if="props.item.Options.contains('V')"
                                    v-model="props.item.Rights"
                                    label="View"
                                    value="V"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('A')"
                                    v-model="props.item.Rights"
                                    label="Add"
                                    value="A"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('E')"
                                    v-model="props.item.Rights"
                                    label="Edit"
                                    value="E"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('D')"
                                    v-model="props.item.Rights"
                                    label="Delete"
                                    value="D"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('C')"
                                    v-model="props.item.Rights"
                                    label="Approve"
                                    value="C"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('S')"
                                    v-model="props.item.Rights"
                                    label="SMS"
                                    value="S"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('F')"
                                    v-model="props.item.Rights"
                                    label="Free Days"
                                    value="F"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('SM')"
                                    v-model="props.item.Rights"
                                    label="Submitting"
                                    value="SM"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                            <v-checkbox
                                    v-if="props.item.Options.contains('RE')"
                                    v-model="props.item.Rights"
                                    label="Receiving"
                                    value="RE"
                                    @change="updateRights(props.item)"
                                    class="my-1 mx-2"
                                    hide-details>
                            </v-checkbox>
                        </v-row>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgUsersRoles"
                title="User Role"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveRole">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="selectedRole.UserRole"
                                label="User Role"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "Users Roles Settings",
        data(){
            return {
                usersRoles: [],
                selectedRole: {},
                rights: {
                    headers: [
                        { text: 'Module', value: 'Module' },
                        { text: 'Form', value: 'Title' },
                        { text: 'Rights', value: 'Rights' },
                    ],
                    data: [],
                },
            };
        },
        methods: {
            getData(){
              this.$loadingDialog.show();

              axios.post('Users/UsersRoles_GetData', {Option: 1})
                  .then(response => {
                      const data = response.data;
                      const status = data.Status;

                      if(status)
                      {
                          this.usersRoles = data.Data;
                      }

                      this.$loadingDialog.hide();
                  })
                  .catch(error => {
                      console.log(error);
                      this.$loadingDialog.hide();
                      this.$snackBar.error({message: 'An unexpected error has occurred'});
                  });
            },
            selectRole(role){
                console.log(role);
                this.selectedRole = role;
                this.rights.data = this.selectedRole.Rights;
            },
            newUserRole(){
                this.selectedRole = {
                    UserRoleID: 0,
                    UserRole: '',
                    Rights: [],
                }

                this.$refs.dlgUsersRoles.show();
            },
            updateRole(role){
                this.selectedRole = role;

                this.$refs.dlgUsersRoles.show();
            },
            saveRole() {
                if(this.selectedRole.UserRole.length == 0)
                {
                    this.$snackBar.error({message: 'Please enter a Role name'});
                    return;
                }

                this.$loadingDialog.show();

                let formData = {
                    'UserRoleID': this.selectedRole.UserRoleID,
                    'UserRole': this.selectedRole.UserRole,
                };

                axios.post('Users/UsersRoles_AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            deleteRole(role){
                const vm = this;
                this.$confirmDialog.show({
                    title: 'Delete User Role',
                    message: `Are you sure you want to delete "${role.UserRole}"? Please confirm.`,
                    async onConfirm(){
                        vm.$loadingDialog.show();

                        await axios.post('Users/UsersRoles_Delete', {UserRoleID: role.UserRoleID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    vm.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                            })
                            .catch(error => {
                                console.log(error);
                                this.$snackBar.error({message: 'An unexpected error has occurred'});
                            });

                        vm.$loadingDialog.hide();
                    }
                });
            },
            updateRights(rights){
                this.$loadingDialog.show();

                let formData = {
                    'Rights': rights
                };

                axios.post('Users/UsersRoles_UpdateRights', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
        },
        created() {
            this.getData();
        }
    }
</script>

<style scoped>

</style>