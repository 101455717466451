<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Invoice</h2>
            </v-col>
        </v-row>

        <v-row dense>

        </v-row>
    </v-container>
</template>

<script>
    export default {
        name: "invoicesAddEdit"
    }
</script>

<style scoped>

</style>