import Vue from 'vue'
import App from './App.vue'

import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import {routes} from './plugins/routes';
import {store} from './store/store';
import axios from 'axios';

import customCS from './mixins/customCS';
import pageTitle from './mixins/pageTitle.js';
import customDialog from "./components/customDialog/customDialog";
import confirmDialog from "./components/confirmDialog/confirmDialog.js";
import documentUploadDialog from "./components/documentUploadDialog/documentUploadDialog.js";
import snackBar from "./components/snackBar/snackBar.js";
import loadingDialog from "./components/loadingDialog/loadingDialog.js";

import pieChart from './components/charts/pieChart'
import barChart from './components/charts/barChart'
import datePicker from "./components/datePicker";

Vue.config.productionTip = false

Vue.mixin(customCS);
Vue.mixin(pageTitle);
Vue.use(confirmDialog);
Vue.use(documentUploadDialog);
Vue.use(snackBar);
Vue.use(loadingDialog);
Vue.component('custom-dialog', customDialog);
Vue.component('pie-chart', pieChart);
Vue.component('bar-chart', barChart);
Vue.component('date-picker', datePicker);

Vue.use(VueRouter);

const config = window.config;

const router = new VueRouter({
  routes,
  base: config.basePath,
  mode: 'history',
});

axios.defaults.baseURL = config.apiUrl;
// axios.defaults.baseURL = "http://192.168.1.9:22884/";

axios.interceptors.request.use(config => {
  if(store.getters.isAuthenticated)
  {
    config.headers.common['Authorization'] = `Bearer ${store.getters.token}`;
  }

  return config;
});

axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if(error.response.status == 401)
      {
        store.dispatch('logout').then(function() {
          router.replace({name: 'login'});
        });
      }
      else
        return Promise.reject(error);
    });

router.beforeEach((to, from, next) => {
  const modules = store.getters.modules;
  const rights = store.getters.rights;
  const userRoleID = store.getters.user.UserRoleID;

  if((modules == '' || modules == undefined) && (rights == '' || rights == undefined) && to.name != 'login')
  {
    store.dispatch('logout').then(function() {
      router.push({name: 'login'});
    });
  }

  if( to.path == '/' ) {
    next({name: 'login'});
  }
  else if( to.name != 'login' && !store.getters.isAuthenticated ) {
    next({name: 'login'});
  }
  else if( to.name == 'login' && store.getters.isAuthenticated ) {
    const module = modules[0];

    if(module.Links.length == 0)
      next({name: module.LinkName});
    else
    {
      const link = module.Links[0];

      if(link.LinkName.includes('ops'))
        next({name: link.LinkName, params: {stage: link.LinkID}});
      else if(module.Links[0].LinkName.includes('terminal'))
        next({name: link.LinkName, params: {id: link.LinkID}});
      else
        next({name: module.Links[0].LinkName});
    }
  }
  else {
    const module = modules[0];

    if(store.getters.isAuthenticated)
    {
      let hasLink = false;

      if(rights != "" && rights != null && rights != undefined)
      {
        rights.forEach(element => {
          if(element.LinkName == to.name)
          {
            hasLink = true;
            return;
          }
        });

        if( !hasLink && userRoleID == 1 )
          hasLink = true;
      }
      else
        next();

      if(hasLink)
        next();
      else {
        if(module.Links.length == 0)
          next({name: module.LinkName});
        else
          next({name: module.Links[0].LinkName, params: {action: 'new'}});
      }
    }
    else
      next();
  }
});

Vue.prototype.$viewPDF = function(url, formData, print, fnCompleted) {
  axios.post(url, formData, { responseType: "blob" }).then(response => {

    const file = new Blob([response.data], {type: "application/pdf"});
    const fileUrl = URL.createObjectURL(file);
    let myWindow = window.open(fileUrl, "_blank");

    if(print)
      myWindow.print();

    fnCompleted();

  }).catch(error => {
    console.log(error);
    fnCompleted();
  });
};

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  // async beforeCreate(){
  //   // const config = await this.readConfigFile();
  //   const ax = axios.create({baseURL: '/'});
  //   const config = await ax.get('conf.json').then(response => {
  //     return response.data;
  //   });
  //
  //   axios.defaults.baseURL = config.apiUrl; //'http://api.shiponlogistics.net/';
  //   console.log(config);
  // }
}).$mount('#app')
