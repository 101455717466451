var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"2"}},[_c('v-img',{attrs:{"max-height":"100px","src":require("@/assets/logo.png"),"contain":""}})],1),(_vm.settings.ClientBanner01.length > 0)?_c('v-col',{attrs:{"md":"10","align":"right"}},[_c('v-img',{attrs:{"height":"150px","src":_vm.settings.ClientBanner01}})],1):_vm._e()],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.files.headers,"items":_vm.files.data,"item-key":"FileID","loading":_vm.files.loading,"loading-text":"Loading Files, please wait...","disable-pagination":"","disable-sort":"","dense":"","hide-default-footer":""},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.IsAcceptAssessment",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"mt-1 mb-2",attrs:{"color":"green","hide-details":"","readonly":item.IsAcceptAssessment},on:{"click":function($event){$event.stopPropagation();return _vm.updateAssessStatus(item)}},model:{value:(item.IsAcceptAssessment),callback:function ($$v) {_vm.$set(item, "IsAcceptAssessment", $$v)},expression:"item.IsAcceptAssessment"}})]}},{key:"item.ValuationFilePath",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","href":item.ValuationFilePath,"target":"_blank","disabled":!(item.ValuationFilePath && item.IsShowDocuments)}},[_c('v-icon',[_vm._v("fa-search")])],1)]}},{key:"item.RiskLevel",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-1 white--text",attrs:{"color":item.RiskLevelColor.toLowerCase(),"label":""}},[_c('b',[_vm._v(_vm._s(item.RiskLevel))])])]}},{key:"item.Stage",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-1",attrs:{"color":"primary","label":""}},[_c('b',[_vm._v(_vm._s(item.Stage))])])]}}],null,false,1455832691)}):_vm._e(),_c('v-card',[_c('v-card-text',{staticClass:"pa-1"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-list',{attrs:{"dense":""}},[_vm._l((_vm.files.data),function(file,i){return [_c('v-list-item',{key:file.FileID},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Shipment BL No: "+_vm._s(file.BLNo))]),_c('v-list-item-title',[_vm._v("Vessel: "+_vm._s(file.VesselName))]),_c('v-list-item-title',[_vm._v("ETA: "+_vm._s(file.ETADate))]),_c('v-list-item-title',[_vm._v("No Of Containers: "+_vm._s(file.FCL))]),_c('v-list-item-title',[_vm._v("Commodity: "+_vm._s(file.ProductDescription))]),_c('v-list-item-title',[_vm._v("Estimated Clearing Date: "+_vm._s(file.EstimatedClearingDate))]),_c('v-list-item-title',[_vm._v("Status: "+_vm._s(file.Stage))]),_c('v-row',{staticClass:"no-gutters"},[((file.ValuationFilePath && file.IsShowDocuments))?_c('v-col',[_c('v-btn',{staticClass:"py-4 mt-2 white--text",attrs:{"color":"green","small":"","block":"","href":file.ValuationFilePath}},[_vm._v("View Assessment")])],1):_vm._e(),(file.ValuationFilePath && !file.IsAcceptAssessment)?_c('v-col',{staticClass:"ml-1"},[_c('v-btn',{staticClass:"py-4 mt-2 white--text",attrs:{"color":"green","small":"","block":""},on:{"click":function($event){return _vm.updateAssessStatus(file)}}},[_vm._v("Accept Assessment")])],1):_vm._e()],1),_c('v-btn',{staticClass:"py-4 mt-2",attrs:{"color":"primary","small":"","block":""},on:{"click":function($event){return _vm.onRowClick(file)}}},[_vm._v("View File")])],1)],1),(i < _vm.files.data.length - 1)?_c('v-divider',{key:("Div_" + (file.FileID))}):_vm._e()]})],2):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }