<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Valuation</h2>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" md="3">
                <v-text-field
                        outlined
                        dense
                        hide-details
                        label="Search"
                        name="search"
                        type="text"
                        prepend-inner-icon="mdi-magnify"
                        class="small-text-field"
                        background-color="white"
                        @keypress.enter="search = $event.target.value">
                </v-text-field>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.totals)" :key="key" class="mr-2">
                            <span :class="getTotalsClass(key, false)">{{ key }}: </span>
                            <span :class="getTotalsClass(key, true)">{{ files.totals[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-spacer></v-spacer>
            <v-col cols="12" md="6" align="end">
                <v-btn
                        color="primary"
                        small
                        class="mr-1 white--text" >
                    Export To Excel
                </v-btn>
                <v-btn
                        color="primary"
                        small
                        class="white--text" >
                    Export To PDF
                </v-btn>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td v-for="(header, i) in files.headers" :key="header.value">
                                <template v-if="i == 0">
                                    <v-icon>fa-filter</v-icon>
                                </template>
                                <template v-else>
                                    <v-text-field
                                            v-model="files.filters[header.value]"
                                            class="small-text-field my-1"
                                            background-color="white"
                                            outlined
                                            dense
                                            hide-details
                                            autcomplete="disable"
                                            @change="customFilter($event, header.value)"
                                            v-if="header.applyFilter && !header.value.includes('Date')">
                                    </v-text-field>
                                    <v-menu
                                            offset-y
                                            transition="scale-transition"
                                            :close-on-content-click="true"
                                            v-if="header.applyFilter && header.value.includes('Date')">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                    v-model="files.filters[header.value]"
                                                    class="small-text-field my-1"
                                                    background-color="white"
                                                    outlined
                                                    dense
                                                    readonly
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on">
                                            </v-text-field>
                                        </template>
                                        <v-date-picker
                                                v-model="files.filters[header.value]"
                                                @change="customFilter($event, header.value)">
                                            <v-spacer></v-spacer>
                                            <v-btn color="grey" class="white--text" small
                                                   @click="customFilter('', header.value)">Clear</v-btn>
                                        </v-date-picker>
                                    </v-menu>
                                </template>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.IsAcceptAssessment="{ item }">
                        <v-tooltip top :disabled="!item.IsAcceptAssessment">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs"
                                     v-on="on"
                                     style="width: min-content;">
                                    <v-switch
                                            v-model="item.IsAcceptAssessment"
                                            color="green"
                                            hide-details
                                            class="mt-1 mb-2"
                                            :readonly="item.IsAcceptAssessment"
                                            @change="updateAssessStatus(item)">
                                    </v-switch>
                                </div>
                            </template>
                            <span>{{ item.IsAcceptAssessment ? `${item.AssessmentAcceptDate} BY ${item.AssessmentAcceptByUser}` : '' }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.IDFFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.IDFFilePath"
                                target="_blank"
                                :disabled="!item.IDFFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.BOEFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.BOEFilePath"
                                target="_blank"
                                :disabled="!item.BOEFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.ValuationReportFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.ValuationReportFilePath"
                                target="_blank" :disabled="!item.ValuationReportFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.MDAFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.MDAFilePath"
                                target="_blank" :disabled="!item.MDAFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.Priority="{ item }">
                        <v-chip
                                label
                                text-color="white"
                                :color="item.Priority == 'Normal' ? 'green' : item.Priority == 'Medium' ? 'orange' : item.Priority == 'Urgent' ? 'purple' : 'red'">
                            {{ item.Priority }}
                        </v-chip>
                    </template>
                    <template v-slot:item.Actions="{ item }">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="viewSMS(item)" dense v-if="rights.allowSMS">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-envelope</v-icon></v-list-item-icon>
                                    <v-list-item-title>Send SMS</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="viewFile(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-search</v-icon></v-list-item-icon>
                                    <v-list-item-title>View File</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="editBOE(item)" dense v-if="rights.allowEdit">
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                                <v-list-item @click="viewDocuments(item.FileID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-file</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Documents</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="viewClearing(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Edit Estimated Clearing Date</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgBOE"
                title="BOE Details"
                :persistent="false"
                :max-width="900"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveBOE">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.BLNo"
                                label="BL No"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.FCL"
                                label="FCL"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.ProductDescription"
                                label="Product Description"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="5">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        :value="formatDate(editingBOE.BOEReceivingDate)"
                                        label="BOE Receiving Date"
                                        class="mb-2"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    style="width: 100%"
                                    v-model="editingBOE.BOEReceivingDate">
                            </v-date-picker>
                        </v-menu>

                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        :value="formatDate(editingBOE.BOESubmissionDate)"
                                        label="BOE Submission Date"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    style="width: 100%"
                                    v-model="editingBOE.BOESubmissionDate">
                            </v-date-picker>
                        </v-menu>

                        <v-select
                                v-model="editingBOE.FKBOEStatusID"
                                label="BOE Status"
                                :items="boeStatus"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                background-color="white"
                                dense
                                class="mt-2"
                                hide-details="auto">
                        </v-select>

                        <v-text-field
                                v-model="editingBOE.UCRNo"
                                label="UCR No"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="editingBOE.Comments"
                                label="Comments"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-text-field>

                        <v-textarea
                                v-model="editingBOE.ClientComments"
                                label="Client Comments"
                                rows="3"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-textarea>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-card>
                            <v-card-title>Documents</v-card-title>
                            <v-list dense>
                                <v-list-item v-for="document in selectedFile.Documents" :key="document.title" @click="selectDocument(document)">
                                    <v-list-item-content>
                                        <v-list-item-title :class="document.FilePath.length == 0 ? 'red--text' : ''">{{ document.DocumentTitle }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ document.DocumentNo }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon><v-btn icon small @click.stop="deleteDocument(document)"><v-icon color="grey" small>fa-trash</v-icon></v-btn></v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgFile"
                title="File Details"
                :persistent="false"
                :max-width="800"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.CompanyName"
                                label="Company Name"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.BLNo"
                                label="BL No"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.BLStatus"
                                label="BL Status"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.Reference"
                                label="Ref No"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.FCL"
                                label="FCL"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.ShippingLine"
                                label="Shipping Line"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.BOEMode"
                                label="BOE Mode"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.BOEStatus"
                                label="BOE Status"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.ProductCategory"
                                label="Product Category"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.ProductDescription"
                                label="Description Of Items"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.ReceivingFileDate"
                                label="File Receiving Date"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.ETADate"
                                label="ETA Date"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-text-field
                                v-model="selectedFile.BOEReceivingDate"
                                label="BOE Receiving Date"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocument"
                title="Upload Document"
                :persistent="false"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentTitle"
                                label="Document Title"
                                readonly
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentNo"
                                label="Document No"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                class="primary"
                                dark
                                block >
                            Upload Document
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadDocument" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgSMS"
                title="Send SMS"
                confirm-button-text="Send"
                cancel-button-text="Close"
                max-width="900"
                :on-confirm="sendSMSMessage">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field label="Message" v-model="message.Message" outlined dense background-color="white" hide-details />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocuments"
                title="All Documents"
                max-width="600"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-list dense>
                    <template  v-if="fileDocuments.length > 0">
                        <v-list-item v-for="doc in fileDocuments" :key="doc.DocumentID" link :href="doc.FilePath" target="_blank">
                            <v-list-item-content>
                                <v-list-item-title>{{ doc.DocumentTitle }}</v-list-item-title>
                                <v-list-item-title v-if="doc.DocumentNo.length > 0">Document # {{ doc.DocumentNo }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title>No Documents Are Available</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgEstimatedClearing"
                title="Estimated Clearing Date"
                confirm-button-text="Save"
                cancel-button-text="Close"
                max-width="500"
                :on-confirm="saveClearing">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <date-picker label="Estimated Clearing Date" v-model="clearing.EstimatedClearingDate" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Bill Of Entry",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S. No', value: 'SNo', applyFilter: false },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'ETA Date', value: 'ETADate', applyFilter: true },
                        { text: 'UCR No', value: 'UCRNo', applyFilter: true },
                        { text: 'BOE No', value: 'BOENo', applyFilter: true },
                        { text: 'BOE', value: 'BOEFilePath', applyFilter: false },
                        { text: 'IDF', value: 'IDFFilePath', applyFilter: false },
                        { text: 'Assessment', value: 'ValuationReportFilePath', applyFilter: false },
                        { text: 'MDA', value: 'MDAFilePath', applyFilter: false },
                        { text: 'Duration', value: 'DurationString', applyFilter: true },
                        { text: 'Comments', value: 'Comments', applyFilter: true },
                        { text: 'BOE Status', value: 'BOEStatus', applyFilter: true },
                        { text: 'BOE Mode', value: 'BOEMode', applyFilter: true },
                        { text: 'Priority', value: 'Priority', applyFilter: true },
                        { text: 'Action', value: 'Actions', applyFilter: false },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    loading: false,
                },
                boeStatus: [],
                selectedFile: {},
                editingBOE: {},
                documents: [],
                fileDocuments: [],
                document: {
                    DocumentID: 0,
                    FKDocumentTypeID: 1,
                    DocumentNo: '',
                    DocumentDate: '',
                    FilePath: '',
                    File: null,
                    name: "",
                    size: "",
                    error: "",
                },
                message: {
                    FileID: 0,
                    Message: '',
                },
                clearing: {
                    FileID: 0,
                    EstimatedClearingDate: '',
                },
                rights: {
                    allowEdit: false,
                    allowSMS: false,
                },
            };
        },
        methods: {
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            getData(){
                this.files.loading = true;

                const formData = {
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: false,
                    Filters: this.files.filters,
                };
                console.log(JSON.stringify(formData));

                axios.post('BOE/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            editBOE(file){
                this.selectedFile = file;

                if( this.selectedFile.Documents.length == 0 )
                {
                    this.documents.forEach(element => {

                        this.selectedFile.Documents.push({
                            DocumentID: 0,
                            DocumentTitle: element.TextLine,
                            FKDocumentTypeID: 1,
                            DocumentNo: '',
                            DocumentDate: '',
                            FilePath: '',
                            File: null,
                            name: "",
                            size: "",
                            error: "",
                        });

                    });
                }

                this.editingBOE = {
                    FileID: file.FileID,
                    FKCustomerID: file.FKCustomerID,
                    BOEReceivingDate: this.formatString(file.BOEReceivingDate),
                    BOESubmissionDate: this.formatString(file.BOESubmissionDate),
                    FKBOEModeID: 0,
                    FKBOEStatusID: file.FKBOEStatusID,
                    FKRiskLevelID: 0,
                    UCRNo: file.UCRNo,
                    Comments: file.Comments,
                    ClientComments: file.ClientComments,
                    IsShowDocuments: file.IsShowDocuments,
                };

                this.$refs.dlgBOE.show();
            },
            selectDocument(document)
            {
                if(document.FilePath.length == 0) {
                    this.document = document;
                    this.$refs.dlgDocument.show();
                }
                else {
                    window.open(document.FilePath, "_blank");
                }
            },
            uploadDocument(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.document.error = "File size is exceeding the limit. Please select a smaller picture.";
                    return;
                }

                this.document.File = file;
                this.document.FilePath = URL.createObjectURL(file);
                this.document.name = file.name;
                this.document.size = size;
                this.document.error = "";
            },
            deleteDocument(document){
                document.DocumentNo = '';
                document.File = null;
                document.FilePath = '';
                document.name = '';
                document.size = '';
                document.error = "";
            },
            saveBOE() {
                let formData = new FormData();
                Object.keys(this.editingBOE).forEach(key => {
                    formData.append(`${key}`, this.editingBOE[key]);
                });

                this.selectedFile.Documents.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Documents[${ind}].${key}`, item[key]);
                    });
                });

                axios.post('BOE/Update', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.getData();
                        }

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    }).catch(error => {
                    console.log(error);
                    this.$loadingDialog.hide();
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });
            },
            viewFile(file){
                this.selectedFile = file;
                this.$refs.dlgFile.show();
            },
            formatDate(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
            formatString(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    const mon = this.months.indexOf(f_date[1]);
                    let f_date_string = new Date(f_date[2], mon, f_date[0]);

                    return this.toShortDateString(f_date_string);
                }
                else
                    return '';
            },
            viewSMS(file){
                this.message = {
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgSMS.show();
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
            viewClearing(file){
                this.clearing = {
                    FileID: file.FileID,
                    EstimatedClearingDate: file.EstimatedClearingDate,
                };

                this.$refs.dlgEstimatedClearing.show();
            },
            async saveClearing(){
                this.$loadingDialog.show();
                await this.SaveEstimatedClearing(this.clearing.FileID, this.clearing.EstimatedClearingDate);
                await this.getData();
                this.$loadingDialog.hide();
            },
            async updateAssessStatus(item){
                this.$loadingDialog.show();
                await this.SaveAssessStatus(item.FileID);
                await this.getData();
                this.$loadingDialog.hide();
            },
            async viewDocuments(fileID){
                this.$loadingDialog.show();

                this.fileDocuments = await axios.post('Files/GetAllDocuments', {FileID: fileID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        return status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$refs.dlgDocuments.show();
                this.$loadingDialog.hide();
            },
            getTotalsClass(key, isValue){
                let css = isValue ? "indigo--text text--darken-2" : "";

                if(key.indexOf("> 5 Days") != -1)
                    css = "red--text";
                else if(key.indexOf("1-5 Days") != -1)
                    css = "green--text";

                return css;
            },
        },
        created() {
            this.rights.allowEdit = this.verifyRights(this.$route.name, 'E', 0);
            this.rights.allowSMS = this.verifyRights(this.$route.name, 'S', 0);

            this.getData();

            this.$loadingDialog.show();
            this.getGeneral(12, 0)
                .then(response => {
                    this.documents = response.Data;
                    this.$loadingDialog.hide();
                })
                .catch(() => {
                    this.$loadingDialog.hide();
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.$loadingDialog.show();
            this.getGeneral(8, 0)
                .then(response => {
                    this.boeStatus = response.Data;
                    this.$loadingDialog.hide();
                })
                .catch(() => {
                    this.$loadingDialog.hide();
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

        }
    }
</script>

<style scoped>

</style>