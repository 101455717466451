<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Terminal Settings</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Terminals
                        <v-spacer></v-spacer>
                        <v-btn
                                class="white--text"
                                color="green"
                                small
                                @click="addTerminal">
                            <v-icon left>fa-plus-circle</v-icon>
                            Add Terminal
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <v-list-item v-for="terminal in terminals" :key="terminal.TerminalID"
                                         style="border-radius: 5px;"
                                         :class="selectedTerminal.TerminalID == terminal.TerminalID ? 'grey lighten-2 elevation-1' : ''"
                                         @click="selectTerminal(terminal)">
                                <v-list-item-content>
                                    <v-list-item-title>{{ terminal.Terminal }}</v-list-item-title>
                                </v-list-item-content>
                                <v-list-item-icon>
                                    <v-btn small icon @click="editTerminal(terminal)">
                                        <v-icon small>fa-pencil</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                                <v-list-item-icon>
                                    <v-btn small icon @click="deleteTerminal(terminal)">
                                        <v-icon small>fa-trash</v-icon>
                                    </v-btn>
                                </v-list-item-icon>
                            </v-list-item>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Display Fields - {{ selectedTerminal.Terminal }}
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-row dense>
                            <v-col cols="12" md="10">
                                <v-autocomplete
                                        label="Field"
                                        v-model="selectedField"
                                        :items="fileFields"
                                        item-text="FieldDisplayName"
                                        item-value="FieldName"
                                        class="small-text-field"
                                        autocomplete="disable"
                                        return-object
                                        hide-details
                                        dense
                                        solo>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="2">
                                <v-btn
                                        class="white--text py-4"
                                        color="green"
                                        small
                                        block
                                        :disabled="!this.selectedTerminal || !this.selectedField"
                                        @click="addDisplayField">
                                    <v-icon>fa-plus-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-list dense>
                            <draggable
                                    v-model="selectedTerminal.DisplayFields"
                                    @start="displayFieldDrag = true"
                                    @end="onDisplayDrag($event, selectedTerminal.DisplayFields)">
                                <v-list-item v-for="field in selectedTerminal.DisplayFields" :key="field.FieldName">
                                    <v-list-item-icon><v-icon small style="cursor: pointer">fa-bars</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ field.FieldDisplayName }}</v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-btn small icon @click="deleteDisplayField(field)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item-icon>
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="white--text" @click="saveFields(true)">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card class="card-scroll-wrapper">
                    <v-card-title>
                        Update Fields - {{ selectedTerminal.Terminal }}
                        <v-spacer></v-spacer>
                        <v-btn small color="green" class="white--text" @click="addUpdateField"><v-icon left>fa-plus-circle</v-icon> Add</v-btn>
                    </v-card-title>
                    <v-card-text class="card-scroll">
                        <v-list dense>
                            <draggable
                                    v-model="selectedTerminal.UpdateFields"
                                    @start="displayFieldDrag = true"
                                    @end="onDisplayDrag($event, selectedTerminal.UpdateFields)">
                                <v-list-item v-for="field in selectedTerminal.UpdateFields" :key="field.FieldName">
                                    <v-list-item-icon><v-icon small style="cursor: pointer">fa-bars</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ field.FieldDisplayName }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ field.FieldType }}</v-list-item-subtitle>
                                        <v-list-item-subtitle v-if="!!field.Options">{{ field.Options }}</v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon>
                                        <v-btn small icon @click="deleteUpdateField(field)">
                                            <v-icon small>fa-trash</v-icon>
                                        </v-btn>
                                    </v-list-item-icon>
                                </v-list-item>
                            </draggable>
                        </v-list>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="green" class="white--text" @click="saveFields(false)">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgTerminal"
                title="Terminal"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveTerminal">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="terminal.Terminal"
                                label="Item Description"
                                solo
                                dense
                                autofocus
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-checkbox
                                v-model="terminal.IsBooking"
                                label="Booking Required"
                                solo
                                dense
                                autofocus
                                hide-details="auto">
                        </v-checkbox>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgFields"
                title="Update Fields"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveUpdateField">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                label="Field Name"
                                v-model="updateField.FieldName"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                label="Field Type"
                                v-model="updateField.FieldType"
                                :items="['Switch', 'Text', 'Date', 'Dropdown', 'Document']"
                                hide-details
                                solo>
                        </v-select>
                    </v-col>
                    <v-col cols="12"
                           v-if="updateField.FieldType == 'Dropdown'">
                        <v-text-field
                                label="Options"
                                v-model="updateField.Options"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                    <v-col cols="12"
                           v-if="updateField.FieldType == 'Document'">
                        <v-text-field
                                label="Document Name"
                                v-model="updateField.Options"
                                hide-details
                                solo>
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";
    import draggable from 'vuedraggable';

    export default {
        title: "Terminal Settings",
        components: {
            draggable,
        },
        data(){
            return {
                terminals: [],
                selectedTerminal: {},
                fileFields: [],
                selectedField: {},
                displayFieldDrag: false,
                updateFieldDrag: false,
                updateField: {
                    FieldName: '',
                    FieldType: '',
                    Options: '',
                },
                terminal: {
                    TerminalID: 0,
                    Terminal: '',
                    IsBooking: true,
                },
            };
        },
        computed: {
            displayMaxSeq() {
                return this.selectedTerminal.DisplayFields.length == 0 ? 0 : Math.max.apply(Math, this.selectedTerminal.DisplayFields.map(function(o){return o.Seq;}));
            },
            updateMaxSeq() {
                return this.selectedTerminal.UpdateFields.length == 0 ? 0 : Math.max.apply(Math, this.selectedTerminal.UpdateFields.map(function(o){return o.Seq;}));
            }
        },
        methods: {
            getData(){
                this.$loadingDialog.show();

                axios.post('Terminals/GetData', {Option: 1})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.terminals = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            getFileFields() {
                this.$loadingDialog.show();

                axios.post('Terminals/GetFileFields', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.fileFields = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});

                        this.$loadingDialog.hide();
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            addTerminal(){
              this.terminal = {
                  TerminalID: 0,
                  Terminal: '',
                  IsBooking: true,
              };

              this.$refs.dlgTerminal.show();
            },
            editTerminal(terminal){
              this.terminal = terminal;
              this.$refs.dlgTerminal.show();
            },
            deleteTerminal(terminal){
                let vm = this;

                this.$confirmDialog.show({
                   title: 'Delete Terminal',
                   message: 'Are you sure you want to delete this terminal? Please confirm.',
                   onConfirm(){
                       vm.$loadingDialog.show();

                       const formData = {
                           TerminalID: terminal.TerminalID
                       };

                       axios.post('Terminals/Delete', formData)
                           .then(response => {
                               const data = response.data;
                               const status = data.Status;

                               if(status)
                               {
                                   vm.selectedTerminal = {};
                                   vm.getData();
                               }

                               vm.$loadingDialog.hide();
                               vm.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                           })
                           .catch(error => {
                               console.log(error);
                               vm.$snackBar.error({message: 'An unexpected error has occurred'});
                               vm.$loadingDialog.hide();
                           });
                   }
                });
            },
            saveTerminal(){
                this.$loadingDialog.show();

                const formData = this.terminal;

                axios.post('Terminals/AddEdit', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            this.selectedTerminal = {};
                            this.getData();
                        }

                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                        this.$loadingDialog.hide();
                    });
            },
            selectTerminal(terminal) {
                this.selectedTerminal = terminal;
            },
            onDisplayDrag(ev, fields) {
                const oldSeq = ev.oldIndex + 1;
                const newSeq = ev.newIndex + 1

                if( oldSeq != newSeq )
                {
                    if( newSeq < oldSeq )
                    {
                        fields.forEach(element => {
                            if(element.Seq >= newSeq && element.Seq < oldSeq)
                                element.Seq += 1;
                            else if(element.Seq == oldSeq)
                                element.Seq = newSeq;
                        });
                    }
                    else
                    {
                        fields.forEach(element => {
                            if(element.Seq == oldSeq)
                                element.Seq = newSeq
                            else if(element.Seq > oldSeq && element.Seq <= newSeq)
                                element.Seq -= 1;
                        });
                    }
                }

                fields.forEach(element => {
                    console.log(element.FieldName, element.Seq);
                });
            },
            addDisplayField() {
                this.selectedTerminal.DisplayFields.push({
                    FieldName: this.selectedField.FieldName,
                    FieldDisplayName: this.selectedField.FieldDisplayName,
                    FieldType: '',
                    IsDisplay: true,
                    Seq: this.displayMaxSeq + 1,
                });

                this.selectedField = {};
            },
            async deleteDisplayField(field){
                const seq = field.Seq;
                const ind = this.selectedTerminal.DisplayFields.indexOf(field);
                this.selectedTerminal.DisplayFields.splice(ind, 1);

                this.selectedTerminal.DisplayFields.forEach(element => {
                    if(element.Seq > seq )
                        element.Seq = element.Seq - 1;
                });
            },
            addUpdateField(){
                this.updateField = {
                    FieldName: '',
                    FieldType: '',
                    Options: '',
                };

                this.$refs.dlgFields.show();
            },
            saveUpdateField() {
                this.selectedTerminal.UpdateFields.push({
                    FieldName: this.updateField.FieldName,
                    FieldDisplayName: this.updateField.FieldName,
                    FieldType: this.updateField.FieldType,
                    Options: this.updateField.Options,
                    IsDisplay: false,
                    Seq: this.updateMaxSeq + 1,
                });
            },
            async deleteUpdateField(field){
                const seq = field.Seq;
                const ind = this.selectedTerminal.UpdateFields.indexOf(field);
                this.selectedTerminal.UpdateFields.splice(ind, 1);

                this.selectedTerminal.UpdateFields.forEach(element => {
                    if(element.Seq > seq )
                        element.Seq = element.Seq - 1;
                });
            },
            async saveFields(isDisplay) {
                this.$loadingDialog.show();

                const formData = {
                    TerminalID: this.selectedTerminal.TerminalID,
                    IsDisplay: isDisplay,
                    Fields: isDisplay ? this.selectedTerminal.DisplayFields : this.selectedTerminal.UpdateFields,
                };

                await axios.post('Terminals/AddEditFields', formData)
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$loadingDialog.hide();
            }
        },
        created(){
            this.getData();
            this.getFileFields();
        },
    }
</script>

<style scoped>

    .card-scroll {
        height: 65vh;
    }

</style>