<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12">
                <h2 class="heading">{{ action }} File</h2>
            </v-col>
        </v-row>
        <v-row dense class="mb-3">
            <v-col cols="12" md="4">
                <v-row dense>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.FileNo"
                                label="File No"
                                class="small-text-field elevation-1"
                                background-color="white"
                                outlined
                                dense
                                disabled
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.CustomerNo"
                                label="Customer No"
                                class="small-text-field elevation-1"
                                background-color="white"
                                outlined
                                dense
                                disabled
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>

                <v-autocomplete
                        v-model="selectedCustomer"
                        label="Company"
                        :items="customers"
                        item-text="CompanyName"
                        @change="onCustomerChange($event)"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        return-object
                        autocomplete="disabled"
                        hide-details="auto">
                </v-autocomplete>

                <v-row dense>
                    <v-col cols="12" md="6" class="py-0">
                        <v-select
                                v-model="file.ShipmentType"
                                label="Shipment Type"
                                :items="['Import', 'Export']"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-select
                                v-model="file.ShipmentMethod"
                                label="Shipment Method"
                                :items="['Sea', 'Air']"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.BLNo"
                                label="BL No"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-select
                                v-model="file.BLStatus"
                                label="BL Type"
                                :items="['Original', 'Telex Release', 'Copy', 'Sea Waybill']"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>

                <v-text-field
                        v-model="file.Reference"
                        label="Reference"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="file.VesselName"
                        label="Vessel Name"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-select
                        v-model="file.FKShippingLineID"
                        label="Shipping Line"
                        :items="shippingLines"
                        item-text="TextLine"
                        item-value="Value01"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-select>

                <v-select
                        v-model="file.FKProductCategoryID"
                        label="Product Category"
                        :items="productCategories"
                        item-text="TextLine"
                        item-value="Value01"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-select>

                <v-textarea
                        v-model="file.ProductDescription"
                        label="Description Of Items"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        rows="2"
                        dense
                        hide-details="auto">
                </v-textarea>

            </v-col>
            <v-col cols="12" md="4">
                <v-autocomplete
                        v-model="file.FKOriginID"
                        label="Origin"
                        :items="countries"
                        item-text="TextLine"
                        item-value="Value01"
                        class="small-text-field elevation-1"
                        background-color="white"
                        outlined
                        dense
                        autocomplete="disable"
                        hide-details="auto">
                </v-autocomplete>

                <v-text-field
                        v-model="file.SupplierName"
                        label="Supplier Name"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-text-field
                        v-model="file.SupplierEmail"
                        label="Supplier Email"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-row dense>
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.SupplierPhone"
                                label="Supplier Phone"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-autocomplete
                                v-model="file.SupplierCountry"
                                label="Supplier Country"
                                :items="countries"
                                item-text="TextLine"
                                item-value="TextLine"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                autocomplete="disable"
                                hide-details="auto">
                        </v-autocomplete>
                    </v-col>
                </v-row>

                <v-text-field
                        v-model="file.SupplierAddress"
                        label="Supplier Address"
                        class="small-text-field elevation-1 mt-2"
                        background-color="white"
                        outlined
                        dense
                        hide-details="auto">
                </v-text-field>

                <v-row dense class="mt-2">
                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.ETADate"
                                label="ETA Date"
                                type="date"
                                class="small-text-field elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.ReceivingFileDate"
                                label="Receiving File Date"
                                type="date"
                                class="small-text-field elevation-1"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.InvoiceValue"
                                label="Invoice Value"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.FreightValue"
                                label="Freight"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="totalValue"
                                label="Total Value"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="py-0">
                        <v-text-field
                                v-model="file.Weight"
                                label="Weight"
                                class="small-text-field elevation-1 mt-2"
                                background-color="white"
                                outlined
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-title>
                        Containers
                        <v-spacer></v-spacer>
                        <v-btn
                                color="green"
                                class="white--text"
                                small
                                @click="addContainer">
                            <v-icon left>fa-plus-circle</v-icon> Add Containers
                        </v-btn>
                    </v-card-title>
                    <v-list dense v-if="file.Containers.length > 0">
                        <v-list-item v-for="container in file.Containers" :key="container.FKFCLTypeID">
                            <v-list-item-content>
                                <v-list-item-title>{{ container.FCL }} x {{ container.FCLType }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-btn icon small @click="deleteContainer(container)"><v-icon small color="grey">fa-trash</v-icon></v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Container Detail<br/>click <b>"ADD CONTAINERS"</b> button above
                    </v-card-text>
                </v-card>

                <v-card class="mt-2">
                    <v-card-title>
                        Documents
<!--                        <v-spacer></v-spacer>-->
<!--                        <v-btn-->
<!--                                color="green"-->
<!--                                class="white&#45;&#45;text"-->
<!--                                small-->
<!--                                @click="addDocument">-->
<!--                            <v-icon left>fa-plus-circle</v-icon> Add Document-->
<!--                        </v-btn>-->
                    </v-card-title>
                    <v-list
                            dense
                            v-if="file.Documents.length > 0">
                        <v-list-item
                                v-for="document in file.Documents"
                                :key="document.DocumentTitle"
                                @click = "selectDocument(document)"
                                link >
                            <v-list-item-content>
                                <v-list-item-title :class="document.FilePath.length == 0 ? 'red--text' : ''">{{ document.DocumentTitle }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-icon>
                                <v-btn icon small @click.stop="deleteDocument(document)"><v-icon small color="grey">fa-trash</v-icon></v-btn>
                            </v-list-item-icon>
                        </v-list-item>
                    </v-list>
                    <v-card-text v-else class="text-center">
                        To add a new Document<br/>click <b>"ADD DOCUMENT"</b> button above
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row>
            <v-col cols="12" align="end">
                <v-btn
                        color="green"
                        class="white--text"
                        @click="saveFile">
                    Save
                </v-btn>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgContainer"
                title="Add Containers"
                :persistent="false"
                confirm-button-text="Add"
                cancel-button-text="Cancel"
                :on-confirm="saveContainer">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="container.FCL"
                                label="No Of Containers"
                                solo
                                dense
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                :items="fclTypes"
                                item-text="TextLine"
                                @change="onFCLTypeChange($event)"
                                label="Type Of Container"
                                solo
                                dense
                                return-object
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                                v-model="container.SerialNo"
                                label="Serial No"
                                solo
                                dense
                                return-object
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocuments"
                title="Documents"
                :persistent="false"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-row>
                    <v-col cols="12">
                        <v-text-field
                                v-model="document.DocumentTitle"
                                label="Document Title"
                                solo
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-btn
                                @click="$refs.imgUpload.click()"
                                class="primary"
                                dark
                                block >
                            Upload Document
                        </v-btn>
                        <p class="mt-2 red--text" v-if="!!document.error">{{ document.error }}</p>
                        <p class="mt-2">{{ document.name }} ( {{ document.size }} KB )</p>
                        <input type="file" accept="*" ref="imgUpload" style="display: none;" @change="uploadDocument" />
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>

    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Files",
        data() {
            return {
                file: {
                    FileID: 0,
                    FileNo: 'NEW',
                    CustomerNo: '0',
                    FKCustomerID: 0,
                    ShipmentType: 'Import',
                    ShipmentMethod: 'Sea',
                    BLNo: '',
                    BLStatus: 'Copy',
                    Reference: '',
                    VesselName: '',
                    FKShippingLineID: 0,
                    FKProductCategoryID: 0,
                    ProductDescription: '',
                    FKOriginID: 0,
                    SupplierName: '',
                    SupplierEmail: '',
                    SupplierPhone: '',
                    SupplierCountry: '',
                    SupplierAddress: '',
                    ETADate: '',
                    ETBDate: this.toShortDateString(new Date()),
                    ReceivingFileDate: this.toShortDateString(new Date()),
                    InvoiceValue: 0,
                    FreightValue: 0,
                    TotalValue: 0,
                    Weight: 0,
                    Containers: [],
                    Documents: [],
                },
                container: {
                    ContainersID: 0,
                    FCL: 0,
                    FCLType: '',
                    FKFCLTypeID: 0,
                    SerialNo: '',
                },
                selectedCustomer: {},
                customers: [],
                shippingLines: [],
                productCategories: [],
                countries: [],
                fclTypes: [],
                reqDocuments: [],
                document: {
                    DocumentID: 0,
                    DocumentTitle: "",
                    DocumentDate: '',
                    DocumentNo: '',
                    FilePath: "",
                    name: "",
                    size: "",
                    error: "",
                },
            };
        },
        computed: {
            userRoleID(){
              return this.$store.getters.user.UserRoleID;
            },
            action() {
                return this.$route.params.action == 'new' ? 'New' : 'Edit';
            },
            totalValue(){
              return parseFloat(this.file.InvoiceValue) + parseFloat(this.file.FreightValue);
            },
        },
        methods: {
            getData(fileID){
                this.$loadingDialog.show();

                const formData = {
                    FileID: fileID,
                    PageNo: 1,
                    NoOfRows: 1,
                    HasFilter: false,
                    IsApproval: false,
                    IsActive: false,
                    Filters: [],
                };
                console.log(JSON.stringify(formData));

                axios.post('Files/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.file = data.Data.Items[0];
                            const customerID = this.file.FKCustomerID;
                            this.selectedCustomer = this.customers.find(element => element.CustomerID == customerID);
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.$loadingDialog.hide();
                    })
                    .catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });
            },
            onCustomerChange(customer){
                this.file.FKCustomerID = customer.CustomerID;
                this.file.CustomerNo = customer.CustomerNo;
            },
            onFCLTypeChange(fclType){
                this.container.FCLType = fclType.TextLine;
                this.container.FKFCLTypeID = fclType.Value01;
            },
            addContainer() {
                this.container = {
                    ContainersID: 0,
                    FCL: 0,
                    FCLType: '',
                    FKFCLTypeID: 0,
                    SerialNo: '',
                };

                this.$refs.dlgContainer.show();
            },
            saveContainer() {
                console.log(this.container);
              this.file.Containers.push(this.container);
            },
            deleteContainer(container) {
                const ind = this.file.Containers.indexOf(container);
                const vm = this;
                this.$confirmDialog.show({
                    title: 'Delete FCL Detail',
                    message: 'Are you want to delete this FCL? Please confirm.',
                    onConfirm() {
                        vm.file.Containers.splice(ind, 1);
                    }
                });
            },
            selectDocument(document)
            {
                if(document.FilePath.length == 0) {
                    this.document = document;
                    this.$refs.dlgDocuments.show();
                }
                else {
                    window.open(document.FilePath, "_blank");
                }
            },
            uploadDocument(e){
                const file = e.target.files[0];
                let size = (file.size/1024).toFixed(2);

                if( size > 1000 )
                {
                    this.document.error = "File size is exceeding the limit. Please select a smaller picture.";
                    return;
                }

                this.document.File = file;
                this.document.FilePath = URL.createObjectURL(file);
                this.document.name = file.name;
                this.document.size = size;
                this.document.error = "";
            },
            deleteDocument(document){
                document.DocumentNo = '';
                document.File = null;
                document.FilePath = '';
                document.name = '';
                document.size = '';
                document.error = "";
            },
            saveFile() {
                if( this.file.FKCustomerID == 0 )
                {
                    this.$snackBar.error({message: 'Please select a company.'});
                    return;
                }

                if( this.file.BLNo.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter a BL No.'});
                    return;
                }

                if( this.file.FKShippingLineID == 0 )
                {
                    this.$snackBar.error({message: 'Please select a shipping line.'});
                    return;
                }

                if( this.file.FKProductCategoryID == 0 )
                {
                    this.$snackBar.error({message: 'Please select a product category.'});
                    return;
                }

                if( this.file.ProductDescription.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter a description of items.'});
                    return;
                }

                if( this.file.FKOriginID == 0 )
                {
                    this.$snackBar.error({message: 'Please select an origin.'});
                    return;
                }

                if( this.file.ETADate.length == 0 )
                {
                    this.$snackBar.error({message: 'Please enter an ETA date.'});
                    return;
                }

                if( this.file.Containers.length == 0 )
                {
                    this.$snackBar.error({message: 'Please add at least 1 FCL detail.'});
                    return;
                }

                let isDocsUploaded = true;

                this.file.Documents.forEach(element => {
                    isDocsUploaded = !!element.FilePath;
                    if(!isDocsUploaded)
                        return;
                });

                // if( this.userRoleID != 1 && !isDocsUploaded )
                // {
                //     this.$snackBar.error({message: 'Please upload all documents.'});
                //     return;
                // }

                this.$loadingDialog.show();

                let formData = new FormData();
                Object.keys(this.file).forEach(key => {
                    if(key != 'Containers' && key != 'Documents')
                        formData.append(`File.${key}`, this.file[key] == null ? 0 : this.file[key]);
                });

                this.file.Containers.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`File.Containers[${ind}].${key}`, item[key] == null ? '' : item[key]);
                    });
                });

                this.file.Documents.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Documents[${ind}].${key}`, item[key] == null ? '' : item[key]);
                    });
                });

                axios.post('Files/AddEdit', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                        {
                            if(this.action == 'Edit')
                                this.getData(this.file.FileID);
                            else
                                this.$router.push({name: 'files'});
                        }

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                        this.$loadingDialog.hide();
                    }).catch(error => {
                        console.log(error);
                        this.$loadingDialog.hide();
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

            }
        },
        created() {
            this.$loadingDialog.show();
            this.getGeneral(4, 0)
                .then(response => {
                    this.shippingLines = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.getGeneral(5, 0)
                .then(response => {
                    this.countries = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.getGeneral(6, 0)
                .then(response => {
                    this.fclTypes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.getGeneral(11, 0)
                .then(response => {
                    this.productCategories = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            this.getCustomers()
                .then(response => {
                    this.customers = response.Data;
                    this.$loadingDialog.hide();
                })
                .catch(() => {
                    this.$loadingDialog.hide();
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            if(this.action == 'Edit') {
                const fileID = this.$route.query.id;
                this.getData(fileID);
            }
            else {
                this.getGeneral(9, 0)
                    .then(response => {
                        this.reqDocuments = response.Data;

                        if(this.file.Documents.length == 0)
                        {
                            this.reqDocuments.forEach(element => {
                                this.file.Documents.push({
                                    DocumentID: 0,
                                    DocumentTitle: element.TextLine,
                                    DocumentDate: '',
                                    DocumentNo: '',
                                    FilePath: "",
                                    name: "",
                                    size: "",
                                    error: "",
                                });
                            });
                        }
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });
            }
        }
    }
</script>

<style scoped>

</style>