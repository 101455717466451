<template>
    <v-container fluid>
        <v-row>
            <v-col md="2">
                <v-img max-height="100px" src="@/assets/logo.png" contain></v-img>
            </v-col>
            <v-col md="10" align="right" v-if="settings.ClientBanner01.length > 0">
                <v-img height="150px" :src="settings.ClientBanner01"></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table v-if="$vuetify.breakpoint.mdAndUp"
                              :headers="files.headers"
                              :items="files.data"
                              item-key="FileID"
                              @click:row="onRowClick"
                              :loading="files.loading"
                              loading-text="Loading Files, please wait..."
                              class="elevation-1"
                              style="cursor: pointer"
                              disable-pagination
                              disable-sort
                              dense
                              hide-default-footer>
                    <template v-slot:item.IsAcceptAssessment="{ item }">
                        <v-switch
                                v-model="item.IsAcceptAssessment"
                                color="green"
                                hide-details
                                class="mt-1 mb-2"
                                :readonly="item.IsAcceptAssessment"
                                @click.stop="updateAssessStatus(item)">
                        </v-switch>
                    </template>
                    <template v-slot:item.ValuationFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.ValuationFilePath"
                                target="_blank"
                                :disabled="!(item.ValuationFilePath && item.IsShowDocuments)">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.RiskLevel="{ item }">
                        <v-chip :color="item.RiskLevelColor.toLowerCase()" label class="my-1 white--text"><b>{{ item.RiskLevel }}</b></v-chip>
                    </template>
                    <template v-slot:item.Stage="{ item }">
                        <v-chip color="primary" label class="my-1"><b>{{ item.Stage }}</b></v-chip>
                    </template>
                </v-data-table>

                <v-card>
                    <v-card-text class="pa-1">
                        <v-list dense v-if="$vuetify.breakpoint.smAndDown">
                            <template v-for="(file, i) in files.data">
                                <v-list-item :key="file.FileID">
                                    <v-list-item-content>
                                        <v-list-item-title>Shipment BL No: {{ file.BLNo }}</v-list-item-title>
                                        <v-list-item-title>Vessel: {{ file.VesselName }}</v-list-item-title>
                                        <v-list-item-title>ETA: {{ file.ETADate }}</v-list-item-title>
                                        <v-list-item-title>No Of Containers: {{ file.FCL }}</v-list-item-title>
                                        <v-list-item-title>Commodity: {{ file.ProductDescription }}</v-list-item-title>
                                        <v-list-item-title>Estimated Clearing Date: {{ file.EstimatedClearingDate }}</v-list-item-title>
                                        <v-list-item-title>Status: {{ file.Stage }}</v-list-item-title>
                                        <v-row class="no-gutters">
                                            <v-col v-if="(file.ValuationFilePath && file.IsShowDocuments)">
                                                <v-btn color="green"
                                                       small
                                                       class="py-4 mt-2 white--text"
                                                       block
                                                       :href="file.ValuationFilePath">View Assessment</v-btn>
                                            </v-col>
                                            <v-col v-if="file.ValuationFilePath && !file.IsAcceptAssessment" class="ml-1">
                                                <v-btn color="green"
                                                       small
                                                       class="py-4 mt-2 white--text"
                                                       block
                                                       @click="updateAssessStatus(file)">Accept Assessment</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-btn color="primary" small class="py-4 mt-2" block @click="onRowClick(file)">View File</v-btn>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="i < files.data.length - 1" :key="`Div_${file.FileID}`"></v-divider>
                            </template>
                        </v-list>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        title: "My Shipments",
        data(){
            return {
                settings: {},
                files: {
                    headers: [
                        {text: 'S No', value: 'SNo'},
                        {text: 'Shipment BL No', value: 'BLNo'},
                        {text: 'Vessel', value: 'VesselName'},
                        // {text: 'UCR No', value: 'UCRNo' },
                        // {text: 'BOE No', value: 'BOENo', applyFilter: true },
                        {text: 'ETA', value: 'ETADate'},
                        {text: 'No Of Containers', value: 'FCL'},
                        // {text: 'Commodity', value: 'ProductDescription'},
                        {text: 'Supplier', value: 'SupplierName'},
                        {text: 'Assessment', value: 'ValuationFilePath' },
                        {text: 'Accept Assessment', value: 'IsAcceptAssessment' },
                        // {text: 'Risk Level', value: 'RiskLevel'},
                        {text: 'Estimated Clearing Date', value: 'EstimatedClearingDate'},
                        {text: 'Status', value: 'Stage'},
                    ],
                    data: [],
                    loading: true,
                }
            };
        },
        methods: {
            async getSettings(){
                await axios.post('Settings/GetSettings', {})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.settings = data.Data;
                        else
                            this.$snackBar.error({message: data.Message});
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });
            },
            async getData(){
                await axios.post('ClientsPortal/Shipments', {FileID: 0})
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.data = data.Data.Files;
                        }
                        else {
                            this.$snackBar.show({color: 'error', message: data.Message});
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                    });

                this.files.loading = false;
            },
            async updateAssessStatus(item){
                this.$loadingDialog.show();
                await this.SaveAssessStatus(item.FileID, true);
                await this.getData();
                this.$loadingDialog.hide();
            },
            onRowClick(row){
                console.log(row);

                const fileID = row.FileID;
                this.$router.push({name: 'clientFile', params: {id: fileID}});
            }
        },
        async created() {
            this.$loadingDialog.show();
            await this.getSettings();
            await this.getData();
            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>
    .pointer {
        cursor: pointer;
    }
</style>