<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">Approve Files</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4>Total Records: <span class="indigo--text text--darken-2">{{ files.totals.TotalRecords }}</span></h4>
                        <h4 class="ml-2">Total Approved: <span class="indigo--text text--darken-2">{{ files.totals.Approved }}</span></h4>
                        <h4 class="ml-2">Total Not-Approved: <span class="indigo--text text--darken-2">{{ files.totals.NotApproved }}</span></h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.boeModeTotal)" :key="key" class="mr-2">
                            BOE {{ key }}: <span class="indigo--text text--darken-2">{{ files.boeModeTotal[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" md="4">
                <v-card>
                    <v-card-text class="d-flex">
                        <h4 v-for="key in Object.keys(files.boeStatusTotal)" :key="key" class="mr-2">
                            BOE {{ key }}: <span class="indigo--text text--darken-2">{{ files.boeStatusTotal[key] }}</span>
                        </h4>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        v-model="files.selected"
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        show-select
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td><v-icon>fa-filter</v-icon></td>
                            <td v-for="header in files.headers" :key="header.value">
                                <v-text-field
                                        v-model="files.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.Stage="{item}">
                        <v-btn
                                color="primary"
                                class="white--text my-1"
                                small
                                block
                                @click="updateStage(item)">
                            {{ item.Stage }}
                        </v-btn>
                    </template>
                    <template v-slot:item.ViewFile="{item}">
                        <v-btn
                                color="primary"
                                class="white--text my-1"
                                icon
                                small
                                block
                                @click="viewFile(item.FileID)">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.FKStatusID="{ item }">
                        <v-btn
                                v-if="item.FKStatusID == 1"
                                color="orange"
                                class="white--text my-1"
                                small
                                block
                                @click="approveFile(item, 2)">
                            Waiting Approval
                        </v-btn>
                        <v-btn
                                v-else
                                color="green"
                                class="white--text my-1"
                                small
                                block
                                @click="approveFile(item, 1)">
                            Cancel Approval
                        </v-btn>
                    </template>
                    <template v-slot:item.BOEAction="{ item }">
                        <v-btn
                                color="primary"
                                class="white--text my-1"
                                small
                                block
                                :disabled="item.FKStatusID == 1"
                                @click="approveBOE(item)">
                            BOE Update
                        </v-btn>
                    </template>
                    <template v-slot:item.Actions="{ item }">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item @click="viewDocuments(item.FileID)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon small>fa-file</v-icon></v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>View Documents</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgBOE"
                title="BOE Approval"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveBOE">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-select
                                v-model="boe.FKBOEModeID"
                                label="BOE Mode"
                                :items="boeModes"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="boe.FKBOEStatusID"
                                label="BOE Status"
                                :items="boeStatus"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="boe.Priority"
                                label="Priority"
                                :items="['Normal', 'Medium', 'Urgent', 'Very Urgent']"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="boe.FKRiskLevelID"
                                label="Risk Level"
                                :items="riskLevels"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgStage"
                title="Move File Stage"
                :persistent="false"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="moveFile">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                                v-model="selectedFile.FileNo"
                                label="File No"
                                outlined
                                dense
                                readonly
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-select
                                v-model="selectedFile.FKStageID"
                                label="Stage"
                                :items="stages"
                                item-text="Stage"
                                item-value="StageID"
                                outlined
                                dense
                                hide-details="auto">
                        </v-select>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
        <custom-dialog
                ref="dlgDocuments"
                title="All Documents"
                max-width="600"
                confirm-button-text=""
                cancel-button-text="Close">
            <template v-slot:content>
                <v-list dense>
                    <template  v-if="fileDocuments.length > 0">
                        <v-list-item v-for="doc in fileDocuments" :key="doc.DocumentID" link :href="doc.FilePath" target="_blank">
                            <v-list-item-content>
                                <v-list-item-title>{{ doc.DocumentTitle }}</v-list-item-title>
                                <v-list-item-title v-if="doc.DocumentNo.length > 0">Document # {{ doc.DocumentNo }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </template>
                    <v-list-item v-else>
                        <v-list-item-content>
                            <v-list-item-title>No Documents Are Available</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Files",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S.No', value: 'SNo', applyFilter: false },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company Name', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'BL Status', value: 'BLStatus', applyFilter: true },
                        { text: 'Shipment Type', value: 'ShipmentType', applyFilter: true },
                        { text: 'ETA Date', value: 'ETADate', applyFilter: true },
                        { text: 'Shipping', value: 'ShippingLine', applyFilter: true },
                        // { text: 'Vessel', value: 'VesselName', applyFilter: true },
                        { text: 'FCL', value: 'FCL', applyFilter: true },
                        { text: 'Ref No', value: 'Reference', applyFilter: true },
                        { text: 'Product Description', value: 'ProductDescription', applyFilter: true },
                        { text: 'Created By', value: 'CreatedBy', applyFilter: true },
                        { text: 'Created On', value: 'CreateDate', applyFilter: true },
                        { text: 'Stage', value: 'Stage', applyFilter: true },
                        { text: '', value: 'ViewFile', applyFilter: false },
                        { text: 'Approval', value: 'FKStatusID', applyFilter: false },
                        { text: 'BOE Update', value: 'BOEAction', applyFilter: false },
                        { text: 'Action', value: 'Actions', applyFilter: false },
                    ],
                    data: [],
                    filters: {},
                    selected: [],
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    totals: {},
                    boeStatusTotal: {},
                    boeModeTotal: {},
                    loading: true,
                },
                stages: [],
                selectedFile: {},
                boeModes: [],
                boeStatus: [],
                riskLevels: [],
                boe: {
                    FileID: 0,
                    Priority: 'Normal',
                    FKBOEModeID: 0,
                    FKBOEStatusID: 0,
                },
                fileDocuments: [],
            };
        },
        methods: {
            getData(){
                this.files.data = [];
                this.files.loading = true;

                const formData = {
                    FileID: 0,
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: true,
                    IsActive: true,
                    Filters: this.files.filters,
                };
                console.log(JSON.stringify(formData));

                axios.post('Files/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.boeStatusTotal = data.Data.BOEStatusTotal;
                            this.files.boeModeTotal = data.Data.BOEModeTotal;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
            approveFile(file, fileStatus) {
                this.$confirmDialog.show({
                    title: fileStatus == 2 ? 'Approve File' : 'Cancel Approval',
                    message: `Are you sure you want to ${ fileStatus == 2 ? 'approve' : 'cancel approval of' } the selected file? Please confirm.`,
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            FileID: file.FileID,
                            FKStatusID: fileStatus,
                        };

                        axios.post('Files/ApproveFile', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    file.FKStatusID = fileStatus;
                                  // this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                this.$loadingDialog.hide();
                            })
                            .catch(() => {
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                                this.$loadingDialog.hide();
                            });
                    }
                });
            },
            approveBOE(file) {
                this.boe = {
                    FileID: file.FileID,
                    Priority: file.Priority,
                    FKBOEModeID: file.FKBOEModeID,
                    FKBOEStatusID: file.FKBOEStatusID,
                    FKRiskLevelID: file.FKRiskLevelID,
                }

                this.$refs.dlgBOE.show();
            },
            saveBOE() {
                this.$confirmDialog.show({
                    title: 'Update BOE',
                    message: 'Are you sure you want to update BOE details for the selected file? Please confirm.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = this.boe;

                        axios.post('Files/ApproveBOE', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                this.$loadingDialog.hide();
                            })
                            .catch(() => {
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                                this.$loadingDialog.hide();
                            });
                    }
                });
            },
            viewFile(fileID){
                const routeData = this.$router.resolve({name: 'filesAddEdit', params: {"action": "edit"}, query: {id: fileID}});
                window.open(routeData.href, '_blank');
            },
            async viewDocuments(fileID){
                this.$loadingDialog.show();

                this.fileDocuments = await axios.post('Files/GetAllDocuments', {FileID: fileID})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        return status ? data.Data : [];
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'An unexpected error has occurred'});
                    });

                this.$refs.dlgDocuments.show();
                this.$loadingDialog.hide();
            },
            updateStage(file) {
                if(file.FKStageID == 0) {
                    this.$snackBar.info({message: 'Cannot move a new file'});
                }
                else {
                    this.selectedFile = file;
                    this.$refs.dlgStage.show();
                }
            },
            moveFile(){
                this.$confirmDialog.show({
                    title: 'Move File',
                    message: 'Are you sure you want to move the selected file? Please confirm.',
                    onConfirm: () => {
                        this.$loadingDialog.show();

                        const formData = {
                            FileID: this.selectedFile.FileID,
                            StageID: this.selectedFile.FKStageID,
                        };

                        axios.post('Files/MoveFile', formData)
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    this.getData();

                                this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                this.$loadingDialog.hide();
                            })
                            .catch(() => {
                                this.$snackBar.error({message: 'Unexpected error has occurred.'});
                                this.$loadingDialog.hide();
                            });
                    }
                });
            },
        },
        async created() {
            this.getData();

            this.$loadingDialog.show();
            await this.getStages()
                .then(response => {
                    this.stages = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(7, 0)
                .then(response => {
                    this.boeModes = response.Data;
                })
                .catch(error => {
                    console.log(error);
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(8, 0)
                .then(response => {
                    this.boeStatus = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(16, 0)
                .then(response => {
                    this.riskLevels = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            this.$loadingDialog.hide();
        },
    }
</script>

<style scoped>

</style>