import Login from '../features/login';
import Home from '../features/home';

import MainDashboard from '../features/dashboards/main';

import Clients from '../features/customers/clients';
import ClientsAddEdit from '../features/customers/clientsAddEdit';
import Files from '../features/files/files';
import FilesAddEdit from '../features/files/filesAddEdit';

import FileApproval from '../features/approvals/files';
import BOEApproval from '../features/approvals/boe';
import ClearingApproval from '../features/approvals/clearing';

import ETAStatus from '../features/operations/eta';
import BOE from '../features/operations/boe';
import Operations from '../features/operations/operations';
import Terminal from '../features/operations/terminal';
import Warehouse from '../features/operations/warehouse';
import Refunds from '../features/operations/refunds';

import ChartOfAccounts from '../features/accounts/chartOfAccounts';
import Services from '../features/accounts/services';
import Invoices from '../features/accounts/invoices';
import InvoicesAddEdit from '../features/accounts/invoicesAddEdit';

import Users from '../features/settings/users';
import UsersRoles from '../features/settings/usersRoles';
import Currency from '../features/settings/currency';
import Transporters from '../features/settings/transporters';
import OperationSettings from '../features/settings/operations';
import TerminalSettings from '../features/settings/terminals';
import GeneralSettings from '../features/settings/general';

import BulkSMS from '../features/bulkSms';

import ClientHome from '../features/clients/clientHome';
import ClientFile from '../features/clients/clientFile';
import ClientProfile from '../features/clients/clientProfile';

export const routes = [
    { path: '/login', component: Login, name: 'login' },
    { path: '/home', component: Home, children: [
            { path: '/', component: MainDashboard, name: 'home' },
            { path: '/clients', component: Clients, name: 'clients' },
            { path: '/clients/:action', component: ClientsAddEdit, name: 'clientsAddEdit' },
            { path: '/files', component: Files, name: 'files' },
            { path: '/files/:action', component: FilesAddEdit, name: 'filesAddEdit' },
            { path: '/approvals/files', component: FileApproval, name: 'fileApproval' },
            { path: '/approvals/boe', component: BOEApproval, name: 'boeApproval' },
            { path: '/approvals/clearing', component: ClearingApproval, name: 'clearingApproval' },
            { path: '/clearedFiles', component: ClearingApproval, name: 'clearedFiles' },
            { path: '/ops/eta', component: ETAStatus, name: 'eta' },
            { path: '/ops/boe', component: BOE, name: 'boe' },
            { path: '/ops/warehouse', component: Warehouse, name: 'warehouse' },
            { path: '/ops/refunds', component: Refunds, name: 'refund' },
            { path: '/ops/:stage', component: Operations, name: 'ops' },
            { path: '/terminal/:id', component: Terminal, name: 'terminal' },
            { path: '/accounts/coa', component: ChartOfAccounts, name: 'chartOfAccounts' },
            { path: '/accounts/services', component: Services, name: 'services' },
            { path: '/accounts/invoices', component: Invoices, name: 'invoices' },
            { path: '/accounts/invoices/:action', component: InvoicesAddEdit, name: 'invoicesAddEdit' },
            { path: '/settings/users', component: Users, name: 'users' },
            { path: '/settings/usersRoles', component: UsersRoles, name: 'usersRoles' },
            { path: '/settings/currency', component: Currency, name: 'currency' },
            { path: '/settings/transporters', component: Transporters, name: 'transporters' },
            { path: '/settings/operations', component: OperationSettings, name: 'operationSettings' },
            { path: '/settings/terminals', component: TerminalSettings, name: 'terminalSettings' },
            { path: '/settings/general', component: GeneralSettings, name: 'settings' },
            { path: '/clientsPortal/home', component: ClientHome, name: 'clientHome' },
            { path: '/clientsPortal/file/:id', component: ClientFile, name: 'clientFile' },
            { path: '/clientsPortal/profile', component: ClientProfile, name: 'clientProfile' },
            { path: '/sms', component: BulkSMS, name: 'sms' },
        ] },
];