<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3">
                <h2 class="heading">BOE Approval</h2>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col cols="12" md="12">
                <v-data-table
                        :headers="files.headers"
                        :items="files.data"
                        :loading="files.loading"
                        loading-text="Loading Files, please wait..."
                        :page.sync="files.pagination.page"
                        :items-per-page="files.pagination.rowsPerPage"
                        class="elevation-1"
                        dense
                        hide-default-footer>
                    <template v-slot:body.prepend v-if="!files.loading">
                        <tr>
                            <td v-for="header in files.headers" :key="header.value">
                                <v-text-field
                                        v-model="files.filters[header.value]"
                                        class="small-text-field my-1"
                                        background-color="white"
                                        outlined
                                        dense
                                        hide-details
                                        :type="header.value.includes('Date') ? 'date' : 'text'"
                                        autcomplete="disable"
                                        @change="customFilter($event, header.value)"
                                        v-if="header.applyFilter">
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                    <template v-slot:item.IsAcceptAssessment="{ item }">
                        <v-tooltip top :disabled="!item.IsAcceptAssessment">
                            <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs"
                                     v-on="on"
                                     style="width: min-content;">
                                    <v-switch
                                            v-model="item.IsAcceptAssessment"
                                            color="green"
                                            hide-details
                                            class="mt-1 mb-2"
                                            @change="updateAssessStatus(item)">
                                    </v-switch>
                                </div>
                            </template>
                            <span>{{ item.IsAcceptAssessment ? `${item.AssessmentAcceptDate} BY ${item.AssessmentAcceptByUser}` : '' }}</span>
                        </v-tooltip>
                    </template>
                    <template v-slot:item.IDFFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.IDFFilePath"
                                target="_blank"
                                :disabled="!item.IDFFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.BOEFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.BOEFilePath"
                                target="_blank"
                                :disabled="!item.BOEFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.ValuationReportFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.ValuationReportFilePath"
                                target="_blank" :disabled="!item.ValuationReportFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.MDAFilePath="{ item }">
                        <v-btn
                                icon
                                color="primary"
                                :href="item.MDAFilePath"
                                target="_blank" :disabled="!item.MDAFilePath">
                            <v-icon>fa-search</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:item.IsBOEApproved="{ item }">
                        <v-chip label color="green" text-color="white" class="my-1" v-if="item.IsBOEApproved">Approved</v-chip>
                        <v-btn color="orange" class="white--text my-1" small @click="approveBOE(item)" v-else>Waiting Approval</v-btn>
                    </template>
                    <template v-slot:item.Actions="{ item }">
                        <v-menu left offset-x>
                            <template v-slot:activator="{on, attrs}">
                                <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        small>
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item @click="editBOE(item)" dense>
                                    <v-list-item-icon class="mr-2"><v-icon>fa-pencil</v-icon></v-list-item-icon>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </template>
                </v-data-table>
                <div class="pt-2 mx-2 text-right">
                    <v-pagination v-model="files.pagination.page" :length="files.pagination.totalPages" total-visible="20" @input="getData"></v-pagination>
                </div>
            </v-col>
        </v-row>

        <custom-dialog
                ref="dlgBOE"
                title="BOE Details"
                :persistent="false"
                :max-width="900"
                confirm-button-text="Save"
                cancel-button-text="Cancel"
                :on-confirm="saveBOE">
            <template v-slot:content>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.BLNo"
                                label="BL No"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.FCL"
                                label="FCL"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field
                                v-model="selectedFile.ProductDescription"
                                label="Product Description"
                                outlined
                                dense
                                readonly
                                background-color="white"
                                hide-details="auto">
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="5">
                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        :value="formatDate(editingBOE.BOEReceivingDate)"
                                        label="BOE Receiving Date"
                                        class="mb-2"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    style="width: 100%"
                                    v-model="editingBOE.BOEReceivingDate">
                            </v-date-picker>
                        </v-menu>

                        <v-menu
                                offset-y
                                transition="scale-transition"
                                :close-on-content-click="true">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                        :value="formatDate(editingBOE.BOESubmissionDate)"
                                        label="BOE Submission Date"
                                        background-color="white"
                                        outlined
                                        dense
                                        readonly
                                        hide-details
                                        v-bind="attrs"
                                        v-on="on">
                                </v-text-field>
                            </template>
                            <v-date-picker
                                    style="width: 100%"
                                    v-model="editingBOE.BOESubmissionDate">
                            </v-date-picker>
                        </v-menu>

                        <v-select
                                v-model="editingBOE.FKBOEModeID"
                                label="BOE Mode"
                                :items="boeModes"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                background-color="white"
                                dense
                                class="mt-2"
                                hide-details="auto">
                        </v-select>

                        <v-select
                                v-model="editingBOE.FKBOEStatusID"
                                label="BOE Status"
                                :items="boeStatus"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                background-color="white"
                                dense
                                class="mt-2"
                                hide-details="auto">
                        </v-select>

                        <v-select
                                v-model="editingBOE.FKRiskLevelID"
                                label="Risk Level"
                                :items="riskLevels"
                                item-text="TextLine"
                                item-value="Value01"
                                outlined
                                background-color="white"
                                dense
                                class="mt-2"
                                hide-details="auto">
                        </v-select>

                        <v-text-field
                                v-model="editingBOE.UCRNo"
                                label="UCR No"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-text-field>

                        <v-text-field
                                v-model="editingBOE.Comments"
                                label="Comments"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-text-field>

                        <v-textarea
                                v-model="editingBOE.ClientComments"
                                label="Client Comments"
                                rows="3"
                                outlined
                                background-color="white"
                                dense
                                autocomplete="disable"
                                class="mt-2"
                                hide-details="auto">
                        </v-textarea>

                        <v-switch v-model="editingBOE.IsShowDocuments"
                                  color="green"
                                  label="Allow Client To View Documents" />
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-card>
                            <v-card-title>Documents</v-card-title>
                            <v-list dense>
                                <v-list-item v-for="(document, ind) in selectedFile.Documents" :key="document.title">
                                    <v-list-item-content>
                                        <v-list-item-title :class="document.FilePath.length == 0 ? 'red--text' : ''">{{ document.DocumentTitle }}</v-list-item-title>
                                        <v-list-item-subtitle>{{ document.DocumentNo }}</v-list-item-subtitle>
                                        <v-row v-if="document.ShortFilePath != undefined">
                                            <v-col>
                                                <v-text-field
                                                        :ref="'txtShortFile_' + ind"
                                                        outlined
                                                        dense
                                                        class="small-text-field mr-2"
                                                        v-model="document.ShortFilePath"
                                                        readonly
                                                        hide-details
                                                        append-icon="fa-copy"
                                                        @click:append="copyShortUrl(ind)">
                                                </v-text-field>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col v-if="document.FilePath.length > 0">
                                                <v-btn color="primary" class="py-4" :href="document.FilePath" target="_blank" small block>View File</v-btn>
                                            </v-col>
                                            <v-col v-if="document.FilePath.length > 0 && document.ShortFilePath == undefined">
                                                <v-btn color="primary" class="py-4" @click.prevent="shortenUrl(document)" small block>Get Short URL</v-btn>
                                            </v-col>
                                        </v-row>
                                        <v-divider class="mt-2"></v-divider>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="10">
                        <v-text-field label="Message" v-model="message.Message" background-color="white" dense outlined hide-details></v-text-field>
                    </v-col>
                    <v-col cols="1">
                        <v-btn color="primary" class="py-5" block @click="sendSMSMessage">Send SMS</v-btn>
                    </v-col>
                </v-row>
            </template>
        </custom-dialog>
    </v-container>
</template>

<script>
    import axios from "axios";

    export default {
        title: "Bill Of Entry",
        data(){
            return {
                files: {
                    headers: [
                        { text: 'S. No', value: 'SNo' },
                        { text: 'File No', value: 'FileNo', applyFilter: true },
                        { text: 'Company', value: 'CompanyName', applyFilter: true },
                        { text: 'BL No', value: 'BLNo', applyFilter: true },
                        { text: 'BL Status', value: 'BLStatus', applyFilter: true },
                        { text: 'UCR No', value: 'UCRNo', applyFilter: true },
                        { text: 'ETA Date', value: 'ETADate', applyFilter: true },
                        { text: 'Duration', value: 'Duration', applyFilter: true },
                        { text: 'Items Description', value: 'ProductDescription', applyFilter: true },
                        { text: 'Comments', value: 'Comments', applyFilter: true },
                        { text: 'Accept Assessment', value: 'IsAcceptAssessment' },
                        { text: 'BOE', value: 'BOEFilePath', applyFilter: false },
                        { text: 'IDF', value: 'IDFFilePath', applyFilter: false },
                        { text: 'Assessment', value: 'ValuationReportFilePath', applyFilter: false },
                        { text: 'MDA', value: 'MDAFilePath', applyFilter: false },
                        // { text: 'IDF No', value: 'IDFNo', applyFilter: true },
                        // { text: 'BOE Submission Date', value: 'BOESubmissionDate', applyFilter: true },
                        // { text: 'BOE Receiving Date', value: 'BOEReceivingDate', applyFilter: true },
                        { text: 'Created By', value: 'CreatedBy', applyFilter: true },
                        { text: 'Created On', value: 'CreateDate', applyFilter: true },
                        { text: 'BOE Status', value: 'BOEStatus', applyFilter: true },
                        { text: 'BOE Mode', value: 'BOEMode', applyFilter: true },
                        { text: 'Approval', value: 'IsBOEApproved' },
                        { text: 'Action', value: 'Actions' },
                    ],
                    data: [],
                    filters: {},
                    pagination: {
                        page: 1,
                        totalPages: 1,
                        rowsPerPage: 25,
                    },
                    loading: false,
                },
                boeStatus: [],
                boeModes: [],
                riskLevels: [],
                selectedFile: {},
                editingBOE: {},
                message: {
                    FileID: 0,
                    Message: '',
                },
            };
        },
        methods: {
            getData(){
                this.files.loading = true;

                const formData = {
                    PageNo: this.files.pagination.page,
                    NoOfRows: this.files.pagination.rowsPerPage,
                    HasFilter: !!Object.keys(this.files.filters).length,
                    IsApproval: true,
                    Filters: this.files.filters,
                };

                axios.post('BOE/GetData', formData)
                    .then(response => {
                        const data = response.data;

                        if(data.Status) {
                            this.files.totals = data.Data.Totals;
                            this.files.pagination.totalPages = data.Data.TotalPages;
                            this.files.data = data.Data.Items;
                        }
                        else {
                            this.$snackBar.error({message: data.Message});
                        }

                        this.files.loading = false;
                    })
                    .catch(() => {
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        this.files.loading = false;
                    });
            },
            customFilter(value, field){
                if( value )
                    this.files.filters[field] = value;
                else
                    delete this.files.filters[field];

                this.getData();
            },
                viewFile(){
                this.$refs.dlgFile.show();
            },
            editBOE(file){
                this.selectedFile = file;

                this.editingBOE = {
                    FileID: file.FileID,
                    FKCustomerID: file.FKCustomerID,
                    BOEReceivingDate: this.formatString(file.BOEReceivingDate),
                    BOESubmissionDate: this.formatString(file.BOESubmissionDate),
                    FKBOEModeID: file.FKBOEModeID,
                    FKBOEStatusID: file.FKBOEStatusID,
                    FKRiskLevelID: file.FKRiskLevelID,
                    UCRNo: file.UCRNo,
                    Comments: file.Comments,
                    ClientComments: file.ClientComments,
                    IsShowDocuments: file.IsShowDocuments,
                };

                this.message = {
                    FileID: file.FileID,
                    Message: '',
                };

                this.$refs.dlgBOE.show();
            },
            async updateAssessStatus(item){
                this.$loadingDialog.show();
                await this.SaveAssessStatus(item.FileID, item.IsAcceptAssessment);
                await this.getData();
                this.$loadingDialog.hide();
            },
            async saveBOE() {
                this.$loadingDialog.show();

                let formData = new FormData();

                Object.keys(this.editingBOE).forEach(key => {
                    formData.append(`${key}`, this.editingBOE[key]);
                });

                this.selectedFile.Documents.forEach((item, ind) => {
                    Object.keys(item).forEach(key => {
                        formData.append(`Documents[${ind}].${key}`, item[key]);
                    });
                });

                await axios.post('BOE/Update', formData,
                    {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        const data = response.data;
                        const status = data.Status;

                        if(status)
                            this.getData();

                        this.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    });

                this.$loadingDialog.hide();
            },
            approveBOE(file){
                const vm = this;
                this.$confirmDialog.show({
                    title: 'Approve BOE Status',
                    message: 'Are you sure you want to approve BOE Status? Please confirm',
                    onConfirm(){
                        vm.$loadingDialog.show();
                        axios.post('BOE/Approve', {FileID: file.FileID})
                            .then(response => {
                                const data = response.data;
                                const status = data.Status;

                                if(status)
                                    vm.getData();

                                vm.$snackBar.show({color: status ? 'success' : 'error', message: data.Message});
                                vm.$loadingDialog.hide();
                          })
                        .catch(error => {
                            console.log(error);
                            this.$loadingDialog.hide();
                            this.$snackBar.error({message: 'Unexpected error has occurred.'});
                        });
                  }
              });
            },
            formatDate(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    let f_date_string = new Date(f_date[0], f_date[1]-1, f_date[2]);

                    return this.toDateString(f_date_string);
                }
                else
                    return '';
            },
            formatString(dt) {
                if(dt)
                {
                    let f_date = dt.split('-');
                    const mon = this.months.indexOf(f_date[1]);
                    let f_date_string = new Date(f_date[2], mon, f_date[0]);

                    return this.toShortDateString(f_date_string);
                }
                else
                    return '';
            },
            async shortenUrl(item){
                this.$loadingDialog.show();

                const formData = {
                    group_guid: '',
                    domain: 'shipon.net',
                    long_url: item.FilePath
                }

                const headers = {
                    'Authorization': 'Bearer ba13118e8d057247c0a15c401945ed3fe792d091',
                    'Content-Type': 'application/json',
                }

                await axios.post('https://api-ssl.bitly.com/v4/shorten', formData, {headers: headers})
                    .then(response => {
                        const data = response.data;
                        this.$set(item, 'ShortFilePath', data.link);
                    })
                    .catch(error => {
                        console.log(error);
                        this.$snackBar.error({message: 'Unexpected error has occurred.'});
                    })

                this.$loadingDialog.hide();
            },
            copyShortUrl(ind){
                const el = this.$refs['txtShortFile_' + ind][0].$el.querySelector('input');
                el.select();
                document.execCommand("copy");
            },
            async sendSMSMessage(){
                this.$loadingDialog.show();
                await this.SendSMS(this.message.FileID, 0, this.message.Message);
                this.$loadingDialog.hide();
            },
        },
        async created() {
            this.getData();

            this.$loadingDialog.show();
            await this.getGeneral(7, 0)
                .then(response => {
                    this.boeModes = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(8, 0)
                .then(response => {
                    this.boeStatus = response.Data;
                })
                .catch(() => {
                    this.$snackBar.error({message: 'Unexpected error has occurred.'});
                });

            await this.getGeneral(16, 0)
                .then(response => {
                    this.riskLevels = response.Data;
                })
                .catch(() => {
                    this.$snackBar.show({color: 'error', message: 'Unexpected error has occurred.'});
                });

            this.$loadingDialog.hide();
        }
    }
</script>

<style scoped>

</style>